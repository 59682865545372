import React, { lazy, Suspense, useContext, useMemo, useState } from 'react';
import { HashRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useAuthentication } from 'ttc-authentication';
import { PrivacyPolicy } from 'ttc-components';
import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';
import AccountContext from '../context/AccountContext';
import LanguageContext, { getUserLocale, updateUserLocale } from '../context/LanguageContext';
import { Audience, ErrorModalMessage } from '../utils/types';
import LocalizationContext from '../context/LocalizationContext';
import { isAudience, isInternal } from '../utils/InternalUtils';
import ErrorContext from '../context/ErrorModalContext';
import MyTicketsContextProvider from '../context/MyTicketsContextProvider';
import ScrollToTop from './common/ScrollToTop';
import NavBar from './nav/NavBar';
import ErrorBoundary from './common/ErrorBoundary';
import Spinner from './common/Spinner';
import ConfirmReservation from './reservations/ConfirmReservation';
import Footer from './common/Footer';
import NotFound from './errors/NotFound';
import ErrorModal from './errors/ErrorModal';
import './App.css';
import 'react-toastify/dist/ReactToastify.min.css';
import '../../resources/css/toast.css';
import AccountRequiredModal from './account/AccountRequiredModal';
import AnimationContainer from './common/animation/AnimationContainer';
import { addErrorBoundaryToRoute } from './nav/NavUtils';

// Dynamic imports via Webpack lazy loading
const Events = lazy(() => import(/* webpackChunkName: 'Events' */ './events/Events'));
const EventDetails = lazy(
    () => import(/* webpackChunkName: 'EventDetails' */ './events/details/EventDetails'),
);
const ActivityDetails = lazy(
    () => import(/* webpackChunkName: 'ActivityDetails' */ './activity/details/ActivityDetails'),
);
const ActivityTimeSlots = lazy(
    () =>
        import(/* webpackChunkName: 'ActivityDetails' */ './activity/timeSlots/ActivityTimeSlots'),
);

const MyTicketsAndPasses = lazy(
    () =>
        import(
            /* webpackChunkName: 'MyTicketsAndPasses' */ './account/myTicketsAndPasses/TicketsAndPasses'
        ),
);

const TicketDetailsPage = lazy(
    () =>
        import(
            /* webpackChunkName: 'TicketDetailsPage' */ './account/myTicketsAndPasses/ticketDetails/TicketDetailsPage'
        ),
);

const TicketSignUp = lazy(
    () => import(/* webpackChunkName: 'TicketSignUp' */ './tickets/signup/TicketSignUp'),
);

const WaitlistSignUpPage = lazy(
    () =>
        import(/* webpackChunkName: 'WaitlistSignUpPage' */ './waitlist/signUp/WaitlistSignUpPage'),
);

const TermsAcceptance = lazy(
    () => import(/* webpackChunkName: 'TermsAcceptance' */ './consent/TermsAcceptance'),
);

const BuildingMap = lazy(
    () => import(/* webpackChunkName: 'BuildingMap' */ './common/BuildingMap'),
);

const App = () => {
    const { accountInfo, setAccountInfo, authServiceError, navBarUser } = useAuthentication();

    // Setup context values
    const accountIdContextValue = useMemo(
        () => ({
            value: accountInfo,
            setValue: setAccountInfo,
        }),
        [accountInfo],
    );

    const locale = getUserLocale();
    const localizedStrings = useContext(LocalizationContext);

    if (localizedStrings.getLanguage() !== locale) {
        localizedStrings.setLanguage(locale);
    }

    const updateLocale = (updatedLocale: string) => {
        updateUserLocale(updatedLocale); // Update local storage
        localizedStrings.setLanguage(updatedLocale); // Set the react-localization language
        window.location.reload();
    };
    const memoLocale = useMemo(() => ({ locale, updateLocale }), [locale]);

    // Error modal state
    const [errorMessage, setErrorMessage] = useState<ErrorModalMessage>({ title: '', body: '' });
    const [showErrorModal, setShowErrorModal] = useState(false);
    const errorMemoMessage = useMemo(
        () => ({
            message: errorMessage,
            updateMessage: (updatedMessage: ErrorModalMessage) => {
                setErrorMessage(updatedMessage);
                setShowErrorModal(true);
            },
        }),
        [errorMessage],
    );

    return (
        <AccountContext.Provider value={accountIdContextValue}>
            <MyTicketsContextProvider>
                <LanguageContext.Provider value={memoLocale}>
                    <ErrorContext.Provider value={errorMemoMessage}>
                        <AccountRequiredModal />
                        <ToastContainer position="top-right" hideProgressBar />
                        <HashRouter>
                            <ScrollToTop />
                            <>
                                <ErrorModal
                                    show={showErrorModal}
                                    message={errorMessage}
                                    onClose={() => setShowErrorModal(false)}
                                />
                                <div className="h-100 d-flex flex-column justify-content-between">
                                    <div role="main">
                                        <NavBar userElement={navBarUser} />
                                        {authServiceError ? (
                                            <Container>
                                                <Alert variant="danger" className="mt-4">
                                                    {localizedStrings.error.eventServiceUnavailable}
                                                </Alert>
                                            </Container>
                                        ) : (
                                            ''
                                        )}
                                        <ErrorBoundary>
                                            <AnimationContainer>
                                                <Suspense fallback={<Spinner />}>
                                                    <Routes>
                                                        <Route
                                                            path="/"
                                                            element={addErrorBoundaryToRoute(
                                                                <Navigate to="/events" />,
                                                            )}
                                                        />
                                                        <Route
                                                            path="/events"
                                                            element={addErrorBoundaryToRoute(
                                                                <Events />,
                                                            )}
                                                        />
                                                        <Route
                                                            path="/events/:eventId"
                                                            element={addErrorBoundaryToRoute(
                                                                <EventDetails />,
                                                            )}
                                                        />
                                                        <Route
                                                            path="/events/:eventId/activities/:activityId"
                                                            element={addErrorBoundaryToRoute(
                                                                <ActivityDetails />,
                                                            )}
                                                        />
                                                        <Route
                                                            path="/events/:eventId/activities/:activityId/timeslots"
                                                            element={addErrorBoundaryToRoute(
                                                                <ActivityTimeSlots />,
                                                            )}
                                                        />
                                                        <Route
                                                            path="/events/:eventId/activities/:activityId/timeslot/:timeSlotId"
                                                            element={addErrorBoundaryToRoute(
                                                                <ConfirmReservation />,
                                                            )}
                                                        />
                                                        <Route
                                                            path="/my-tickets-passes"
                                                            element={addErrorBoundaryToRoute(
                                                                <MyTicketsAndPasses />,
                                                            )}
                                                        />
                                                        <Route
                                                            path="/my-tickets-passes/:resourceType/:resourceId"
                                                            element={addErrorBoundaryToRoute(
                                                                <TicketDetailsPage />,
                                                            )}
                                                        />
                                                        <Route
                                                            path="/events/:eventId/event_gates/:eventGateId"
                                                            element={addErrorBoundaryToRoute(
                                                                <TicketSignUp />,
                                                            )}
                                                        />
                                                        <Route
                                                            path="/events/:eventId/waitlist"
                                                            element={addErrorBoundaryToRoute(
                                                                <WaitlistSignUpPage />,
                                                            )}
                                                        />
                                                        <Route
                                                            path="/terms-acceptance/:resourceType/:resourceId"
                                                            element={addErrorBoundaryToRoute(
                                                                <TermsAcceptance />,
                                                            )}
                                                        />

                                                        {isAudience(Audience.NOW_PASS) &&
                                                        isInternal(localizedStrings) ? (
                                                            <Route
                                                                path="/privacy-policy"
                                                                element={addErrorBoundaryToRoute(
                                                                    <PrivacyPolicy />,
                                                                )}
                                                            />
                                                        ) : null}

                                                        {isAudience(Audience.NOW_PASS) &&
                                                        isInternal(localizedStrings) ? (
                                                            <Route
                                                                path="/building-map"
                                                                element={addErrorBoundaryToRoute(
                                                                    <BuildingMap />,
                                                                )}
                                                            />
                                                        ) : null}

                                                        <Route
                                                            path="*"
                                                            element={addErrorBoundaryToRoute(
                                                                <NotFound />,
                                                            )}
                                                        />
                                                    </Routes>
                                                </Suspense>
                                            </AnimationContainer>
                                        </ErrorBoundary>
                                    </div>
                                    <div>
                                        <Footer />
                                    </div>
                                </div>
                            </>
                        </HashRouter>
                    </ErrorContext.Provider>
                </LanguageContext.Provider>
            </MyTicketsContextProvider>
        </AccountContext.Provider>
    );
};

export default App;
