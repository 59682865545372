import axios, { type AxiosResponse } from 'axios';
import pick from 'lodash.pick';
import type { WaitlistEntry, WaitlistEntryRequest, WaitlistEntryStatus } from '../utils/types';

/**
 * Get waitlist entries for current user.
 */
export const fetchWaitlistEntries = async (
    /** user session token */
    token: string,
): Promise<Array<WaitlistEntry> | null> => {
    try {
        const response: AxiosResponse = await axios.get('/attendee/me/waitlists/entries', {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (e) {
        return null;
    }
};

/**
 * Create waitlist entries via POST
 */
export const createWaitlistEntries = async ({
    token,
    locale,
    waitlistEntries,
}: {
    /** user session token */
    token: string;
    /** user loacale for header */
    locale: string;
    /** list of updated WaitlistEntries for the POST body */
    waitlistEntries: Array<WaitlistEntryRequest>;
}): Promise<Array<WaitlistEntry> | null> => {
    try {
        const response: AxiosResponse<Array<WaitlistEntry>> = await axios.post(
            '/attendee/waitlists',
            waitlistEntries,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Accept-Language': locale,
                },
            },
        );
        return response.data;
    } catch (e) {
        return null;
    }
};

/**
 * Update waitlist entries via PUT
 */
export const updateWaitlistEntries = async ({
    token,
    waitlistEntries,
    locale,
}: {
    /** user session token */
    token: string;
    /** list of updated WaitlistEntries for the PUT body */
    waitlistEntries: Array<WaitlistEntry>;
    /** user loacale for header */
    locale: string;
}): Promise<Array<WaitlistEntry> | null> => {
    try {
        const response: AxiosResponse = await axios.put(
            '/attendee/waitlists',
            waitlistEntries.map((entry) =>
                pick(entry, [
                    'waitlist_entry_id',
                    'status',
                    'participant_id',
                    'event_id',
                    'resource_type',
                    'resource_id',
                    'host_ticket_id',
                    'party_id',
                    'offered_date',
                    'redeemed_date',
                    'offer_expire_date',
                    'sms_opted_in_status',
                    'adults_requested',
                    'children_requested',
                ]),
            ),
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Accept-Language': locale,
                },
            },
        );
        return response.data;
    } catch (e) {
        return null;
    }
};

/**
 * Update given waitlist entries' statuses to the given status.
 */
export const updateWaitlistEntriesStatus = async ({
    waitlistEntries,
    status,
    token,
    locale,
}: {
    /** list of WaitlistEntry(s) to update */
    waitlistEntries: Array<WaitlistEntry>;
    /** change the WaitlistEntry(s) to this status */
    status: WaitlistEntryStatus;
    /** user session token */
    token: string;
    /** user locale */
    locale: string;
}): Promise<Array<WaitlistEntry> | null> =>
    updateWaitlistEntries({
        waitlistEntries: waitlistEntries.map((entry) => ({
            ...entry,
            status,
        })),
        token,
        locale,
    });
