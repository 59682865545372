import React from 'react';
import TicketAltIcon from './icons/TicketAltIcon';
import './DotDivider.css';
import DottedLineSeparator from './DottedLineSeparator';

type DotDividerProps = {
    /** boolean to show the ticket icon or not */
    showIcon?: boolean;
    /** Used for dot divider styling */
    className?: string;
    /** Used for nested divider styling */
    dividerClassName?: string;
    /** Optional Divider icon class names. Defaults to 'divider-icon-rotated' */
    dividerIconClassName?: string;
};

/**
 * A simple component rendering out a dotted line as a divider between list content.
 */
const DotDivider = ({
    className,
    dividerClassName,
    dividerIconClassName = 'divider-icon-rotated',
    showIcon = true,
}: DotDividerProps) => (
    <div className={`d-flex justify-content-center align-items-center ${className || 'my-4'}`}>
        <DottedLineSeparator className={dividerClassName} />
        {showIcon && <TicketAltIcon className={dividerIconClassName} />}
        <DottedLineSeparator className={dividerClassName} />
    </div>
);

export default DotDivider;
