import React, { useContext } from 'react';
import Nav from 'react-bootstrap/Nav';
import Dropdown from 'react-bootstrap/Dropdown';

import LocalizationContext from '../../context/LocalizationContext';
import LanguageContext from '../../context/LanguageContext';
import { isInternal } from '../../utils/InternalUtils';

/**
 * Component for rendering a drop down menu with a list of available languages and their
 * corresponding locales.
 */
const LanguageMenu = () => {
    const localizedStrings = useContext(LocalizationContext);
    const { updateLocale } = useContext(LanguageContext);
    const internalApp = isInternal(localizedStrings);

    // If this is an internal app build, bail. The language is to be restricted;
    // not changable.
    if (internalApp) {
        return null;
    }

    const items = Object.entries(localizedStrings.locales).map(([key, value]) => {
        key = key.replace('_', '-');

        return (
            <Dropdown.Item
                as="button"
                key={key}
                value={key}
                onClick={() => {
                    updateLocale(key);
                    // Trigger a page reload so that Alps can be re-loaded with the correct locale
                    // eslint-disable-next-line no-restricted-globals
                    location.reload();
                    return false;
                }}
            >
                {value}
            </Dropdown.Item>
        );
    });

    // Render the component.
    return (
        <Nav className="justify-content-end">
            <Nav.Item className="nav-item">
                <Dropdown>
                    <Dropdown.Toggle variant="link" className="nav-link" id="language-dropdown">
                        <i className="fas fa-globe me-2" />
                        {localizedStrings.general.currentLanguage}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>{items}</Dropdown.Menu>
                </Dropdown>
            </Nav.Item>
        </Nav>
    );
};

export default LanguageMenu;
