import Moment from 'moment';
import React, { useContext } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useFormContext } from 'react-hook-form';
import Alert from 'react-bootstrap/Alert';
import LanguageContext from '../../../context/LanguageContext';
import LocalizationContext from '../../../context/LocalizationContext';
import { getAttendeeType } from '../../../utils/AccountUtils';
import { isInternal } from '../../../utils/InternalUtils';
import { getSupportedLocale } from '../../../utils/LanguageUtils';
import { AttendeeType, DataCollectionAssociation, ParticipantInfo } from '../../../utils/types';
import DataCollectionFields from '../fields/DataCollectionFields';
import ReadOnlyBirthdayField from '../fields/ReadOnlyBirthdayField';
import ReadOnlyNameField from '../fields/ReadOnlyNameField';
import { BlockType, generateFormControlReferencePrefix } from '../../../utils/BlockUtils';
import BlockHeader from './BlockHeader';

export interface RegistrantBlockProps {
    /** Name of the user. This resolves to Nintendo Account nickname for public. */
    name: string;
    /** Nintendo Account ID of the user */
    userId: string;
    /** ParticipantID (GPS account ID) of the user */
    participantId: string;
    /** is this user the HOST of this party */
    isHost: boolean;
    /** User's birthday */
    birthday: string | null;
    /** Boolean for rendering activity-specific components */
    isActivity?: boolean;
    /** Optional JSX component that contains a tooltip */
    tooltip?: JSX.Element;
    /**
     * The participant information such as first name, last name, etc.
     * This property is to satisfy the requirement for adult guests to fill out additional info (such as phone numbers, ZIP, etc) during the
     * terms agreement flow.
     */
    participantInfo?: ParticipantInfo;
    /** Data collection items */
    dataCollection?: Array<DataCollectionAssociation> | null;
    /** Boolean to display the user's birthday */
    displayBirthday?: boolean;
}

/**
 * Component displaying a block of fields + name for the user to fill out.
 */
const RegistrantBlock = ({
    name,
    userId,
    participantId,
    isHost,
    birthday,
    isActivity,
    tooltip,
    displayBirthday,
    participantInfo,
    dataCollection,
}: RegistrantBlockProps) => {
    const localizedStrings = useContext(LocalizationContext);
    const { locale } = useContext(LanguageContext);
    const { formState } = useFormContext();

    const formControlReferencePrefix: string = generateFormControlReferencePrefix(
        userId,
        BlockType.REGISTRANT_BLOCK,
    );

    let attendeeType: AttendeeType;
    if (!isInternal(localizedStrings)) {
        attendeeType = getAttendeeType(birthday);
    } else {
        attendeeType = AttendeeType.ADULT;
    }

    return (
        <>
            <Row>
                <Col>
                    <BlockHeader
                        icon={<i className="me-2 far fa fa-user" />}
                        title={localizedStrings.reservations.registrantInformation}
                        formState={formState}
                        formControlReferencePrefixes={[formControlReferencePrefix]}
                        tooltip={tooltip}
                    />

                    {isActivity && (
                        <Alert className="alert alert-info">
                            {localizedStrings.formatString(
                                localizedStrings.tickets.registration.registrant.onlyOneActivity,
                                {
                                    note: <b>{`${localizedStrings.general.note}:`}</b>,
                                },
                            )}
                        </Alert>
                    )}
                </Col>
            </Row>

            <Container>
                <ReadOnlyNameField userId={userId} name={name} attendeeType={attendeeType} />
                {displayBirthday ? (
                    <ReadOnlyBirthdayField
                        userId={userId}
                        birthday={Moment(birthday)
                            .locale(getSupportedLocale(locale))
                            .format(localizedStrings.dates.birthday)}
                    />
                ) : null}

                <DataCollectionFields
                    userId={userId}
                    nickname={name}
                    participantId={participantId}
                    isRegistrant={isHost}
                    birthday={birthday}
                    formControlReferencePrefix={formControlReferencePrefix}
                    dataCollection={dataCollection}
                    participantInfo={participantInfo}
                />
            </Container>
        </>
    );
};

export default RegistrantBlock;
