import React, { useContext } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import Moment from 'moment/moment';

import LanguageContext from '../../../context/LanguageContext';
import LocalizationContext from '../../../context/LocalizationContext';
import { getSupportedLocale } from '../../../utils/LanguageUtils';

import './FieldStyles.css';

/** Property values used to construct a static field displaying a birthday. */
type StaticBirthdayFieldProps = {
    /** The identification of the user logged in. */
    userId: string;
    /** The birthday to display. */
    birthday: string;
};

/**
 * Component for rendering a birthday readonly field.
 */
const ReadOnlyBirthdayField = (props: StaticBirthdayFieldProps) => {
    const localizedStrings = useContext(LocalizationContext);
    const { locale } = useContext(LanguageContext);
    const { userId, birthday } = props;

    return (
        <Form.Group as={Row}>
            <Col md={3}>
                <Form.Label className="mt-2">
                    {localizedStrings.tickets.registration.guest.birthday}
                </Form.Label>
            </Col>

            <Col className="name-field-user-name">
                <Form.Control
                    className="name-field-spaced-text"
                    id={`${userId}-birthday`}
                    plaintext
                    readOnly
                    disabled
                    value={Moment(birthday)
                        .locale(getSupportedLocale(locale))
                        .format(localizedStrings.dates.birthday)}
                />
            </Col>
        </Form.Group>
    );
};

export default ReadOnlyBirthdayField;
