import React, { useContext } from 'react';
import Form from 'react-bootstrap/Form';
import { useFormContext } from 'react-hook-form';
import LocalizationContext from '../../../context/LocalizationContext';
import { AttributeType, NintendoAccountFamilyUser } from '../../../utils/types';
import { BlockType } from '../../../utils/BlockUtils';

type ChildGuardianConsentBlockProps = {
    childGuests: Array<NintendoAccountFamilyUser>;
    formControlReferencePrefix: string;
};

/**
 * A sub block of the ConsentBlock component. Displays list of consents that the HOST needs to approve for
 * child guests
 *
 * @param userId ID of the logged-in user
 * @param childGuests list of NintendoAccountFamilyUser objects
 * @param formControlReferencePrefix
 */
const ChildGuardianConsentBlock = ({
    childGuests,
    formControlReferencePrefix,
}: ChildGuardianConsentBlockProps) => {
    const localizedStrings = useContext(LocalizationContext);
    const { register, watch } = useFormContext();

    if (childGuests.length === 0) {
        return null;
    }

    // Watch for child name input, so we can add their first and/or last name next to their nickname
    const formWatcher = watch();

    return (
        <>
            <Form.Check
                id={formControlReferencePrefix}
                className="mt-6 mb-6"
                type="checkbox"
                {...register(formControlReferencePrefix, { required: true })}
                data-testid="child-acknowledgement-checkbox"
                label={localizedStrings.reservations.childAcknowledgement}
            />

            <div className="form-check">
                <ul className="pt-2">
                    {childGuests.map((guest) => {
                        const { nickname } = guest;
                        // There is a potential for a participant to not show up in form state if there is no data to collect
                        // Ex: If you're adding a child, but you're not collecting any data items from them.
                        const guestNames: { [attribute: string]: string } | undefined =
                            formWatcher.user && formWatcher.user[guest.user_id]
                                ? formWatcher.user[guest.user_id][BlockType.GUESTS_BLOCK]
                                : {};

                        const firstName = guestNames?.[AttributeType.FIRST_NAME];
                        const lastName = guestNames?.[AttributeType.LAST_NAME];

                        let finalName = '';
                        if (firstName || lastName) {
                            if (firstName && !lastName) {
                                finalName = firstName;
                            } else if (!firstName && lastName) {
                                finalName = lastName;
                            } else {
                                finalName = `${firstName} ${lastName}`;
                            }
                            finalName = `(${finalName})`;
                        }

                        return (
                            <li key={guest.user_id}>
                                <span className="me-2">{nickname}</span>
                                <span>{finalName}</span>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </>
    );
};

export default ChildGuardianConsentBlock;
