import { InternalLocalizedStrings, TickTockLocalizedStrings } from '../context/LocalizationContext';
import AppConfig from '../components/config/AppConfig';
import { Audience } from './types';

/**
 * TypeScript Type Guard function for determining if the localization file currently has additional properties added by
 * internal localization file.
 * @param localizedStrings - Current localization content context.
 * @returns - Returns true if internal. False if not.
 */
export const isInternal = (
    localizedStrings: TickTockLocalizedStrings,
): localizedStrings is InternalLocalizedStrings => {
    return 'internal' in localizedStrings;
};

/** *
 *  Utility function for checking audience type
 *  @returns - True if current audience type matches the supplied audience type
 */
export const isAudience = (audience: Audience) => {
    return AppConfig.audience === audience;
};
