import React from 'react';
import { getSupportedLocale } from '../utils/LanguageUtils';

export const LOCALE_LOCAL_STORAGE_KEY = 'NINTENDO_EXPERIENCE_LOCALE';

/**
 * Get the users locale in the following order:
 *  (1) check if the URI has the 'lang' parameter. For example:
 *         https://experience-dev1.nintendo.com/#/events/11&lang=en-US
 *         NOTE: if we do find the 'lang' parameter, update the user local storage with the value
 *  (2) check if local storage has their preferred language
 *  (3) check the browsers locale
 *
 *  Returns string representation of the language (e.g. 'en-US', 'ja-JP', etc...)
 */
export const getUserLocale = (): string => {
    // We are using HashRouter from react-router, so we need to replace the "#/" from the URI
    // in order to use the url searchParams method
    const nonHashUrl = new URL(window.location.href.replace('#/', ''));
    if (nonHashUrl.searchParams.has('lang')) {
        const lang = getSupportedLocale(nonHashUrl.searchParams.get('lang'));

        // Update the URL with whatever language is returned from getSupportedLocale to be consistent
        window.history.replaceState(null, '', `#${nonHashUrl.pathname}?lang=${lang}`);
        localStorage.setItem(LOCALE_LOCAL_STORAGE_KEY, lang);
        return lang;
    }

    if (localStorage.getItem(LOCALE_LOCAL_STORAGE_KEY)) {
        return getSupportedLocale(localStorage.getItem(LOCALE_LOCAL_STORAGE_KEY));
    }

    return getSupportedLocale(window.navigator.language).valueOf();
};

/**
 * Update the users locale in local storage. If the user has a `?lang=en-US` query param (e.g.
 * coming from a microsite), remove that from the URL to avoid user confusion
 *
 * @param locale the locale (e.g. en-AU) to change the language to
 */
export const updateUserLocale = (locale: string) => {
    const nonHashUrl = new URL(window.location.href.replace('#/', ''));
    if (nonHashUrl.searchParams.has('lang')) {
        window.history.replaceState(null, '', `#${nonHashUrl.pathname}`);
    }
    localStorage.setItem(LOCALE_LOCAL_STORAGE_KEY, locale);
};

const LanguageContext = React.createContext({
    locale: getUserLocale(),
    updateLocale: updateUserLocale,
});

export default LanguageContext;
