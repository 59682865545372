import React, { ReactNode } from 'react';
import ErrorBoundary from '../common/ErrorBoundary';

/**
 * Utility method to wrap whatever node we're passing to <Route /> component with a standalone ErrorBoundary.
 * In React Router v6, you can't have any component under <Routes/> that is not their <Route /> component -_-
 *
 * @param node - React Node to be rendered inside the error boundary.
 */
export const addErrorBoundaryToRoute = (node: ReactNode) => <ErrorBoundary>{node}</ErrorBoundary>;
