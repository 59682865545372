import React, { useContext } from 'react';
import moment from 'moment';
import { PartyMember } from '../../utils/types';
import LocalizationContext from '../../context/LocalizationContext';
import { isInternal } from '../../utils/InternalUtils';

type Props = {
    partyMember: PartyMember;
    hideChildLabel?: boolean;
    hideNickname?: boolean;
};

/**
 * A simple component for displaying a formatted name of an Event Registration party member.
 * @param partyMember
 * @param hideChildLabel
 * @param hideNickname set if you don't want to display the nickname in this component
 * @constructor
 */
const PartyMemberName = ({ partyMember, hideChildLabel, hideNickname }: Props) => {
    const localizedStrings = useContext(LocalizationContext);

    if (isInternal(localizedStrings)) {
        return <span>{partyMember.participant_identification.value.toUpperCase()}</span>;
    }

    // Check if this party member is a child
    const isChild = partyMember.birthday
        ? moment().diff(moment(partyMember.birthday), 'years') < 18
        : false;

    return (
        <span>
            <span>
                {partyMember.participant_info?.FIRST_NAME
                    ? `${partyMember.participant_info.FIRST_NAME} `
                    : null}
                {partyMember.participant_info?.LAST_NAME
                    ? `${partyMember.participant_info.LAST_NAME} `
                    : null}
                {isChild && !hideChildLabel
                    ? `(${localizedStrings.tickets.registration.guest.child})`
                    : null}
            </span>
            {!hideNickname ? (
                <span>
                    {partyMember.participant_info?.FIRST_NAME ||
                    partyMember.participant_info?.LAST_NAME
                        ? ` (${partyMember.nickname})`
                        : ` ${partyMember.nickname}`}
                </span>
            ) : null}
        </span>
    );
};

export default PartyMemberName;
