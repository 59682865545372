import React from 'react';
import { Link } from 'react-router-dom';

type Props = {
    to: string | {};
    message: string;
};

/**
 * Component for a generic breadcrumb link to lead a user back to a previous screen
 */
const Breadcrumb = (props: Props) => (
    <p className="back-link hide-print" data-testid="breadcrumb">
        <Link to={props.to} className="btn btn-link">
            <i className="fas fa-chevron-left" />
            <span className="ms-2">{props.message}</span>
        </Link>
    </p>
);

export default Breadcrumb;
