import { ErrorOption, FieldPath, FieldValues } from 'react-hook-form';
import { LocalizedStrings as LocalizedStringsType } from 'react-localization';
import validator from 'validator';
import { PublicLocalizationObject } from '../../resources/locales/en_US';
import { BlockType } from './BlockUtils';
import {
    AttributeType,
    GrandPrixServiceError,
    DetailFormErrors,
    ParticipantDataCollectionMap,
    ParticipantInfoMap,
    AttendeeType,
    DataCollectionAssociation,
} from './types';

const PARTICIPANT_ID_FIELD_PREFIX = 'participant_id_';

const PARTICIPANT_ID_PREFIX_REGEX = RegExp(`^${PARTICIPANT_ID_FIELD_PREFIX}`);

/**
 * Given a participant ID, generate a form key that is safe for react-hook-form
 * @param participantId {string | number} - The participant ID to create a form key for
 */
export const getFormKeyForParticipantId = (participantId: string | number) =>
    `${PARTICIPANT_ID_FIELD_PREFIX}${participantId}`;

/**
 * Given a form key, return the participant ID it was associated with
 * @param formKey {string} - The form key that contains the participant ID
 */
export const getParticipantIdFromFormKey = (formKey: string) =>
    formKey.replace(PARTICIPANT_ID_PREFIX_REGEX, '');

/**
 * The user data from the FieldValues parameter is in the following format:
 *      "user.{userId}.{BlockType}.{form_type}"
 *
 * The following bit of code creates a new object named "participantInfo" and transforms the FieldValues into
 * a ParticipantInfoMap to send to GPS.
 *
 * Simple before:
 *  "user.12345.REGISTRANT_BLOCK.MAILING_ADDRESS.street_address"
 *
 * After:
 *  "user.12345.MAILING_ADDRESS.street_address"
 */
export const transformFormToParticipantData = (
    values: FieldValues,
): {
    participantInfo: ParticipantInfoMap;
    participantDataCollection: ParticipantDataCollectionMap;
    participantList: Array<string>;
} => {
    const attendees = values.attendees ?? {};
    const addedUsers = values.participant ?? {};
    const userFormData = values.user ?? {};
    const participantData: {
        participantInfo: ParticipantInfoMap;
        participantDataCollection: ParticipantDataCollectionMap;
        participantList: Array<string>;
    } = {
        participantInfo: {},
        participantDataCollection: {},
        participantList: [],
    };
    if (Object.values(attendees).length > 0) {
        // Event Gate Party WPP: Add attendees that have been selected to the participant list
        Object.keys(attendees).forEach((userId) => {
            if (attendees[userId]) {
                participantData.participantList.push(userId);
            }
        });
    } else {
        // Add users selected from non-Event Gate WPP party
        Object.keys(addedUsers).forEach((userId) => {
            participantData.participantList.push(userId);
        });
    }
    Object.keys(userFormData).forEach((userId) => {
        // Iterate over all the BlockType enums checking to see if we have data for that block or not
        // If we do, add it to the "participantInfo" object.
        Object.keys(BlockType).forEach((blockType) => {
            if (userFormData[userId][blockType] !== undefined) {
                if (userFormData[userId][blockType] instanceof Object) {
                    // If the user was added from a different block, check for data collection
                    Object.keys(userFormData[userId][blockType]).forEach((blockData) => {
                        // Include only blockData that contains AttributeType. Everything else should be removed
                        // as it will cause the GPS codex validation to fail if there are extra fields that
                        // don't adhere to the codex schema.
                        if (Object.values(AttributeType).includes(blockData as AttributeType)) {
                            if (
                                blockData === AttributeType.OPT_IN ||
                                blockData === AttributeType.ACCESS_CODE
                            ) {
                                // Participant Data Collection is stored in GPS
                                participantData.participantDataCollection[userId] = {
                                    ...participantData.participantDataCollection[userId],
                                    [blockData]: userFormData[userId][blockType][blockData],
                                };
                            } else {
                                // Participant Info is stored in CODEX
                                participantData.participantInfo[userId] = {
                                    ...participantData.participantInfo[userId],
                                    [blockData]: userFormData[userId][blockType][blockData],
                                };
                            }
                        }
                    });
                } else if (Object.values(AttributeType).includes(blockType as AttributeType)) {
                    participantData.participantInfo[userId] = {
                        ...participantData.participantInfo[userId],
                        ...userFormData[userId][blockType],
                    };
                }
            }
        });
    });

    return participantData;
};

/**
 * Process the Form submit response error messages.
 *
 * You can use the type guard isFormErrorResponse to make sure the gpServiceError is in the correct format.
 *
 * @param gpServiceError - the GrandPrixServiceError message to iterate over
 * @param localizedStrings - LocalizedStrings for dumping out localized error messages
 * @param setError - react-hook-form setError message to highlight which field is in error on the form
 */
export const processFormErrorResponse = (
    gpServiceError: GrandPrixServiceError<DetailFormErrors>,
    localizedStrings: LocalizedStringsType<PublicLocalizationObject>,
    setError?: (
        name: FieldPath<FieldValues>,
        error: ErrorOption,
        options?: { shouldFocus: boolean },
    ) => void,
) => {
    Object.keys(gpServiceError.details).forEach((accountId) => {
        const fieldNameInError = Object.keys(gpServiceError.details[accountId])[0];
        // We are currently only looking for ACCESS_CODE error which is a part of the GUESTS_BLOCK
        if (setError && fieldNameInError === AttributeType.ACCESS_CODE) {
            setError(`user.${accountId}.${BlockType.GUESTS_BLOCK}.${fieldNameInError}`, {
                message: localizedStrings.formatString(localizedStrings.error.formError.default, {
                    attribute: localizedStrings.events.dataAttribute[fieldNameInError],
                }) as string,
            });
        }
    });
};

/**
 * Switch any letter entry to an Upper Case.
 */

export const isUpperCaseEntry = (entryInput: string, setState: (value: string) => void) => {
    setState(entryInput.replace(/\s/g, '').toUpperCase());
};

// maximum number of characters for the zip/postal code
export const ZIP_CODE_MAX = 5;

/**
 * Postal code validation
 * @param value postal code as string
 * @return true if valid, false otherwise
 */
export const postalCodeValidator = (value: string) => {
    if (value.length > 0) {
        return validator.isPostalCode(value, 'any') && value.length === ZIP_CODE_MAX;
    }
    return true;
};

export const getSortedDataCollectionForAttendeeType = (
    attendeeType: AttendeeType,
    dataCollection?: DataCollectionAssociation[] | null,
) => {
    const validAttributes: Array<DataCollectionAssociation> = (dataCollection ?? []).filter(
        (dataItem) => {
            return dataItem.data_collection.attendee_type === attendeeType;
        },
    );

    const attributeOrder = Object.values(AttributeType);
    return validAttributes.sort((a, b) => {
        return (
            attributeOrder.indexOf(a.data_collection.attribute_type) -
            attributeOrder.indexOf(b.data_collection.attribute_type)
        );
    });
};
