import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Helper method to scroll the window to the top of the page
 */
export const scrollToTopOfPage = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
};

/**
 * In some cases when we route to a page, the page opens halfway down. This component will force
 * the page to scroll to the top. Pulled directly from the react-router-dom documentation
 * https://v5.reactrouter.com/web/guides/scroll-restoration
 */
const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        scrollToTopOfPage();
    }, [pathname]);

    return null;
};

export default ScrollToTop;
