import isEmpty from 'lodash.isempty';
import moment from 'moment';
import { ConfigType, WaitlistEntry, type EventGateResponse } from './types';

/**
 * Determine whether waitlist is enabled for the event gate
 */
export const waitlistEnabledForEventGate = (
    eventGate: Pick<EventGateResponse, 'event_gate_configs'>,
) => {
    return (
        eventGate.event_gate_configs.find((config) => {
            return config.name === ConfigType.WAITLIST_ENABLED;
        })?.value === 'true'
    );
};

export enum GateWaitlistCategory {
    INDIVIDUAL = 'individual',
    FAMILY = 'family',
    OTHER = 'other',
}

/**
 * Determine whether an event gate is for an individual, family, or other ticket. Returns `null` if category cannot be determined.
 */
export const getGateWaitlistCategory = (
    gate: Pick<EventGateResponse, 'max_attendees_per_party' | 'min_attendees_per_party'>,
) => {
    if (gate.max_attendees_per_party === 1) {
        return GateWaitlistCategory.INDIVIDUAL;
    }
    if (gate.min_attendees_per_party > 1) {
        return GateWaitlistCategory.FAMILY;
    }
    if (gate.max_attendees_per_party > 1) {
        return GateWaitlistCategory.OTHER;
    }
    return null;
};

/**
 * Get the WAITLIST_END_DATE for an event_gate, if it exists.
 */
export const getGateWaitlistEndDate = (gate?: Pick<EventGateResponse, 'event_gate_configs'>) => {
    const endDate = gate?.event_gate_configs?.find(
        ({ name, value }) => name === ConfigType.WAITLIST_END_DATE && !isEmpty(value),
    )?.value;
    if (!endDate) {
        return undefined;
    }
    return moment.utc(endDate);
};

/**
 * Calculate the number of requested tickets for a waitlist, including the implied ticket for the host
 */
export const getRequestedTicketsCount = ({
    adults_requested = 0,
    children_requested = 0,
}: Pick<WaitlistEntry, 'adults_requested' | 'children_requested'>) => {
    return adults_requested + children_requested + 1;
};
