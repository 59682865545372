import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import axios, { AxiosResponse } from 'axios';
import JWT from 'jsonwebtoken';
import moment from 'moment';
import merge from 'lodash.merge';
import {
    loginRedirect,
    OAuthAppConfig,
    getRedirectUri,
    parseEncodedStateProperties,
    replaceWindowHistory,
} from 'auth-utils';
import { AccountInfo, LOADING_ACCOUNT_INFO } from '../context/AccountContext';
import { AuthenticationState, JwtClaims } from '../utils/types';
import { localizedStrings } from '../context/LocalizationContext';
import en from '../../resources/locales/en_US';
import enInternal from '../../resources/locales/en.internal';
import { useAuthenticationSessionTimeout } from '../hooks/authenticationHooks';
import internalAppConfig from '../components/config/InternalAppConfig';

const authorizationTypes = {
    HYRULE: { gpAuthorizeFunction: 'hyrule', authorizeUri: 'oauth/authorize' },
    AZURE_AD: { gpAuthorizeFunction: 'azure-ad', authorizeUri: 'common/oauth2/authorize' },
};

// Determine the authorization type based on the auth server URL. If it starts with login.microsoft.com it's azure-ad,
// otherwise it can be assumed to be Hyrule (at somewhere like hyrule-auth.noa.com, localhost:8080, etc)
const authorizationType = internalAppConfig.authServerBaseUrl.startsWith(
    'https://login.microsoftonline.com',
)
    ? authorizationTypes.AZURE_AD
    : authorizationTypes.HYRULE;

const appConfig: OAuthAppConfig = {
    authServerBaseUrl: internalAppConfig.authServerBaseUrl,
    authorizeUri: authorizationType.authorizeUri,
    clientId: internalAppConfig.clientId,
};

// Merge in internal localizations
localizedStrings.setContent({ 'en-US': merge(en, enInternal) });

/**
 * Method to get the GP JWT token given an Authorization Code
 * @param code The Authorization Code obtained from the Auth Server
 * @param setAuthServiceError A set state action to indicate an error communicating with the authorization service
 */
const getAccessToken = async (
    code: string,
    setAuthServiceError: React.Dispatch<React.SetStateAction<boolean>>,
) => {
    const redirectUri = getRedirectUri(appConfig);
    const apiUrl = `/attendee/${authorizationType.gpAuthorizeFunction}/authorize?code=${code}&redirect_uri=${redirectUri}`;
    try {
        const response: AxiosResponse = await axios.post(apiUrl);
        return response.data['nintendo-gp-token'];
    } catch (e) {
        setAuthServiceError(true);
        return null;
    }
};

/**
 * A hook that sets up authentication for the application. It will do the auth code trade if an auth code is
 * provided, otherwise redirect the user to login. After the code is exchanged for a GP JWT then the appropriate states
 * for the app are set. A session timeout is set up to automatically refresh the user's session.
 *
 * @returns {AuthenticationState} Returns the state representing the authentication, which includes the account info,
 * a method to update the account info, an error indicator, authenticating status, and the user's nav bar element
 */
export const useAuthentication = (): AuthenticationState => {
    // Setup state
    const [authenticating, setAuthenticating] = useState<boolean>(true);
    const [accountInfo, setAccountInfo] = useState<AccountInfo>(LOADING_ACCOUNT_INFO);
    const [accessTokenExpiration, setAccessTokenExpiration] = useState<number>(0);
    const [authServiceError, setAuthServiceError] = useState<boolean>(false);

    useEffect(() => {
        const queryParameters = queryString.parse(window.location.search);
        if (queryParameters && queryParameters.code) {
            // If we have an auth code, trade it for a GP JWT
            const getGPJWT = async (): Promise<AccountInfo> => {
                const token = await getAccessToken(
                    queryParameters.code as string,
                    setAuthServiceError,
                );

                const result = JWT.decode(token) as JwtClaims;

                return {
                    token,
                    nickname: result.name,
                    code: queryParameters.code as string,
                    idToken: '',
                    userId: result.user_id,
                    loginCheck: false,
                    birthday: null,
                    participantId: result.sub,
                    userImage: result.user_image,
                    showModal: false,
                };
            };

            // Need to set to loading as getting employee events requires authentication
            setAuthenticating(true);

            getGPJWT()
                .then((response) => {
                    const result = JWT.decode(response.token) as { exp: number };

                    const expirationDelay = moment(result.exp * 1000).diff(moment()) * 0.9;

                    setAccountInfo(response);
                    setAccessTokenExpiration(expirationDelay);
                })
                .catch(() => {
                    setAuthServiceError(true);
                });

            // Set the OAuth 2 state so that the user lands on the correct page after a redirect
            let path: string | null = null;

            if (queryParameters.state) {
                const state = parseEncodedStateProperties(queryParameters.state as string);
                if (state.route) {
                    path = `#${state.route}`;
                }
            }

            replaceWindowHistory(path);
        } else {
            // If we don't have an auth code, redirect the user to the Auth Server to authorize
            loginRedirect(appConfig, false);
        }
    }, []);

    useAuthenticationSessionTimeout(accessTokenExpiration, () => {
        loginRedirect(appConfig, false);
    });

    return {
        authenticating,
        accountInfo,
        setAccountInfo,
        authServiceError,
        navBarUser: <span>{accountInfo.userId}</span>,
    };
};

/**
 * The render setup function. For INTERNAL this is a simple call back to renderApp() since no extra scripting is required.
 * @param locale {string} - The user's locale
 * @param renderApp - A function that renders the application
 */
export const renderSetup = (locale: string, renderApp: () => void) => {
    renderApp();
};

/**
 * A function that defines how to prompt the user to login. For INTERNAL this is a simple redirect to the Auth Server.
 */
export const showLogin = () => {
    loginRedirect(appConfig, false);
};

/**
 * A function defining how to render the QR Code given a user's account info. For INTERNAL it's the employee user_id in
 * the format EMPLOYEE_USER_ID=user_id
 * @param accountInfo {AccountInfo} - The user's account info
 */
export const getQrCodeValue = (accountInfo: AccountInfo) =>
    getQrCodeValueForUserId(accountInfo.userId);

export const getQrCodeValueForUserId = (userId: string) => `EMPLOYEE_USER_ID=${userId}`;
