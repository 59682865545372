import axios, { AxiosRequestHeaders, AxiosResponse } from 'axios';
import { EventGateResponse } from '../utils/types';
import { getHeaders } from './ApiUtils';

/** *
 * API function to verify an user-inputted access code
 *
 * @param eventId - Event ID
 * @param accessCode - Access code key
 * @param token - API token
 */
export const getEventGateForAccessCode = async (
    eventId: number,
    accessCode: string,
    token: string,
): Promise<EventGateResponse | null> => {
    try {
        const response: AxiosResponse<EventGateResponse> = await axios.get(
            `/attendee/events/${eventId}/event_gates`,
            {
                headers: getHeaders(token) as AxiosRequestHeaders,
                params: {
                    access_code: accessCode,
                },
            },
        );
        return response.data;
    } catch (e) {
        return null;
    }
};
