// This ignore is needed as typescript can't resolve the Webpack external
// @ts-ignore
import ttcConfig from 'ttcConfig';
import { Config } from './AppConfig';

export interface InternalConfig extends Config {
    clientId: string;
    authServerBaseUrl: string;
}

const InternalAppConfig: InternalConfig = { ...ttcConfig };

export default InternalAppConfig;
