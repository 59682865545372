import React from 'react';
import { ActivityTypeNames } from '../utils/types';

/**
 * Hook for rendering UI components for specific activity types.
 * @param actualType - The actual Activity type name for the current activity.
 * @param expectedType - The expected Activity type name for this section to be rendered.
 * @param body - Body of the component to be rendered
 */
const useActivityTypeOnly = (
    actualType: ActivityTypeNames,
    expectedType: ActivityTypeNames,
    body: React.ReactElement,
) => {
    if (actualType !== expectedType) {
        return null;
    }
    return body;
};

export default useActivityTypeOnly;
