import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { OverlayTrigger } from 'react-bootstrap';
import Tooltip from 'react-bootstrap/Tooltip';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useFetchState } from '../../../../../hooks/customHooks';
import AccountContext from '../../../../../context/AccountContext';
import { getFamily } from '../../../../../api/nintendoAccountApi';
import {
    AttendeeType,
    NintendoAccountFamilyResponse,
    NintendoAccountFamilyUser,
    ParticipantInfoMap,
} from '../../../../../utils/types';
import { localizedStrings } from '../../../../../context/LocalizationContext';
import Spinner from '../../../../common/Spinner';
import { getAttendeeType } from '../../../../../utils/AccountUtils';
import './GuestModal.css';

interface Props {
    /** if this current registration page is for a regular gate or a drawing */
    isDrawing: boolean;
    /** run once the user has confirmed their guests */
    onConfirm: (guests: Array<NintendoAccountFamilyUser>, guestInfo: ParticipantInfoMap) => void;
    /** The max number of guests allowed */
    maxGuests: number;
    /** Nintendo Account IDs deemed as ineligible */
    ineligibleGuests: Array<string>;
    /** The eligibility check parameters to provide */
    eligibilityCheck?: {
        eventGateId?: number;
        drawingId?: string;
        timeslotId?: number;
    };
    /** currently selected guests, including updates from 'remove guest' button on Guest Block */
    selectedGuests: Array<NintendoAccountFamilyUser>;
}

/**
 * Component for adding Nintendo Account guests.
 */
const GuestModal = ({
    maxGuests,
    onConfirm,
    eligibilityCheck,
    ineligibleGuests,
    isDrawing,
    selectedGuests,
}: Props) => {
    const { value: accountInfo } = useContext(AccountContext);
    const {
        value: familyResponse,
        loading,
        refresh,
    } = useFetchState<NintendoAccountFamilyResponse | null>(
        () =>
            getFamily(
                accountInfo.token,
                eligibilityCheck?.eventGateId,
                eligibilityCheck?.drawingId,
                eligibilityCheck?.timeslotId,
            ),
        {
            family: [],
            eligibility: {},
            participant_info: {},
        },
    );
    const [showModal, setShowModal] = useState(false);
    const hideModal = useCallback(() => setShowModal(false), []);
    const { register, watch, getValues, setValue } = useForm({ mode: 'onBlur' });

    const formValues = watch();
    const currentSelected = Object.values(formValues).reduce((accumulator, currentValue) => {
        if (currentValue) {
            return accumulator + 1;
        }
        return accumulator;
    }, 0);
    const counterText = localizedStrings.formatString(
        localizedStrings.tickets.registration.guest.counter,
        {
            current: currentSelected,
            max: maxGuests,
        },
    );
    const toggleModal = useCallback(() => {
        // Ensure the modal checkboxes are checked for selected users
        familyResponse?.family.forEach((user) => {
            setValue(
                user.user_id,
                selectedGuests.some((selected) => selected.user_id === user.user_id),
            );
        });
        setShowModal((prev) => !prev);
    }, [familyResponse?.family, selectedGuests, setValue]);

    // Effect to manually set the form values to false in cases we get an ineligibility notice from the API response.
    useEffect(() => {
        ineligibleGuests.map((nintendoAccountId) => {
            setValue(nintendoAccountId, false);
        });
    }, [ineligibleGuests, setValue]);

    const isSupervisor =
        familyResponse?.family.find((user) => user.user_id === accountInfo.userId)?.supervisor ??
        false;

    // Toggle the modal open if we get ineligible guests data coming in
    useEffect(() => {
        if (ineligibleGuests.length > 0) {
            toggleModal();
        }
    }, [ineligibleGuests, toggleModal]);

    const userMap: { [id: string]: NintendoAccountFamilyUser } = {};

    const noFamily = (familyResponse?.family.length || 0) <= 0;

    let body: JSX.Element | null = (
        <div className="d-flex justify-content-center">
            <Spinner className="mt-4 mb-4" />
        </div>
    );

    const ineligibleNames: Array<string> = [];

    if (!loading) {
        body = noFamily ? null : (
            <>
                <p>
                    <strong className="me-1">
                        {localizedStrings.tickets.registration.guest.selectFamilyMember}
                    </strong>
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 800 }}
                        overlay={
                            <Tooltip
                                id="family-unavailable"
                                className="guest-modal-registration-info-tooltip"
                            >
                                <span>{`${localizedStrings.tickets.registration.guest.notSelectableIf}:`}</span>
                                <ol className="ps-3 mb-0 mt-1 text-align-left remove-list-format">
                                    <li>
                                        {
                                            localizedStrings.tickets.registration.guest
                                                .reachedMaxGuests
                                        }
                                    </li>
                                    <li>
                                        {
                                            localizedStrings.tickets.registration.guest
                                                .childAndNotParent
                                        }
                                    </li>
                                    <li>
                                        {
                                            localizedStrings.tickets.registration.guest
                                                .alreadyRegistered
                                        }
                                    </li>
                                </ol>
                            </Tooltip>
                        }
                    >
                        <Button
                            variant="link"
                            className="p-0"
                            data-testid="activate-guest-modal-registration-info-tooltip"
                        >
                            <i className="text-muted far fa-info-circle ms-2" />
                        </Button>
                    </OverlayTrigger>
                </p>
                {familyResponse?.family.map((familyUser) => {
                    const isChild = getAttendeeType(familyUser.birthday) === AttendeeType.CHILD;

                    if (familyUser.user_id === accountInfo.userId) {
                        return null;
                    }

                    userMap[familyUser.user_id] = familyUser;

                    // Check if the array of ids include the current user or not.
                    // This data is separate from the eligibilityCheck object from the family response
                    // since we need to also add an alert to the modal denoting all the users that got removed.
                    const ineligibleUser = ineligibleGuests.includes(familyUser.user_id);

                    if (ineligibleUser) {
                        ineligibleNames.push(familyUser.nickname);
                    }

                    const familyMemberEligible = familyResponse.eligibility[familyUser.user_id];

                    const disableCheckbox =
                        (formValues[familyUser.user_id] !== true && currentSelected >= maxGuests) ||
                        (isChild && !isSupervisor) ||
                        !familyMemberEligible ||
                        ineligibleUser;

                    return (
                        <Form.Check
                            key={familyUser.user_id}
                            type="checkbox"
                            data-testid={`${familyUser.nickname}-checkbox`}
                            id={familyUser.user_id}
                            disabled={disableCheckbox}
                            label={
                                <>
                                    <span className="me-1">{familyUser.nickname}</span>
                                    {isChild ? (
                                        <span className="text-muted">{`(${localizedStrings.tickets.registration.guest.child})`}</span>
                                    ) : null}
                                </>
                            }
                            {...register(familyUser.user_id)}
                        />
                    );
                })}
                {currentSelected >= maxGuests ? (
                    <p className="text-danger mt-3">
                        <b>{counterText}</b>
                    </p>
                ) : (
                    <p className="text-muted mt-3">{counterText}</p>
                )}
            </>
        );
    }

    return (
        <>
            <Button
                variant="outline-primary"
                className="mb-2"
                data-testid="guest-modal-btn"
                onClick={toggleModal}
            >
                <i className="far fa-user-friends me-2" />
                <span>{localizedStrings.tickets.registration.guest.addManageGuests}</span>
            </Button>
            <Modal show={showModal} onHide={hideModal} onShow={refresh}>
                {/* Modal header */}
                <Modal.Header closeButton>
                    <Modal.Title className="me-auto">
                        {localizedStrings.tickets.registration.guest.addManageGuests}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {ineligibleNames.length > 0 ? (
                        <Alert variant="warning">
                            {localizedStrings.formatString(
                                isDrawing
                                    ? localizedStrings.tickets.registration.guest
                                          .guestsRemovedDrawing
                                    : localizedStrings.tickets.registration.guest
                                          .guestsRemovedTicket,
                                {
                                    guests: ineligibleNames.join(', '),
                                },
                            )}
                        </Alert>
                    ) : null}
                    {noFamily ? null : (
                        <Alert variant="info" className="mb-4" role="status">
                            {localizedStrings.formatString(
                                localizedStrings.tickets.registration.guest.maxLimit,
                                {
                                    max: maxGuests,
                                },
                            )}
                        </Alert>
                    )}
                    {/* Modal body. The warning message is always displayed no matter the size of the NA family. */}
                    <p>
                        {localizedStrings.formatString(
                            localizedStrings.tickets.registration.guest.guestFamilyWarning,
                            {
                                nintendoFamilyLink: (
                                    <a
                                        href={
                                            localizedStrings.tickets.registration.guest
                                                .guestFamilyUrl
                                        }
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {
                                            localizedStrings.tickets.registration.guest
                                                .guestFamilyLink
                                        }
                                    </a>
                                ),
                            },
                        )}
                    </p>
                    {body}
                </Modal.Body>
                {/* Modal footer */}
                <Modal.Footer className="justify-content-center align-content-center flex-nowrap">
                    <Button
                        variant="outline-secondary"
                        data-testid="guest-modal-cancel-btn"
                        className="me-md-auto"
                        onClick={toggleModal}
                    >
                        {localizedStrings.general.cancel}
                    </Button>
                    {noFamily ? (
                        <Button
                            as="a"
                            href="https://accounts.nintendo.com/family/add_member"
                            target="_blank"
                            rel="noreferrer"
                            onClick={toggleModal}
                        >
                            <span>
                                {
                                    localizedStrings.tickets.registration.guest
                                        .manageNintendoAccountFamily
                                }
                            </span>
                            <i className="fas fa-external-link-alt small" />
                        </Button>
                    ) : (
                        <Button
                            variant="primary"
                            data-testid="guest-modal-confirm-btn"
                            onClick={() => {
                                const addedGuests: Array<NintendoAccountFamilyUser> = [];
                                const finalResults = getValues();

                                Object.keys(finalResults).map((userId) => {
                                    const wasSelected = finalResults[userId];
                                    if (wasSelected) {
                                        addedGuests.push(userMap[userId]);
                                    }
                                });
                                onConfirm(addedGuests, familyResponse?.participant_info ?? {});
                                toggleModal();
                            }}
                        >
                            {localizedStrings.general.confirm}
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default GuestModal;
