import { cancelTemporaryReservation } from '../api/eventApi';

export const TEMP_RESERVATION_LOCAL_STORAGE_KEY = 'tempsToCancel';

/**
 * Cancels any old temp reservations that are stored in localStorage
 * @param token users accountInfo token
 */
export const cancelOldTempReservations = async (token: string): Promise<null> => {
    const storageValue: string | null = localStorage.getItem(TEMP_RESERVATION_LOCAL_STORAGE_KEY);

    const dataArray: Array<string> = storageValue ? JSON.parse(storageValue) : [];
    await Promise.all(dataArray.map((id) => cancelTemporaryReservation(token, id)));
    localStorage.removeItem(TEMP_RESERVATION_LOCAL_STORAGE_KEY);

    return null;
};

/**
 * Adds data to the TEMP_RESERVATION_LOCAL_STORAGE_KEY in localStorage
 * @param value: string value to store
 */
export const addTempReservationsToLocalStorage = (value: string) => {
    localStorage.setItem(TEMP_RESERVATION_LOCAL_STORAGE_KEY, value);
};

/**
 * Removes the TEMP_RESERVATION_LOCAL_STORAGE_KEY from localStorage
 */
export const removeTempReservationsFromLocalStorage = () => {
    localStorage.removeItem(TEMP_RESERVATION_LOCAL_STORAGE_KEY);
};
