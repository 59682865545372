// @ts-ignore
import ttcConfig from 'ttcConfig';

export interface Config {
    baseUrl: string;
    googleMapsApiKey: string;
    nintendoAccountClientId: string;
    redirectUri: string;
    alpsJs: { [key: string]: string };
    audience?: string;
}

const AppConfig: Config = { ...ttcConfig };

export default AppConfig;
