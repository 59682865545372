import React from 'react';
import Loading from 'react-loading';
import './Spinner.css';

type Props = {
    className?: string;
};

/**
 * General purpose spinner component while the main component is fetching some data.
 *
 * @param className - Alternative CSS class names
 */
const Spinner = ({ className }: Props) => (
    <div
        className={className || 'SpinnerView'}
        aria-busy="true"
        role="status"
        aria-label="content-loading"
        data-testid="spinner"
    >
        <Loading color="#E8001E" />
    </div>
);

export default Spinner;
