import React, { useContext } from 'react';
import LocalizationContext from '../../context/LocalizationContext';
import LocalizedDate from './LocalizedDate';

export const FormattedTimeRange = ({
    startDate,
    endDate,
    timezone,
    overrideFormat = null,
    hasLineBreak = false,
}: {
    startDate: string;
    endDate: string | null;
    timezone: string;
    overrideFormat?: string | null;
    hasLineBreak?: boolean | null;
}) => {
    const localizedText = useContext(LocalizationContext);
    const format = overrideFormat == null ? localizedText.dates.hourMinute : overrideFormat;
    return (
        <>
            <LocalizedDate date={startDate} format={format} timezone={timezone} />
            {endDate != null ? (
                <>
                    {localizedText.dates.range}
                    {hasLineBreak ? <br /> : null}
                    <LocalizedDate date={endDate} format={format} timezone={timezone} />
                </>
            ) : null}
        </>
    );
};
