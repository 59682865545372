import axios, { AxiosRequestHeaders, AxiosResponse } from 'axios';
import {
    BaseTicket,
    ConfirmTicketsRequest,
    DetailFormErrors,
    DrawingTicket,
    GrandPrixServiceError,
    Ticket,
    TicketResource,
} from '../utils/types';
import { checkExceptionForGrandPrixError } from '../utils/ErrorUtils';
import { audienceQueryParam, getHeaders } from './ApiUtils';

/**
 * Call the ticket creation API to create tickets for the specified resource
 */
const getTickets = async ({
    resourceType,
    resourceId,
    token,
    accessCode,
    waitlistEntryId,
}: {
    /**
     * The resource type to create ticket(s) for. Ex) event_gates
     */
    resourceType: TicketResource;
    /**
     * The ID of the resource to create ticket(s) for
     */
    resourceId: string | number;
    /**
     * The user's access token
     */
    token: string;
    /**
     * Optional access code for access code-guarded Event Gates
     */
    accessCode?: string;
    /**
     * Optional waitlist entry id for waitlist-guarded Event Gates
     */
    waitlistEntryId?: string;
}): Promise<Array<Ticket>> => {
    const headers = getHeaders(token) as AxiosRequestHeaders;
    const apiUri = `/attendee/${resourceType}/${resourceId}/tickets`;
    const body = {} as { access_code?: string; waitlist_entry_id?: string };

    if (accessCode) {
        body.access_code = accessCode;
    }
    if (waitlistEntryId) {
        body.waitlist_entry_id = waitlistEntryId;
    }

    const response: AxiosResponse<Array<Ticket>> = await axios.post(apiUri, body, { headers });

    return response.data;
};

/**
 * Confirm these Tickets
 * @param resourceType one of TicketResource
 * @param resourceId the ID for this resource
 * @param token users token
 * @param locale the locale to use for notifications
 * @param confirmRequest a ConfirmTicketsRequest object
 */
export const confirmTickets = async (
    resourceType: TicketResource,
    resourceId: string | number,
    token: string,
    locale: string,
    confirmRequest: ConfirmTicketsRequest,
): Promise<Array<Ticket> | GrandPrixServiceError<Array<string> | DetailFormErrors>> => {
    const headers = {
        Authorization: `Bearer ${token}`,
        'Accept-Language': locale,
    };

    const apiUri = `/attendee/${resourceType}/${resourceId}/tickets`;

    try {
        const response: AxiosResponse<Array<Ticket>> = await axios.put(apiUri, confirmRequest, {
            headers,
        });
        return response.data;
    } catch (e) {
        return checkExceptionForGrandPrixError(e);
    }
};

/**
 * Call the ticket creation API to create tickets for an event gate
 */
export const getEventGateTickets = async ({
    eventGateId,
    token,
    accessCode,
    waitlistEntryId,
}: {
    /** The event gate's event_gate_id */
    eventGateId: number;
    /** The user's access token */
    token: string;
    /** optional Access Code */
    accessCode?: string;
    /** Optional waitlist entry id for waitlist-guarded Event Gates */
    waitlistEntryId?: string;
}): Promise<Array<Ticket>> => {
    return getTickets({
        resourceType: TicketResource.EVENT_GATES,
        resourceId: eventGateId,
        token,
        accessCode,
        waitlistEntryId,
    });
};

/**
 * Call the ticket creation API to create tickets for a drawing
 * @returns Returns an array of tickets
 */
export const getDrawingTickets = async (
    /** The drawing's drawing_id */
    drawingId: string,
    /** The user's access token */
    token: string,
): Promise<Array<Ticket>> => {
    return getTickets({
        resourceType: TicketResource.DRAWINGS,
        resourceId: drawingId,
        token,
    });
};

/**
 * Retrieve tickets for the specific ticket type
 * /attendee/me/{resourceType}/tickets
 * @param ticketType TicketResource
 * @param token Users bearer token
 */
export const getMyTickets = async (
    ticketType: TicketResource,
    token: string,
): Promise<Array<DrawingTicket | BaseTicket> | null> => {
    const apiUri = `/attendee/me/${ticketType}/tickets`;

    try {
        const response: AxiosResponse<Array<DrawingTicket | BaseTicket>> = await axios.get(apiUri, {
            params: audienceQueryParam,
            headers: getHeaders(token) as AxiosRequestHeaders,
        });
        return response.data;
    } catch (e) {
        return null;
    }
};

/**
 * API function for getting an iOS wallet pass for a ticket
 *
 * @param resourceType - Type of resource this ticket is for
 * @param resourceId - ID of the resource this ticket is for
 * @param ticketId ID of the ticket
 * @param token Token provided by JWT
 * @return Blob | null - Returns a blob if the data is generated. Returns null otherwise.
 */
export const getTicketWalletPass = async (
    resourceType: string,
    resourceId: string,
    ticketId: string,
    token: string,
) => {
    const apiUrl = `/attendee/me/${resourceType}/${resourceId}/tickets/${ticketId}/apple-wallet-pass`;

    try {
        const headers = getHeaders(token) as AxiosRequestHeaders;
        const response: AxiosResponse<Blob> = await axios.get(apiUrl, {
            headers,
            data: {},
            responseType: 'blob',
        });
        return response.data;
    } catch (e) {
        return null;
    }
};

/**
 * API function for getting add to calendar links
 *
 * @param ticketResourceType - Type of resource this ticket is for; i.e. event-gates
 * @param ticketResourceId - ID of the resource this ticket is for.
 * @param ticketId - ID of the ticket
 * @param token - Token provided by JWT
 * @return JSON | null - return a blob if the data is return. Null otherwise
 */
export const getTicketCalendarLinks = async (
    ticketResourceType: string,
    ticketResourceId: string,
    ticketId: string,
    token: string,
) => {
    const apiUri = `/attendee/me/${ticketResourceType}/${ticketResourceId}/tickets/${ticketId}/calendars/links`;

    try {
        const headers = getHeaders(token) as AxiosRequestHeaders;
        const response: AxiosResponse = await axios.get(apiUri, {
            headers,
        });
        return response.data;
    } catch (e) {
        return null;
    }
};

/**
 * API function for canceling a ticket.
 *
 * @param token - Token provided by JWT
 * @param ticketResourceType - Type of resource this ticket is for; i.e. event-gates
 * @param ticketId - ID of the ticket
 * @param partyId - ID of the party this ticket is for
 * @param participantId - Participant who we want to cancel the ticket for
 * @return boolean - True if cancel succeeded; false otherwise
 */
export const cancelTicket = async (
    token: string,
    ticketResourceType: string,
    ticketId: string,
    partyId: string,
    participantId: number,
): Promise<boolean> => {
    const apiUri = `attendee/${ticketResourceType}/tickets/${ticketId}/parties/${partyId}/participants/${participantId}`;
    try {
        const headers = getHeaders(token) as AxiosRequestHeaders;
        const response: AxiosResponse = await axios.delete(apiUri, {
            headers,
        });
        return response.status === 200;
    } catch (e) {
        return false;
    }
};
