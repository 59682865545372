import React from 'react';
import { HTMLMotionProps, motion } from 'framer-motion';

interface Props extends HTMLMotionProps<'div'> {
    key: string;
}

/**
 * Generic component adding a fade animation effect around a component
 */
const Fade = (props: Props) => {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            {...props}
        >
            {props.children}
        </motion.div>
    );
};

export default Fade;
