import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import ReactModal from 'react-modal';
import axios from 'axios';
import { renderSetup } from 'ttc-authentication';
import App from './src/js/components/App';
import './src/resources/css/sitestyles.css';
import KeyContext, { ApiKey } from './src/js/context/KeyContext';
import AppConfig from './src/js/components/config/AppConfig';
import { getUserLocale } from './src/js/context/LanguageContext';

// Bootstrap global imports
import 'bootstrap/dist/css/bootstrap.min.css';

ReactModal.setAppElement('#root');
axios.defaults.baseURL = AppConfig.baseUrl;

const key: ApiKey = AppConfig.googleMapsApiKey;
const locale = getUserLocale();

const ContextApp = () => {
    useEffect(() => {
        // React 18s new optimized render is making us confirm this is called
        // here at the end after component is rendered. Public only.
        if (global.window.Alps) {
            global.window.Alps.mountRiot.call();
        }
    }, []);
    return (
        <KeyContext.Provider value={key}>
            <App />
        </KeyContext.Provider>
    );
};

const renderApp = () => {
    const container = document.getElementById('root');
    const root = createRoot(container!);
    root.render(<ContextApp />);
};

renderSetup(locale, renderApp);
