import { useContext } from 'react';
import moment from 'moment-timezone/builds/moment-timezone-with-data-1970-2030.min';

import 'moment/locale/en-gb';
import 'moment/locale/en-au';
import 'moment/locale/fr';
import 'moment/locale/fr-ca';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/it';
import 'moment/locale/nl';

import { SupportedLocale } from '../../utils/types';
import LanguageContext from '../../context/LanguageContext';
import { getSupportedLocale } from '../../utils/LanguageUtils';
import { isInternal } from '../../utils/InternalUtils';
import LocalizationContext from '../../context/LocalizationContext';

type Props = {
    /** The raw date string. */
    date: string;
    /** The format to display the date in. */
    format: string;
    /** The timezone relative to the date. */
    timezone: string;
    /** Value that indicates if the entire date should be capitalized. */
    upperCase?: boolean;
};

/**
 * Utility-like component that renders a date according to the end user's language and locale.
 */
const LocalizedDate = ({ date, format, timezone, upperCase }: Props) => {
    const { locale } = useContext(LanguageContext);
    const localizedStrings = useContext(LocalizationContext);

    let supportedLocale: SupportedLocale = getSupportedLocale(locale);

    // If internal, fix it to English so that we don't have moment
    // render dates in other locales.
    if (isInternal(localizedStrings)) {
        supportedLocale = SupportedLocale.EN_US;
    }

    const formattedDate = moment(date).locale(supportedLocale).tz(timezone).format(format);

    if (upperCase) {
        return formattedDate.toUpperCase();
    }

    return formattedDate;
};

export default LocalizedDate;
