import React from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import CountryRegionList, {
    CountryRegionType,
    RegionType,
} from '../../../../resources/assets/country-region';
import { localizedStrings } from '../../../context/LocalizationContext';
import { postalCodeValidator, ZIP_CODE_MAX } from '../../../utils/FormUtils';
import { DataCollectionAssociation, ParticipantInfo } from '../../../utils/types';
import InformationalToolTip from '../../policies/InformationalToolTip';
import GenericField from './GenericField';
import SelectField from './SelectField';
import './FieldStyles.css';

type MailingAddressProps = {
    dataCollectionItem: DataCollectionAssociation;
    formControlReferencePrefix: string;
    participantInfo?: ParticipantInfo;
};

/**
 * Component for rendering all the form elements needed for Mailing address
 *
 * @param dataCollectionItem
 * @param formControlReferencePrefix
 * @param participantInfo
 */
const MailingAddressFields = ({
    dataCollectionItem,
    formControlReferencePrefix,
    participantInfo,
}: MailingAddressProps) => {
    let regionList: Array<string> = [];
    CountryRegionList.map((country: CountryRegionType) => {
        country.regions.forEach((region: RegionType) => {
            regionList = [...regionList, region.name];
        });
    });

    return (
        <>
            <Form.Label className="mt-2">
                {localizedStrings.events.dataAttribute.US_ADDRESS}
                {dataCollectionItem.data_collection.require_response ? (
                    <span className="field-error-color">*</span>
                ) : null}{' '}
                {dataCollectionItem.data_collection.tooltip ? (
                    <InformationalToolTip tooltip={dataCollectionItem.data_collection.tooltip} />
                ) : null}
            </Form.Label>
            <GenericField
                nickname=""
                isRequired={dataCollectionItem.data_collection.require_response}
                label={localizedStrings.events.dataAttribute.MAILING_ADDRESS.streetAddress}
                placeholder={localizedStrings.events.dataAttribute.MAILING_ADDRESS.streetAddress}
                defaultValue={
                    participantInfo &&
                    participantInfo.US_ADDRESS &&
                    participantInfo.US_ADDRESS.addressLine1
                        ? participantInfo.US_ADDRESS.addressLine1
                        : undefined
                }
                formControlReference={`${formControlReferencePrefix}.US_ADDRESS.addressLine1`}
            />
            <Row>
                <GenericField
                    nickname=""
                    isRequired={false}
                    label={localizedStrings.events.dataAttribute.MAILING_ADDRESS.aptOrSuite}
                    placeholder={localizedStrings.events.dataAttribute.MAILING_ADDRESS.aptOrSuite}
                    defaultValue={
                        participantInfo &&
                        participantInfo.US_ADDRESS &&
                        participantInfo.US_ADDRESS.addressLine2
                            ? participantInfo.US_ADDRESS.addressLine2
                            : undefined
                    }
                    formControlReference={`${formControlReferencePrefix}.US_ADDRESS.addressLine2`}
                />
                <GenericField
                    nickname=""
                    isRequired={dataCollectionItem.data_collection.require_response}
                    label={localizedStrings.events.dataAttribute.MAILING_ADDRESS.city}
                    placeholder={localizedStrings.events.dataAttribute.MAILING_ADDRESS.city}
                    defaultValue={
                        participantInfo &&
                        participantInfo.US_ADDRESS &&
                        participantInfo.US_ADDRESS.city
                            ? participantInfo.US_ADDRESS.city
                            : undefined
                    }
                    formControlReference={`${formControlReferencePrefix}.US_ADDRESS.city`}
                />
            </Row>
            <Row>
                <Col sm={12} md={4}>
                    <SelectField
                        options={regionList}
                        isRequired={dataCollectionItem.data_collection.require_response}
                        label={localizedStrings.events.dataAttribute.MAILING_ADDRESS.state}
                        defaultValue={
                            participantInfo &&
                            participantInfo.US_ADDRESS &&
                            participantInfo.US_ADDRESS.region
                                ? participantInfo.US_ADDRESS.region
                                : undefined
                        }
                        formControlReference={`${formControlReferencePrefix}.US_ADDRESS.region`}
                    />
                </Col>

                <Col sm={12} md={4}>
                    <SelectField
                        disabled
                        options={CountryRegionList.map((country) => country.countryName)}
                        label={localizedStrings.events.dataAttribute.MAILING_ADDRESS.country}
                        defaultValue={
                            participantInfo &&
                            participantInfo.US_ADDRESS &&
                            participantInfo.US_ADDRESS.country
                                ? participantInfo.US_ADDRESS.country
                                : 'United States'
                        }
                        isRequired={dataCollectionItem.data_collection.require_response}
                        formControlReference={`${formControlReferencePrefix}.US_ADDRESS.country`}
                    />
                </Col>

                <Col sm={12} md={4}>
                    <GenericField
                        nickname=""
                        isRequired={dataCollectionItem.data_collection.require_response}
                        label={localizedStrings.events.dataAttribute.MAILING_ADDRESS.zipCode}
                        placeholder={localizedStrings.events.dataAttribute.MAILING_ADDRESS.zipCode}
                        defaultValue={
                            participantInfo &&
                            participantInfo.US_ADDRESS &&
                            participantInfo.US_ADDRESS.postalCode
                                ? participantInfo.US_ADDRESS.postalCode
                                : undefined
                        }
                        formControlReference={`${formControlReferencePrefix}.US_ADDRESS.postalCode`}
                        validator={postalCodeValidator}
                        maxLength={ZIP_CODE_MAX}
                    />
                </Col>
            </Row>
        </>
    );
};

export default MailingAddressFields;
