import React, { useContext, useCallback } from 'react';
import { showLogin } from 'ttc-authentication';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import LocalizationContext from '../../context/LocalizationContext';
import AccountContext from '../../context/AccountContext';

type Props = {
    onCancel?: () => void;
};

const AccountRequiredModal = ({ onCancel }: Props) => {
    const localizedStrings = useContext(LocalizationContext);
    const { value: accountInfo, setValue } = useContext(AccountContext);

    const onClick = useCallback(() => showLogin(), []);

    const hideModal = () => {
        setValue({ ...accountInfo, showModal: false });
    };

    return (
        <Modal
            id="nintendo-account-login-required-modal"
            data-testid="nintendo-account-required-modal"
            show={!accountInfo.userId && accountInfo.showModal}
            onHide={() => {
                hideModal();
            }}
        >
            <Modal.Header closeButton>
                <Modal.Title>{localizedStrings.account.accountRequired}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{localizedStrings.account.requiredToRSVP}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={() => {
                        if (onCancel) {
                            onCancel();
                        }
                        hideModal();
                    }}
                >
                    {localizedStrings.general.cancel}
                </Button>
                <Button onClick={onClick} variant="primary">
                    {localizedStrings.general.signInCreateAccount}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AccountRequiredModal;
