import axios, { AxiosRequestHeaders, AxiosResponse } from 'axios';
import {
    ActivityDataCollection,
    ActivityResponse,
    EventActivityResponse,
    EventDataCollection,
    EventGateDataCollection,
    EventGateDrawing,
    EventGateResponse,
    EventResponse,
    GrandPrixServiceError,
    PaginatedResponse,
    ParticipantInfoMap,
    Reservation,
    Ticket,
    TicketResource,
} from '../utils/types';
import { checkExceptionForGrandPrixError } from '../utils/ErrorUtils';
import { audienceQueryParam, getHeaders } from './ApiUtils';
import { fetchDrawings, fetchDrawingsAuthenticated } from './drawingsApi';

const queryParams = (params: { [key: string]: string | number }) => {
    return { ...params, ...audienceQueryParam };
};

/**
 * The call to get a specific page of events data
 * @param apiUri - The API URI to call (ex: /attendee/events)
 * @param page - The page to retrieve (starts at 1)
 * @param token - The user's token if they have one
 */
const fetchEventsPage = async (apiUri: string, page: number, token: string | null) => {
    const response: AxiosResponse<PaginatedResponse<EventResponse>> = await axios.get(apiUri, {
        params: queryParams({ page }),
        headers: token ? (getHeaders(token) as AxiosRequestHeaders) : {},
    });
    return response.data;
};

/**
 * A function that parses the paginated API and returns all events.
 *
 * @param apiUri - The API URI to call (ex: /attendee/events)
 * @param token - The user's auth token if they have one
 */
const fetchAllEvents = async (apiUri: string, token: string | null) => {
    try {
        const events: Array<EventResponse> = [];

        const firstPage = await fetchEventsPage(apiUri, 1, token);

        events.push(...firstPage.data);

        const totalPages = firstPage.total_pages;

        if (totalPages > 1) {
            const promiseArray: Array<Promise<void>> = [];

            for (let i = 2; i <= totalPages; i++) {
                const promise: Promise<void> = new Promise(async (resolve) => {
                    const page = await fetchEventsPage(apiUri, i, token);
                    events.push(...page.data);
                    resolve();
                });

                promiseArray.push(promise);
            }
            await Promise.all(promiseArray);
        }

        return events;
    } catch (e) {
        return null;
    }
};

/**
 * API call to get all public events
 * @return {EventResponse[]} - Returns an array of EventInfo objects
 */
export const fetchEventsPublic = async () => {
    return fetchAllEvents('/public/events', null);
};

/**
 * API call to get all events for an authenticated user
 * @return {EventResponse[]} - Returns an array of EventInfo objects
 */
export const fetchEventsAuthenticated = async (token: string) => {
    return fetchAllEvents('/attendee/events', token);
};

/**
 * API call to get a single event and the associated activities
 * @param eventId - ID of the event
 * @return {EventActivityResponse} - Returns a single EventActivityResponse
 */
export const fetchEvent = async (eventId: number) => {
    const apiUri = `/public/events/${eventId}`;

    try {
        const response: AxiosResponse<EventActivityResponse> = await axios.get(apiUri);
        return response.data;
    } catch (e) {
        return null;
    }
};

/**
 * API call to get a single event and the associated activities
 * @param eventId - ID of the event
 * @param token - The user's GP JWT token
 * @return {EventActivityResponse} - Returns a single EventActivityResponse
 */
export const fetchEventAuthenticated = async (eventId: number | string, token: string) => {
    const apiUri = `/attendee/events/${eventId}`;

    try {
        const response: AxiosResponse<EventActivityResponse> = await axios.get(apiUri, {
            headers: getHeaders(token) as AxiosRequestHeaders,
        });
        return response.data;
    } catch (e) {
        return null;
    }
};

/**
 * Get all event gates for this event_id
 * @param eventId - ID of the event
 * @return {Array<EventGateResponse>} - Returns a array of EventGates
 */
export const fetchEventGates = async (eventId: number) => {
    const apiUri = `/public/events/${eventId}/event_gates`;

    try {
        const response: AxiosResponse<Array<EventGateResponse>> = await axios.get(apiUri);
        return response.data;
    } catch (e) {
        return null;
    }
};

/**
 * Get all event gates for this event_id
 * @param eventId - ID of the event
 * @param token - Service auth token
 * @return {Array<EventGateResponse>} - Returns a array of EventGates
 */
export const fetchEventGatesAuthenticated = async (
    eventId: number,
    token: string,
): Promise<Array<EventGateResponse> | null> => {
    const apiUri = `/attendee/events/${eventId}/event_gates`;

    try {
        const response: AxiosResponse<Array<EventGateResponse>> = await axios.get(apiUri, {
            headers: getHeaders(token) as AxiosRequestHeaders,
        });
        return response.data;
    } catch (e) {
        return null;
    }
};

/**
 * Get all event gates for this event_id, with drawing data populated
 * @param eventId - ID of the event
 * @param token - Service auth token
 * @return {Array<EventGateDrawing>} - Returns a array of EventGateDrawing objects
 */
export const fetchEventGatesWithDrawingsAuthenticated = async (
    eventId: number,
    token: string,
): Promise<Array<EventGateDrawing>> => {
    const eventGates = await fetchEventGatesAuthenticated(eventId, token);

    if (eventGates) {
        return Promise.all(
            eventGates.map(async (eventGate) => {
                const drawings = await fetchDrawingsAuthenticated(
                    TicketResource.EVENT_GATES,
                    eventGate.event_gate_id,
                    token,
                );
                return { ...eventGate, drawings };
            }),
        );
    }
    return [];
};

/**
 * Get all event gates with drawing data for this event_id
 * @param eventId - ID of the event
 * @return {Array<EventGateResponse>} - Returns an array of EventGateDrawing objects
 */
export const fetchEventGatesWithDrawings = async (
    eventId: number,
): Promise<Array<EventGateDrawing>> => {
    const eventGates = await fetchEventGates(eventId);
    if (eventGates) {
        return Promise.all(
            eventGates.map(async (eventGate) => {
                const drawings = await fetchDrawings(
                    TicketResource.EVENT_GATES,
                    eventGate.event_gate_id,
                );
                return { ...eventGate, drawings };
            }),
        );
    }
    return [];
};

/**
 * API method for returning all Event level data attributes to be collected from the user.
 * @param eventId - Event ID
 * @param token - GP token
 */
export const fetchEventDataCollection = async (
    eventId: number,
    token: string,
): Promise<Array<EventDataCollection> | null> => {
    const apiUri = `/attendee/events/${eventId}/data_collection`;

    try {
        const response: AxiosResponse<Array<EventDataCollection>> = await axios.get(apiUri, {
            headers: getHeaders(token) as AxiosRequestHeaders,
        });
        return response.data;
    } catch (e) {
        return null;
    }
};

/**
 * API method for returning all Event level data attributes to be collected from the user.
 * @param eventId - Event ID
 * @param eventGateId - Event Gate ID
 * @param token - GP token
 */
export const fetchEventGateDataCollection = async (
    eventId: number,
    eventGateId: number,
    token: string,
): Promise<Array<EventGateDataCollection> | null> => {
    const apiUri = `/attendee/events/${eventId}/event_gates/${eventGateId}/data_collection`;

    try {
        const response: AxiosResponse<Array<EventGateDataCollection>> = await axios.get(apiUri, {
            headers: getHeaders(token) as AxiosRequestHeaders,
        });
        return response.data;
    } catch (e) {
        return null;
    }
};

/**
 * Fetch details for an event gate
 * @param eventId event id
 * @param eventGateId event gate id
 * @param token user session token
 * @returns Promise<EventGateResponse | null>
 */
export const fetchEventGateDetails = async (
    eventId: number | string,
    eventGateId: number | string,
    token: string,
): Promise<EventGateResponse | null> => {
    const apiUri = `/attendee/events/${eventId}/event_gates/${eventGateId}`;

    try {
        const response: AxiosResponse<EventGateResponse> = await axios.get(apiUri, {
            headers: getHeaders(token) as AxiosRequestHeaders,
        });
        return response.data;
    } catch (e) {
        return null;
    }
};

/**
 * API method for returning all Activity level data attributes to be collected from the user.
 * @param eventId - Event ID
 * @param activityId - Activity ID
 * @param token - GP token
 */
export const fetchActivityDataCollection = async (
    eventId: number,
    activityId: number,
    token: string,
): Promise<Array<ActivityDataCollection> | null> => {
    const apiUri = `/attendee/events/${eventId}/activities/${activityId}/data_collection`;

    try {
        const response: AxiosResponse<Array<ActivityDataCollection>> = await axios.get(apiUri, {
            headers: getHeaders(token) as AxiosRequestHeaders,
        });
        return response.data;
    } catch (e) {
        return null;
    }
};

/**
 * API for getting a single activity detail
 * @param eventId - ID of the event
 * @param activityId - ID of the activity
 * @return {ActivityResponse} - Returns a single ActivityResponse
 */
export const fetchActivity = async (
    eventId: number,
    activityId: number,
): Promise<ActivityResponse | null> => {
    const apiUri = `/public/events/${eventId}/activities/${activityId}`;

    try {
        const response: AxiosResponse<ActivityResponse> = await axios.get(apiUri);
        return response.data;
    } catch (e) {
        return null;
    }
};

/**
 * API for getting a single activity detail
 * @param eventId - ID of the event
 * @param activityId - ID of the activity
 * @param token - The user's GP JWT token
 * @return {ActivityResponse} - Returns a single ActivityResponse
 */
export const fetchActivityAuthenticated = async (
    eventId: number,
    activityId: number,
    token: string,
): Promise<ActivityResponse | null> => {
    const apiUri = `/attendee/events/${eventId}/activities/${activityId}`;

    try {
        const response: AxiosResponse<ActivityResponse> = await axios.get(apiUri, {
            headers: getHeaders(token) as AxiosRequestHeaders,
        });
        return response.data;
    } catch (e) {
        return null;
    }
};

export const createTemporaryReservations = async (
    eventId: number,
    activityId: number,
    timeSlotId: number,
    token: string,
    cancelReservationId: string,
    partyId?: string,
) => {
    const apiUri = `/attendee/events/${eventId}/activities/${activityId}/timeslots/${timeSlotId}/temporary-reservations`;

    try {
        const headers = getHeaders(token) as AxiosRequestHeaders;
        const response: AxiosResponse<Array<Reservation | Ticket>> = await axios.post(
            apiUri,
            {
                cancel_reservation_id: cancelReservationId,
                party_id: partyId,
            },
            { headers },
        );
        return response.data;
    } catch (e: any) {
        if (e.response && e.response.data.code && e.response.data.message) {
            throw e.response.data.code;
        }
        return null;
    }
};

/**
 * Cancel this reservationId. Any errors are ignored.
 * @param token user accountInfo token
 * @param reservationId the reservation to cancel
 */
export const cancelTemporaryReservation = async (token: string, reservationId: string) => {
    const apiUri = `/attendee/me/reservations/${reservationId}`;
    try {
        const headers = getHeaders(token) as AxiosRequestHeaders;
        await axios.delete(apiUri, { headers });
    } catch (e) {
        // intentionally ignoring
    }
};

/**
 * Confirm temporary reservations
 * @param token users token
 * @param locale users selected locale
 * @param temporaryReservations array of ALL temporary Reservation objects
 * @param guests list of Nintendo Account ID for guests
 * @param participantInfo optional information about the user (e.g. first name, zip code, etc...)
 * @param cancelReservationId cancel reservation id. Defaults to an empty string
 * @param addMeToParty add user to the party or not
 */
export const confirmTemporaryReservation = async (
    token: string,
    locale: string,
    temporaryReservations: Array<Reservation>,
    guests: Array<string>,
    participantInfo: ParticipantInfoMap,
    cancelReservationId: string = '',
    addMeToParty: boolean,
): Promise<Array<Reservation> | GrandPrixServiceError<Array<string>>> => {
    const apiUri = `/attendee/me/temporary-reservations`;

    try {
        const headers = {
            Authorization: `Bearer ${token}`,
            'Accept-Language': locale,
        };
        const response: AxiosResponse<Array<Reservation>> = await axios.put(
            apiUri,
            {
                reservations: temporaryReservations,
                guests,
                participantInfo,
                cancel_reservation_id: cancelReservationId,
                addMeToParty,
            },
            {
                headers,
            },
        );
        return response.data;
    } catch (e) {
        return checkExceptionForGrandPrixError(e);
    }
};

/**
 * API function for canceling a reservation.
 *
 * @param token - Token provided by JWT
 * @param reservationId - ID of the reservation
 * @return boolean - True if cancel succeeded; false otherwise
 */
export const cancelReservation = async (token: string, reservationId: string): Promise<boolean> => {
    const apiUri = `/attendee/me/reservations/${reservationId}`;
    try {
        const headers = getHeaders(token) as AxiosRequestHeaders;
        const response: AxiosResponse = await axios.delete(apiUri, {
            headers,
        });
        return response.status === 200;
    } catch (e) {
        return false;
    }
};
