import React, { useCallback, useContext, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ErrorOption, FieldPath, FieldValues, FormProvider, useForm } from 'react-hook-form';
import Loading from 'react-loading';
import LocalizationContext from '../../context/LocalizationContext';
import PassOnTicketsModal from '../account/myTicketsAndPasses/MyWaitlists/WaitlistAccordionItem/PassOnTicketsModal';
import './RegistrationForm.css';

interface RegistrationFormProps extends React.PropsWithChildren<{}> {
    title: string | JSX.Element | null;
    onSubmit?: (
        data: FieldValues,
        setError?: (
            name: FieldPath<FieldValues>,
            error: ErrorOption,
            options?: { shouldFocus: boolean },
        ) => void,
    ) => void | Promise<void>;
    buttonLabel?: string;
    isWaitlist?: boolean;
    onPassWaitlist?: () => void | Promise<void>;
}

/**
 * Form component for various registration processes. Since the various registration components (e.g. TicketSignUp)
 * can get rather larger, this component is to help clean those components up and to standardize on around single
 * FormProvider.
 *
 * @param title: title
 * @param onSubmit: form submission callback method with the following params:
 *      data: passes a FieldValues object with all the fields data
 *      setError: optional react-hook-form setError hook, so you can add custom error messages based on responses
 * @param children: optional Array of ReactElements. Usually any of the registration/block components
 * @param buttonLabel: optional string label for the button. Defaults to "Confirm"
 */
const RegistrationForm = ({
    title,
    onSubmit,
    buttonLabel,
    isWaitlist,
    onPassWaitlist,
    children,
}: RegistrationFormProps) => {
    const localizedStrings = useContext(LocalizationContext);
    const formMethods = useForm({ mode: 'onChange', shouldUnregister: true });

    const { setError } = formMethods;
    const { isSubmitting, isValidating } = formMethods.formState;

    const [isSubmittingToBackend, setIsSubmittingToBackend] = useState(false);

    // Make sure the button is disabled while we are submitting the data...
    const onFormSubmit = async (values: FieldValues) => {
        setIsSubmittingToBackend(true);
        if (onSubmit) {
            await onSubmit(values, setError);
        }
        setIsSubmittingToBackend(false);
    };

    const [showPassModal, setShowPassModal] = useState(false);
    const openPassModal = useCallback(() => setShowPassModal(true), []);
    const closePassModal = useCallback(() => setShowPassModal(false), []);
    const [isPassingWaitlistTicket, setIsPassingWaitlistTicket] = useState(false);

    const handlePassWaitlist = useCallback(async () => {
        if (isPassingWaitlistTicket) {
            return;
        }
        setIsPassingWaitlistTicket(true);
        try {
            await onPassWaitlist?.();
            closePassModal();
        } finally {
            setIsPassingWaitlistTicket(false);
        }
    }, [closePassModal, isPassingWaitlistTicket, onPassWaitlist]);

    return (
        <div data-testid="reservation-container">
            {React.isValidElement(title) ? title : <h2 className="mt-0">{title}</h2>}
            <FormProvider {...formMethods}>
                <form
                    onSubmit={formMethods.handleSubmit(async (values) => {
                        await onFormSubmit(values);
                    })}
                >
                    <div className="sr-only">
                        {localizedStrings.tickets.registration.registrant.requiredFields}
                    </div>

                    {children}

                    {isWaitlist ? (
                        <Row>
                            <Col xs={6} sm={8} md={9} />
                            <Col
                                xs={6}
                                sm={4}
                                md={3}
                                className="registration-form-waitlist-buttons-container"
                            >
                                <Button
                                    variant="outline-danger"
                                    disabled={
                                        isPassingWaitlistTicket ||
                                        isSubmitting ||
                                        isValidating ||
                                        isSubmittingToBackend
                                    }
                                    onClick={openPassModal}
                                >
                                    {isPassingWaitlistTicket ? (
                                        <Loading type="spin" height={25} width={25} />
                                    ) : (
                                        localizedStrings.tickets.waitlist.passOnTickets
                                    )}
                                </Button>
                                <Button
                                    type="submit"
                                    variant="primary"
                                    disabled={
                                        isPassingWaitlistTicket ||
                                        isSubmitting ||
                                        isValidating ||
                                        isSubmittingToBackend
                                    }
                                >
                                    {isSubmitting || isSubmittingToBackend ? (
                                        <Loading type="spin" height={25} width={25} />
                                    ) : (
                                        localizedStrings.tickets.waitlist.claimTickets
                                    )}
                                </Button>
                                <PassOnTicketsModal
                                    show={showPassModal}
                                    onHide={closePassModal}
                                    onConfirm={handlePassWaitlist}
                                    submitting={isPassingWaitlistTicket}
                                />
                            </Col>
                        </Row>
                    ) : (
                        <div className="row my-5 mx-0">
                            <Button
                                variant="primary"
                                className="w-100 d-flex justify-content-center align-items-center"
                                disabled={isSubmitting || isValidating || isSubmittingToBackend}
                                type="submit"
                                data-testid="ticket-signup-submit-button"
                            >
                                {isSubmitting || isValidating || isSubmittingToBackend ? (
                                    <Loading type="spin" height={20} width={20} />
                                ) : (
                                    buttonLabel || localizedStrings.general.confirm
                                )}
                            </Button>
                        </div>
                    )}
                </form>
            </FormProvider>
        </div>
    );
};

export default RegistrationForm;
