import React from 'react';
import Form from 'react-bootstrap/Form';
import { useFormContext } from 'react-hook-form';
import InformationalToolTip from '../../policies/InformationalToolTip';

type OptInCheckboxProps = {
    formControlReference: string;
    checkboxLabel: string;
    tooltip: string;
};

/**
 * Component for rendering all the form elements needed for Mailing address
 *
 * @param formControlReference unique id for this Form.Check
 * @param checkboxLabel The label for the Form.Check
 * @param tooltip Optional tooltip displayed after form
 */
const OptInCheckbox = ({ formControlReference, checkboxLabel, tooltip }: OptInCheckboxProps) => {
    const { register } = useFormContext();

    return (
        <div>
            <Form.Check
                id={formControlReference}
                inline
                data-testid={formControlReference}
                type="checkbox"
                label={checkboxLabel}
                {...register(formControlReference)}
            />
            {tooltip ? <InformationalToolTip tooltip={tooltip} /> : null}
        </div>
    );
};

export default OptInCheckbox;
