import React, { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { ErrorModalMessage } from '../../utils/types';
import LocalizationContext from '../../context/LocalizationContext';

type ErrorModalProps = {
    show: boolean;
    message: ErrorModalMessage;
    onClose: () => void;
};

/**
 * A modal to display simple error messages. Can be shown from anywhere in the app using the ErrorModalContext's
 * updateMessage function
 *
 * @param show {boolean} - Show the modal or not
 * @param message {ErrorModalMessage} - The message, including title and body, to show in the modal
 * @param onClose {() => void} - The function to call when closing the modal
 */
const ErrorModal = ({ show, message, onClose }: ErrorModalProps) => {
    const localizedStrings = useContext(LocalizationContext);

    return (
        <Modal show={show} onHide={onClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>{message.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{message.body}</Modal.Body>
            <Modal.Footer className="justify-content-start">
                <Button
                    variant="outline-dark"
                    onClick={onClose}
                    data-testid="error-modal-close-button"
                >
                    {localizedStrings.error.modal.close}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ErrorModal;
