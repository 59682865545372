import React, { useCallback, useMemo } from 'react';

import MyTicketsContext from './MyTicketsContext';
import { useMyTickets } from '../hooks/use-my-tickets';
import { useMyWaitlists } from '../hooks/use-my-waitlists';
import { useHydrateWaitlists } from '../hooks/use-hydrate-waitlists';

const MyTicketsContextProvider = ({ children }: React.PropsWithChildren<{}>) => {
    const tickets = useMyTickets();
    const waitlists = useMyWaitlists();
    const hydratedWaitlists = useHydrateWaitlists({ waitlists: waitlists.waitlists });

    const { refresh: refreshTickets } = tickets;
    const { refresh: waitlistRefresh } = waitlists;
    const { rehydrate: waitlistRehydrate } = hydratedWaitlists;

    const refreshWaitlists = useCallback(async () => {
        const newWaitlists = await waitlistRefresh();
        await waitlistRehydrate(newWaitlists);
        return newWaitlists;
    }, [waitlistRefresh, waitlistRehydrate]);

    const refreshAll = useCallback(async () => {
        await Promise.all([refreshTickets(), refreshWaitlists()]);
    }, [refreshTickets, refreshWaitlists]);

    const loading = tickets.loading || waitlists.loading || hydratedWaitlists.loading;

    const ctxValue = useMemo(
        () => ({
            tickets,
            waitlists: {
                ...waitlists,
                waitlists: hydratedWaitlists.hydratedWaitlists,
                meta: hydratedWaitlists.meta,
                refresh: refreshWaitlists,
            },
            loading,
            refresh: refreshAll,
        }),
        [
            hydratedWaitlists.hydratedWaitlists,
            hydratedWaitlists.meta,
            loading,
            refreshAll,
            refreshWaitlists,
            tickets,
            waitlists,
        ],
    );

    return <MyTicketsContext.Provider value={ctxValue}>{children}</MyTicketsContext.Provider>;
};

export default MyTicketsContextProvider;
