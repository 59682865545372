import React from 'react';
import { motion, Variants } from 'framer-motion';
import { useLocation } from 'react-router-dom';

type Props = {
    children: JSX.Element | Array<JSX.Element>;
};

/**
 * Generic component to handle FramerMotion's fade transitions
 * @param children - JSX to be displayed
 */
const AnimationContainer = ({ children }: Props) => {
    const location = useLocation();

    return (
        <motion.div
            key={location.pathname}
            role="status"
            initial="initial"
            animate="animate"
            exit="exit"
            variants={page}
        >
            {children}
        </motion.div>
    );
};

// Animation config
const page: Variants = {
    initial: {
        opacity: 0,
    },
    animate: {
        opacity: 1,
        transition: {
            delay: 0.3,
            duration: 0.3,
        },
    },
    exit: {
        opacity: 0,
        transition: {
            duration: 0.3,
        },
    },
};

export default AnimationContainer;
