import React, { useContext } from 'react';
import Countdown from 'react-countdown';
import moment from 'moment';
import Alert from 'react-bootstrap/Alert';
import LocalizationContext from '../../context/LocalizationContext';

type CountdownRenderer = React.ComponentProps<typeof Countdown>['renderer'];

export interface CountDownTimerProps {
    /** Template string from localizedStrings that expects a template variable, `timeRemaining` */
    message: string;
    /** Time when the temp reservation ends */
    expirationDate: string;
    /** Runs when countdown expires */
    onExpire?: () => void | Promise<void>;
    /** Override default renderer of countdown time */
    renderCountdown?: CountdownRenderer;
}

const defaultRenderer: CountdownRenderer = ({ formatted }) => {
    return `${formatted.minutes}:${formatted.seconds}`;
};

/**
 * Component for controlling the countdown timer on the registration page(s).
 */
const CountDownTimer = ({
    message,
    expirationDate,
    onExpire,
    renderCountdown,
}: CountDownTimerProps) => {
    const localizedStrings = useContext(LocalizationContext);
    const formattedExpirationDate = moment(expirationDate).toISOString();

    return (
        <>
            {/* Section only for screen readers */}
            <Alert className="visually-hidden" data-testid="sr-timer">
                {localizedStrings.formatString(message, {
                    timeRemaining: localizedStrings.account.time,
                })}
            </Alert>
            {/* This section is aria-hidden so that the screen reader is not announcing every second of the countdown */}
            <div id="live-timer" aria-hidden="true" data-testid="live-timer">
                <Alert variant="warning" className="mt-1">
                    {localizedStrings.formatString(message, {
                        timeRemaining: (
                            <strong>
                                <span id="timer">
                                    <Countdown
                                        date={formattedExpirationDate}
                                        onComplete={onExpire}
                                        renderer={renderCountdown ?? defaultRenderer}
                                    />
                                </span>{' '}
                                {localizedStrings.reservations.minutes}
                            </strong>
                        ),
                    })}
                </Alert>
            </div>
        </>
    );
};

export default CountDownTimer;
