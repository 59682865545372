import { ErrorMessage } from '@hookform/error-message';
import get from 'lodash.get';
import React, { useContext } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { useFormContext } from 'react-hook-form';
import { FormControlProps } from 'react-bootstrap/FormControl';
import clsx from 'clsx';
import LocalizationContext from '../../../context/LocalizationContext';
import InformationalToolTip from '../../policies/InformationalToolTip';

interface GenericFieldProps extends FormControlProps {
    /** the Form.Label */
    label: string;
    /** Nintendo Account nickname, used for aria-label to help identify who the field is for */
    nickname: string;
    /** unique id for this Form.Group and Form.Control */
    formControlReference: string;
    /** is this field required or not */
    isRequired: boolean;
    /** optional maxLength for this input field */
    maxLength?: number;
    /** optional method used to validate this Form.Control data */
    validator?: (value: string) => boolean;
    /** optional The HTML input type, which is only relevant if 'as' attribute is 'input' (the default). */
    controlType?: string;
    /** optional Set the default value for this Form.Control */
    defaultValue?: string;
    /** optional tooltip */
    tooltip?: string;
}

const renderError: Required<React.ComponentProps<typeof ErrorMessage>>['render'] = ({
    message,
}) => <div className="generic-field-form-error">{message}</div>;

/**
 * Generic Form.Control
 */
const GenericField = ({
    label,
    nickname,
    formControlReference,
    isRequired,
    maxLength,
    onChange,
    disabled = false,
    readOnly = false,
    validator,
    controlType,
    placeholder,
    defaultValue,
    tooltip,
}: GenericFieldProps) => {
    const localizedStrings = useContext(LocalizationContext);
    const { register, formState } = useFormContext();

    const fieldOptions = validator
        ? {
              required: isRequired
                  ? (localizedStrings.formatString(localizedStrings.error.formError.required, {
                        attribute: label,
                    }) as string)
                  : false,
              validate: (value: string) =>
                  validator(value) ||
                  (localizedStrings.formatString(localizedStrings.error.formError.default, {
                      attribute: label,
                  }) as string),
              onChange: onChange || undefined,
          }
        : {
              required: isRequired
                  ? (localizedStrings.formatString(localizedStrings.error.formError.required, {
                        attribute: label,
                    }) as string)
                  : false,
              onChange: onChange || undefined,
          };

    return (
        <Form.Group as={Col} className="generic-field-form-group">
            <Form.Label
                htmlFor={formControlReference}
                className="generic-field-form-label"
                aria-label={`${nickname} ${label}`}
            >
                {label} {isRequired ? <span className="field-error-color">*</span> : null}
                {tooltip ? <InformationalToolTip tooltip={tooltip} /> : null}
            </Form.Label>

            <Form.Control
                maxLength={maxLength || undefined}
                id={formControlReference}
                data-testid={formControlReference}
                className={clsx('generic-field-form-control', {
                    'generic-field-form-read-only': readOnly,
                    'border-danger': !!get(formState.errors, formControlReference) || false,
                })}
                type={controlType}
                defaultValue={defaultValue}
                placeholder={placeholder}
                disabled={disabled}
                readOnly={readOnly}
                {...register(formControlReference, fieldOptions)}
            />

            <ErrorMessage
                errors={formState.errors}
                name={formControlReference}
                render={renderError}
            />
        </Form.Group>
    );
};

export default GenericField;
