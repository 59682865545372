import keyBy from 'lodash.keyby';
import {
    AcknowledgementType,
    Config,
    ConfigType,
    File,
    FileAssociation,
    FileCategory,
} from '../types';

/**
 * Utility function to categorize and sort files returned for an Event or an Activity.
 * @param files - List of file association objects
 * @returns - Returns a map object with mappings from file categories to to sorted list of file objects.
 */
export const processAndCategorizeFiles = (files: Array<FileAssociation>) => {
    const filesMap: { [fileCategory: string]: Array<File> } = {};

    // The order of the categories is important. FileCategory enum is in the order we are interested in. So we
    // simply loop through all the FileCategory values and associate an empty array to the filesMap. This way
    // when the files.forEach() is called, filesMap will already have the FileCategories in the order we want.
    Object.keys(FileCategory).map((value) => {
        filesMap[value] = [];
    });

    files.forEach((fileAssociation) => {
        const { file } = fileAssociation;
        const { category } = file;
        if (!filesMap[category]) {
            // This will only happen if a category is not in the FileCategory enum
            filesMap[category] = [file];
        } else {
            filesMap[category].push(file);
        }
    });

    Object.keys(filesMap).map((fileCategory) => {
        const fileList = filesMap[fileCategory];
        fileList.sort((a, b) => {
            return a.order < b.order ? -1 : 1;
        });
    });

    return filesMap;
};

/**
 * Gets all the fileAssociations that require an acknowledgment
 * @param files: array of File objects to iterate over
 * @return Array<File>: array of File objects that require acknowledgment
 */
export const getFilesThatRequireAcknowledgment = (files: Array<File>): Array<File> => {
    let requiredFiles: Array<File> = [];
    if (files && files.length > 0) {
        requiredFiles = files.filter((file: File) => {
            const fileConfigs = keyBy(file.file_configs, (config: Config) => {
                return config.name;
            });
            return fileConfigs[ConfigType.ACKNOWLEDGEMENT]?.value === AcknowledgementType.CHECKBOX;
        });
    }

    return requiredFiles;
};

/**
 * Simple helper method to grab all File objects from the array of FileAssociation
 * @param fileAssociations: Array of FileAssociation objects
 * @return Array<File>: Array of File objects from each FileAssociation objects
 */
export const getFilesFromAssociation = (fileAssociations: Array<FileAssociation>): Array<File> => {
    return fileAssociations.map((fileAssociation) => fileAssociation.file);
};
