import axios, { AxiosRequestHeaders, AxiosResponse } from 'axios';
import { DrawingResponse, TicketResource } from '../utils/types';
import { getHeaders } from './ApiUtils';

/**
 * Gets all drawings for the specified resource
 * @param resourceType {TicketResource} - The resource type to retrieve drawings for. Ex) event_gates
 * @param resourceId {string} - The ID of the resource type being requested
 * @returns Returns an array of DrawingResponse objects for the specified resource
 */
export const fetchDrawings = async (
    resourceType: TicketResource,
    resourceId: string | number,
): Promise<Array<DrawingResponse>> => {
    const apiUri = `/public/${resourceType}/${resourceId}/drawings`;
    const response: AxiosResponse<Array<DrawingResponse>> = await axios.get(apiUri);
    return response.data;
};

/**
 * Gets all drawings for the specified resource for an authenticated user
 * @param resourceType {TicketResource} - The resource type to retrieve drawings for. Ex) event_gates
 * @param resourceId {string} - The ID of the resource type being requested
 * @param token {string} - The user's access token
 * @returns Returns an array of DrawingResponse objects for the specified resource
 */
export const fetchDrawingsAuthenticated = async (
    resourceType: TicketResource,
    resourceId: string | number,
    token: string,
): Promise<Array<DrawingResponse>> => {
    const headers = getHeaders(token) as AxiosRequestHeaders;

    const apiUri = `/attendee/${resourceType}/${resourceId}/drawings`;

    const response: AxiosResponse<Array<DrawingResponse>> = await axios.get(apiUri, { headers });

    return response.data;
};
