import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import compact from 'lodash.compact';
import keyBy from 'lodash.keyby';
import { fetchEventAuthenticated, fetchEventGateDetails } from '../api/eventApi';
import AccountContext from '../context/AccountContext';
import {
    EventActivityResponse,
    EventGateResponse,
    HydratedWaitlistEntry,
    WaitlistEntry,
} from '../utils/types';

export interface HydratedWaitlistsMetaData {
    /**
     * Lookup resources by ID
     */
    resourcesById: Record<string | number, EventGateResponse>;

    /**
     * Lookup events by ID
     */
    eventsById: Record<string | number, EventActivityResponse>;

    /**
     * Lookup waitlists by event ID
     */
    waitlistsByEvent: Record<string | number, Array<HydratedWaitlistEntry>>;

    /**
     * Lookup waitlists by waitlist ID
     */
    waitlistsById: Record<string | number, HydratedWaitlistEntry>;
}

const emptyMeta: HydratedWaitlistsMetaData = {
    resourcesById: {},
    eventsById: {},
    waitlistsByEvent: {},
    waitlistsById: {},
} as const;

export const useHydrateWaitlists = ({
    waitlists,
}: { waitlists?: Array<WaitlistEntry> | null } = {}) => {
    const {
        value: { token },
    } = useContext(AccountContext);
    const [loading, setLoading] = useState(false);
    const [hydratedWaitlists, setHydratedWaitlists] = useState<Array<HydratedWaitlistEntry>>([]);
    const [meta, setMeta] = useState<HydratedWaitlistsMetaData>(emptyMeta);

    const bustCache = useCallback(async () => {
        setHydratedWaitlists([]);
        setMeta(emptyMeta);
    }, []);

    /**
     * Re-fetch events and associated gates/tickets
     */
    const rehydrate = useCallback(
        async (waitlistsToRehydrate?: Array<WaitlistEntry> | null) => {
            setLoading(true);

            try {
                const eventIds = new Set<string | number>();
                const resourceIds = new Set<string | number>();
                const resourceEvents: Record<string | number, string | number> = {};

                (waitlistsToRehydrate ?? waitlists)?.forEach((wl) => {
                    eventIds.add(wl.event_id);
                    resourceIds.add(wl.resource_id);
                    resourceEvents[wl.resource_id] = wl.event_id;
                });

                const eventLoaders = [...eventIds].map((eventId) =>
                    fetchEventAuthenticated(eventId, token),
                );
                const resourceLoaders = [...resourceIds].map((resourceId) =>
                    fetchEventGateDetails(resourceEvents[resourceId], resourceId, token),
                );
                const eventsData = compact(await Promise.all(eventLoaders));
                const resourcesData = compact(await Promise.all(resourceLoaders));
                const eventsById = keyBy(eventsData, 'event_id');
                const resourcesById = keyBy(resourcesData, 'event_gate_id');
                const hydrated: Array<HydratedWaitlistEntry> = (
                    waitlistsToRehydrate ??
                    waitlists ??
                    []
                ).map((wl) => ({
                    ...wl,
                    event: eventsById[wl.event_id],
                    resource: resourcesById[wl.resource_id],
                }));
                const waitlistsByEvent = hydrated.reduce((acc, hydratedWl) => {
                    if (!acc[hydratedWl.event_id]) {
                        acc[hydratedWl.event_id] = [];
                    }
                    acc[hydratedWl.event_id].push(hydratedWl);
                    return acc;
                }, {} as HydratedWaitlistsMetaData['waitlistsByEvent']);

                setHydratedWaitlists(hydrated);
                setMeta({
                    resourcesById,
                    eventsById,
                    waitlistsByEvent,
                    waitlistsById: keyBy(hydrated, 'waitlist_entry_id'),
                });
            } finally {
                setLoading(false);
            }
        },
        [token, waitlists],
    );

    useEffect(() => {
        if (token && !loading && waitlists && hydratedWaitlists.length !== waitlists.length) {
            rehydrate();
        }
    }, [hydratedWaitlists.length, loading, rehydrate, token, waitlists]);

    return useMemo(
        () => ({
            loading,
            rehydrate,
            bustCache,
            hydratedWaitlists,
            meta,
        }),
        [bustCache, hydratedWaitlists, loading, meta, rehydrate],
    );
};

export type UseHydrateWaitlists = ReturnType<typeof useHydrateWaitlists>;
