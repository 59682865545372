import React, { useContext } from 'react';
import { LocalizedStrings } from 'react-localization';
import { Link } from 'react-router-dom';
import { ActivityTypeNames } from '../../../../../utils/types';
import LocalizationContext from '../../../../../context/LocalizationContext';
import './RtwAdditionalInfo.css';
import useActivityTypeOnly from '../../../../../hooks/internalHooks';
import { InternalLocalizationObject } from '../../../../../../resources/locales/en.internal';

type Props = {
    activityType: ActivityTypeNames;
};

/**
 * Additional information block for Nintendo Onsite Work activities reservations.
 * @param activityType - Activity type name for this Activity.
 */
const RtwAdditionalInfo = ({ activityType }: Props) => {
    const localizedStrings = useContext(
        LocalizationContext,
    ) as LocalizedStrings<InternalLocalizationObject>;

    return useActivityTypeOnly(
        activityType,
        ActivityTypeNames.WORK_ONSITE,
        <div className="additional-instructions-text mb-5" data-testid="rtw-additional-info">
            <h5 className="mb-3 text-center">
                <strong>
                    {
                        localizedStrings.internal.workOnsite.account.rsvps.details
                            .additionalInstructions.title
                    }
                </strong>
            </h5>
            <h6>
                <strong>
                    {
                        localizedStrings.internal.workOnsite.account.rsvps.details
                            .additionalInstructions.sections.buildingEntrance.title
                    }
                </strong>
            </h6>
            <p>
                {localizedStrings.formatString(
                    localizedStrings.internal.workOnsite.account.rsvps.details
                        .additionalInstructions.sections.buildingEntrance.body,
                    <Link
                        to={
                            localizedStrings.internal.workOnsite.account.rsvps.details
                                .additionalInstructions.sections.buildingEntrance.linkAddress
                        }
                    >
                        {
                            localizedStrings.internal.workOnsite.account.rsvps.details
                                .additionalInstructions.sections.buildingEntrance.clickHere
                        }
                    </Link>,
                )}
            </p>
            <h6>
                <strong>
                    {
                        localizedStrings.internal.workOnsite.account.rsvps.details
                            .additionalInstructions.sections.tempTest.title
                    }
                </strong>
            </h6>
            <p>
                {
                    localizedStrings.internal.workOnsite.account.rsvps.details
                        .additionalInstructions.sections.tempTest.body
                }
            </p>
            <h6>
                <strong>
                    {
                        localizedStrings.internal.workOnsite.account.rsvps.details
                            .additionalInstructions.sections.badgeRequired.title
                    }
                </strong>
            </h6>
            <p>
                {
                    localizedStrings.internal.workOnsite.account.rsvps.details
                        .additionalInstructions.sections.badgeRequired.body
                }
            </p>
        </div>,
    );
};

export default RtwAdditionalInfo;
