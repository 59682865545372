// Mexican Spanish (Language-Country Code: es-MX) Locale File.

import defaultsDeep from 'lodash.defaultsdeep';

import { PublicLocalizationObject } from './en_US';
import es_ES from './es_ES';

const es_MX: PublicLocalizationObject = defaultsDeep(
    {
        general: {
            soldOut: 'YA NO HAY LUGARES DISPONIBLES',
            currentLanguage: 'Español (México)',
        },
        tabs: {
            myTicketsAndPasses: 'Mis boletos / Pases',
        },
        events: {
            details: {
                tabs: {
                    tickets: 'Boletos',
                },
                accessCode: {
                    error: {
                        registrationClosed: 'El registro para este boleto ha concluido.',
                    },
                },
            },
            dataAttribute: {
                FULL_NAME: 'Nombre completo',
                FIRST_NAME: 'Nombre (s)',
                LAST_NAME: 'Apellidos',
                MAILING_ADDRESS: {
                    aptOrSuite: 'Departamento',
                    state: 'Colonia',
                },
            },
            ageRatingUrl:
                'https://www.dof.gob.mx/nota_detalle.php?codigo=5606047&fecha=27/11/2020#gsc.tab=0',
        },
        activities: {
            details: {
                title: 'Detalles de la actividad',
                ticketIsRequired:
                    'Se requiere tener un boleto para este evento antes de registrarse para esta actividad. {link}.',
                ticketIsPendingDrawing:
                    'Tu boleto para el evento todavía depende de una rifa. Regresa cuando se haya realizado la rifa.',
                checkForTicketAvailability: 'Comprueba aquí la disponibilidad de boletos',
                label: {
                    startDate: 'La fecha de inicio es el {startDate}',
                },
            },
            types: {
                GAME_DEMO: 'Demo de un juego',
                MEET_GREET: 'Meet & Greet',
                PRODUCT_LAUNCH: 'Lanzamiento de producto',
            },
        },
        reservations: {
            nickname: 'Usuario de la cuenta Nintendo',
            adultAcknowledgement:
                'Al seleccionar esta casilla, certifico que yo y el / los invitados mayores de 18 años que forman parte de mi grupo familiar hemos leído las políticas de privacidad y hemos aceptado los términos y condiciones que se enlistan más abajo.',
            childAcknowledgement:
                'Al seleccionar esta casilla, certifico que soy el padre / madre / tutor del o de los invitados menores de 18 años que forman parte de mi grupo familiar.Asimismo, acepto en su nombre las políticas de privacidad y los términos y condiciones que se enlistan más arriba.',
        },
        tickets: {
            ticket: 'Boleto',
            tickets: 'Boletos',
            ticketRequiredForCheckIn: 'Se require este boleto para asistir al evento',
            ticketsRequiredForCheckIn: 'Se requieren estos boletos para asistir al evento',
            getTickets: 'Obtener boletos',
            eventTicket: 'Boleto del evento',
            eventTickets: 'Boletos del evento',
            viewTickets: 'Ver boletos',
            cancelOtherTicket: 'Cancela tu otro boleto antes de registrarte para este',
            soldOut: 'No quedan lugares',
            adultRequired: 'Se requiere la compañía de un adulto',
            backToMyTicketsPasses: 'Volver a mis boletos/pases',
            notFound: 'No se han encontrado boletos',
            unknownTicketType: 'Tipo de boleto desconocido',
            receivedTickets: 'Has recibido tus boletos para {resourceName} {resourceDates}.',
            drawings: {
                wonDrawing: 'Has ganado la rifa de boletos de {resourceName} {resourceDates}.',
                lostDrawing: 'Lo sentimos, pero no has ganado la rifa para este boleto.',
                pendingDrawing:
                    'Te has registrado para participar en la rifa de boletos de {resourceName} {resourceDates}.',
            },
            registration: {
                ticketDetails: 'Información del boleto',
                registrant: {
                    requiredFields: 'Los espacios marcados con * son obligatorios.',
                },
                guest: {
                    guestsRemovedTicket:
                        'Se ha eliminado a {guests} como invitado/a porque ya se había registrado para un boleto de este evento.',
                    guestsRemovedDrawing:
                        'Se ha eliminado a {guests} como invitado/a porque ya se había registrado para participar en la rifa de boletos de este evento.',
                    guestFamilyHelpUrl:
                        'https://es-americas-support.nintendo.com/app/answers/detail/p/992/c/854/a_id/23078',
                    guestFamilyUrl:
                        'https://es-americas-support.nintendo.com/app/answers/detail/p/992/c/854/a_id/23078',
                },
            },
            cancelTicket: {
                cancelTicketModalTitle: 'Cancelar boleto',
                cancelLink: 'Cancelar este boleto',
                cancelTicketModalWarning: {
                    line1: 'No podrás asistir a este evento sin boleto',
                    line2: 'Se cancelarán tu boleto y tu Warp Pipe Pass(es)',
                    line3: 'Al cancelar tu boleto, se cancelarán automáticamente el boleto o boletos y el o los Warp Pipe Pass(es) de todos los miembros de tu grupo(si corresponde). Todos los miembros recibirán un correo electrónico en el que se les notificará sobre la cancelación.',
                    line4: 'Las cancelaciones son definitivas. Los boletos y los Warp Pass Passes no se considerarán válidos si se cancelan.',
                },
                areYouSure: '¿Seguro que quieres cancelar este boleto?',
                cancelButton: 'Cancelar boleto',
                cancelYourTicket: '¿No puedes asistir? Cancela tu boleto o boletos',
            },

            cancelDrawingEntry: {
                cancelTicketModalWarning: {
                    line1: 'Ya no participarás en la rifa de boletos.',
                },
            },
        },
        terms: {
            addedToDrawing:
                '{ invitor } te ha añadido para participar en la rifa de boletos del siguiente evento de Nintendo.Para poder participar, debes aceptar los términos del evento.',
            addedToEvent:
                '{ invitor } te ha enviado un boleto para el siguiente evento de Nintendo.Para poder asistir a este evento, debes aceptar primero los términos del evento.',
            completeInformation: 'Llena la siguiente información:',
        },
        error: {
            eventsError:
                'Se produjo un error al intentar mostrar los eventos. Inténtalo de nuevo más tarde.',
            eventDetailsError:
                'Se produjo un error al intentar mostrar la información del evento. Inténtalo de nuevo más tarde.',
            activityDetailsError:
                'Se produjo un error al intentar mostrar la información de la actividad. Inténtalo de nuevo más tarde.',
            timeSlotError:
                'Se produjo un error al intentar mostrar las horas disponibles. Inténtalo de nuevo más tarde.',
            tempReservationError:
                'Se produjo un error al intentar mantener esta reservación. Inténtalo de nuevo más tarde.',
            myRSVPsError:
                'Se produjo un error al intentar acceder a la confirmación de tu participación. Inténtalo de nuevo más tarde.',
            dataCollectionError:
                'Se produjo un error al intentar acceder a los datos. Inténtalo de nuevo más tarde.',
            reservationCouldNotBeConfirmed:
                'No se ha podido confirmar la reservación. Inténtalo de nuevo más tarde.',
            reservationCancelFailure:
                'Se produjo un error durante la cancelación. Inténtalo de nuevo más tarde.',
            reservationFormError: 'Debes llenar todos los espacios obligatorios.',
            ticketConfirmationFailed:
                'No se ha podido confirmar el boleto del evento. Revisa la información proporcionada e inténtalo de nuevo.',
            serviceError: {
                GP0009: 'No hay espacios disponibles en la hora solicitada.',
                GP0015: 'No se encontró la reservación que se quiere cancelar.',
                unknown: 'Se produjo un error.',
            },
            serviceErrorModal: {
                GP212: {
                    body: 'El registro para este boleto no está disponible todavía.',
                },
                GP213: {
                    body: 'El registro para este boleto ha concluido.',
                },
                GP0214: {
                    title: 'Boleto del evento no disponible',
                    body: 'Lo sentimos, pero ya no quedan lugares para el boleto del evento que has seleccionado.Consulta la disponibilidad de otras boletos.',
                },
                GP0218: {
                    body: 'Ya participas en la rifa de este boleto.',
                },
                GP220: {
                    body: 'El registro para este boleto no está disponible todavía.',
                },
                GP221: {
                    body: 'El registro para esta boleto ha concluido.',
                },
                unknown: {
                    body: 'Lo sentimos, pero se produjo un error desconocido.Inténtalo de nuevo.',
                },
            },
            formError: {
                required: 'Espacio obligatorio',
                default: 'Espacio no válido',
            },
            ticketCancelingError: 'Error al cancelar un boleto',
        },
        account: {
            checkIn: {
                poorInternetConnectivity:
                    'En caso de que la conexión a internet no sea estable en el evento, te recomendamos que imprimas el Warp Pipe Pass desde esta página o desde el correo electrónico que hemos enviado a la dirección de correo electrónico vinculada a tu cuenta Nintendo. (Si no puedes ver dicho correo electrónico, revisa tu carpeta de spam.)',
            },
            rsvps: {
                rsvps: 'Boletos y Warp Pipe Passes',

                cancel: {
                    ticket: 'Cancelar boleto',
                },
                details: {
                    pass: {
                        ios: require('../images/pass/ios/es/apple_wallet_badge_mx.svg'),
                    },
                },
            },
        },
        noneFound: {
            events: 'No hay eventos para los cuáles registrarse actualmente. Vuelve por aquí más tarde.',
        },
        footer: {
            disclaimer:
                'El Warp Pipe Pass y / o boleto solo puede usarse una vez, no es transferible y no está permitida su venta. El Warp Pipe Pass y / o boleto puede canjearse para recibir acceso prioritario en la fila para acceder a un juego o evento determinados a una hora designada; por el contrario, no se podrá canjear si se utiliza a una hora que no sea la designada. QR Code (código QR) es una marca registrada de DENSO WAVE INCORPORATED. Las marcas de Nintendo son propiedad de Nintendo. © Nintendo.',
            privacyPolicyUrl: 'https://www.nintendo.com/es-mx/privacy-policy/',
            termsOfUse: 'Términos de uso',
            termsOfUseUrl: 'https://www.nintendo.com/es-mx/terms-of-use/',
        },
        locales: {
            // To keep order the same, we need to explicitly call ...es_ES.locales
            ...es_ES.locales,
            nl_NL: 'Holandés',
        },
    },
    es_ES,
);

export default es_MX;
