// French (Language-Country Code: it-IT) Locale File.
import defaultsDeep from 'lodash.defaultsdeep';

import en_US, { PublicLocalizationObject } from './en_US';

const it_IT: PublicLocalizationObject = defaultsDeep(
    {
        general: {
            backToActivityTimes: 'Torna agli orari dell’attività',
            backToActivityDetails: 'Torna ai dettagli dell’attività',
            backToEvent: 'Torna all’evento',
            location: 'Posizione',
            rsvp: '',
            entry: '',
            soldOut: 'TUTTO ESAURITO',
            cancel: 'Annulla',
            close: 'Chiudi',
            confirm: 'Conferma',
            exit: '',
            signInCreateAccount: 'Accedi / Crea un account',
            signIn: 'Accedi',
            warpPipePass: '',
            learnMore: 'Maggiori informazioni',
            currentLanguage: 'Italiano',
            yes: '',
            no: '',
            note: 'NOTA',
            addressFormat: '{address} {postal_code}, {city}, {region}',
            areYouSure: '',
        },
        tabs: {
            events: 'Eventi',
            myRSVPs: '',
            myTicketsAndPasses: 'I miei biglietti/pass',
            checkIn: '',
            signIn: '',
        },
        events: {
            eventItemHeader: 'Evento:',
            upcoming: '',
            moreInfo: 'Maggiori informazioni',
            eventDetails: 'Dettagli dell’evento',
            viewEventDetails: 'Vedi i dettagli dell’evento',
            details: {
                tabs: {
                    tickets: 'Biglietti',
                    activities: 'Attività',
                    resources: '',
                },
                accessCode: {
                    aria: {
                        accessCodeInput: 'Codice d’accesso',
                        exitButton: 'Esci dal campo di immissione del codice d’accesso',
                    },
                    title: 'Inserisci il tuo codice d’accesso',
                    toggleButtonLabel: 'Utilizza un codice d’accesso',
                    redeemButtonLabel: 'Invia',
                    inputPlaceHolder: 'Codice d’accesso di 16 cifre',
                    enterDifferentCode: 'Inserisci un codice d’accesso differente',
                    success: {
                        woohoo: 'Woo-hoo!',
                        youCanRegister: 'Adesso puoi registrarti per:',
                    },
                    error: {
                        invalid: 'Codice non valido. Riprova.',
                        registrationClosed: 'Le registrazioni per questo biglietto sono chiuse.',
                    },
                },
                waitlist: {
                    available: '',
                    drawingOver: '',
                    drawingLost: '',
                    waitlistJoined: '',
                    waitlistLink: '',
                    waitlistOffer: '',
                    offerPassed: '',
                    offerExpired: '',
                    waitlistClosed: '',
                    button: {
                        join: '',
                        edit: '',
                    },
                },
            },
            dataAttribute: {
                FULL_NAME: 'Nominativo completo',
                FIRST_NAME: 'Nome',
                LAST_NAME: 'Cognome',
                POSTAL_CODE: 'CAP',
                PHONE: 'Numero di telefono',
                US_ADDRESS: 'Indirizzo',
                MAILING_ADDRESS: {
                    streetAddress: 'Via, corso, piazza, ecc.',
                    aptOrSuite: 'Appartamento/Interno',
                    city: 'Città',
                    state: 'Provincia',
                    country: 'Paese',
                    zipCode: 'CAP',
                },
                OPT_IN: 'Acconsenti a condividere il tuo indirizzo e-mail',
                ACCESS_CODE: 'Codice d’accesso',
            },
            ageRatingUrl: 'https://pegi.info/',
        },
        activities: {
            activities: 'Attività',
            viewActivities: 'Vedi attività',
            registrationNotBegun: 'Le registrazioni per questa attività non sono ancora aperte',
            registrationHasEnded: 'Le registrazioni per questa attività sono chiuse',
            registrationBegins: 'Data e orario di apertura delle registrazioni:',
            details: {
                title: 'Dettagli dell’attività',
                registrationBegins: 'Data e orario di apertura delle registrazioni:',
                registrationEnds: 'Data e orario di chiusura delle registrazioni:',
                header: {
                    noAvailableTimes: 'Nessun orario disponibile.',
                },
                continueToRSVP: 'Avanti',
                viewMy: 'Vedi il mio {0}',
                alreadyRSVPd: 'Hai già un Warp Pipe Pass per questa attività.',
                ticketIsRequired:
                    'Per iscriverti a questa attività hai bisogno di un biglietto per l’evento. {link}.',
                eventTicketRequired: "È richiesto un biglietto per l'evento",
                ticketIsPendingDrawing:
                    'L’estrazione dei biglietti per questo evento non è ancora avvenuta. Riprova dopo che l’estrazione avrà avuto luogo.',
                checkForTicketAvailability: 'Verifica la disponibilità di biglietti qui',
                label: {
                    startDate: 'La data di inizio è {startDate}',
                    activityType: 'Il tipo di attività è {activityType}',
                },
                iconLabel: {
                    activityTime: 'Icona per l’orario dell’attività',
                    activityType: 'Icona per il tipo dell’attività',
                    registration: 'Icona per il tipo di registrazione',
                    location: 'Icona per il luogo',
                },
            },
            timeSlots: {
                title: '',
                noTimesForDay: 'Nessun orario disponibile per questa data.',
            },
            unknownActivityType: '',
            types: {
                GAME_DEMO: 'Dimostrazione di gioco',
                GENERAL_CHECK_IN: 'Check-in',
                COMPETITIVE_PLAY: 'Sessione di gioco competitiva',
                EXCLUSIVE_OFFER: 'Offerta esclusiva',
                LEAD_GENERATION: 'Lead Generation',
                MEET_GREET: 'Sessione di presentazione',
                PRODUCT_LAUNCH: 'Lancio di un prodotto',
                SPECIAL_EVENT: 'Evento speciale',
                unknown: '',
            },
        },
        reservations: {
            timeRemainingForReservation:
                'Tempo rimanente: {timeRemaining}. Se lasci questa pagina, o se il tempo scade prima che tu abbia completato la registrazione, questa operazione verrà annullata.',
            timeRemainingForDrawing: 'Hai ancora {timeRemaining} per completare la tua iscrizione.',
            timeRemainingForWaitlist: '',
            waitlistThingsToKeepInMind: '',
            waitlistThingsToKeepInMindItem1: '',
            waitlistThingsToKeepInMindItem2: '',
            waitlistThingsToKeepInMindItem3: '',
            waitlistThingsToKeepInMindItem4: '',
            minutes: ' minutes',
            registrant: '',
            registrantInformation: 'I tuoi dati',
            nickname: 'Nickname dell’account Nintendo',
            confirmRSVP: 'Conferma',
            confirmEntry: 'Iscriviti all’estrazione',
            legalConsent: 'Liberatoria',
            adultAcknowledgement:
                'Cliccando su questo riquadro dichiaro che sia io sia eventuali altri partecipanti maggiorenni membri del mio gruppo famiglia abbiamo preso visione della Policy sulla privacy e accettiamo le condizioni generali visualizzabili selezionando il link qui sotto.',
            childAcknowledgement:
                'Cliccando su questo riquadro certifico di essere il genitore/tutore legale di eventuali ospiti minorenni del mio gruppo famiglia. Dichiaro di avere preso visione della Policy sulla privacy e accetto le condizioni generali visualizzabili selezionando il link qui sopra a nome di tali ospiti, elencati qui sotto.',
            adultGuestsAcknowledgement:
                "Selezionando questo riquadro confermo che i miei accompagnatori adulti mi hanno autorizzato ad aggiungerli come ospiti per questo evento e hanno dato il loro consenso alla ricezione dell'invito tramite e-mail. Ogni adulto deve accettare le condizioni di partecipazione allegate all'invito prima di prendere parte all'evento.",
            event_registration: {
                attendees: 'Partecipanti',
                note: 'NOTA:',
                attendeeCannotRegister:
                    'i partecipanti non possono registrarsi per più di un’attività nello stesso orario, o più volte per la stessa attività nel corso della stessa giornata.',
                currentUserIneligible:
                    'Hai già un Warp Pipe Pass per questa attività o per un’altra attività allo stesso orario.',
                removedGuests:
                    '{guests} è stato/a rimosso/a come ospite perché ha già un Warp Pipe Pass per questa attività o per un’altra attività allo stesso orario.',
                selectAllAttendees: 'Seleziona tutti i partecipanti per questa attività.',
            },
        },
        tickets: {
            ticket: 'biglietto',
            tickets: 'biglietti',
            wppRequiredForCheckIn: 'Per effettuare il check-in è richiesto questo {0}.',
            wppsRequiredForCheckIn: 'Per effettuare il check-in sono richiesti questi {0}.',
            ticketRequiredForCheckIn: 'Per partecipare all’evento è richiesto questo {0}.',
            ticketsRequiredForCheckIn: 'Per partecipare all’evento sono richiesti questi {0}.',
            getTickets: 'Biglietti',
            eventTicket: 'biglietto',
            eventTickets: 'biglietti',
            viewTickets: 'Visualizza biglietti',
            viewEntry: 'Verifica stato',
            registrationClosed: 'Le registrazioni sono chiuse',
            comingSoon: 'Prossimamente',
            cancelOtherTicket: 'Cancella il tuo altro biglietto prima di richiedere questo.',
            soldOut: 'Tutto esaurito',
            adultRequired: 'È richiesto un adulto',
            adultRequiredTooltip: 'La registrazione per questo evento richiede un adulto.',
            adultRequiredActivityTooltip:
                'La registrazione per questa attività richiede un adulto.',
            backToMyTicketsPasses: 'Torna a I miei biglietti/pass',
            registrationAlreadyClosed:
                'Le registrazioni si sono chiuse alle ore {1} del giorno {0}',
            registrationNotStarted: 'Le registrazioni si apriranno alle ore {1} del giorno {0}',
            notFound: 'Biglietti non trovati',
            unknownTicketType: 'Tipo di biglietto sconosciuto',
            consent: {
                consentRequiredTooltipTitle: 'È necessario effettuare l’accesso',
                consentRequiredTooltipBody:
                    'Questo utente deve accettare l’invito e le condizioni generali effettuando l’accesso al suo account Nintendo.',
                consentRequiredToolTipBodyHostUrl: 'accetta l’invito per l’evento',
                consentRequiredToolTipBodyHost:
                    'Per prendere parte a questa attività devi {0} e accettare le condizioni generali.',
            },
            receivedTickets:
                'Hai ricevuto i tuoi biglietti per il seguente evento o attività: {resourceName} {resourceDates}.',
            drawings: {
                wonDrawing:
                    'Hai vinto l’estrazione relativa ai biglietti per il seguente evento o attività: {resourceName} {resourceDates}.',
                lostDrawing: 'Sfortunatamente il tuo nome non è stato estratto.',
                pendingDrawing:
                    'Ti sei iscritto/a all’estrazione dei biglietti per il seguente evento o attività: {resourceName} {resourceDates}.',
            },
            registration: {
                ticketDetails: 'Dettagli del biglietto',
                registrant: {
                    onlyOneActivity:
                        '{note} I partecipanti non possono registrarsi per più di un’attività nello stesso orario, o per la stessa attività più volte nel corso della stessa giornata.',
                    dataNotice:
                        'Per scoprire di più su come utilizziamo i tuoi dati, consulta la nostra {link}',
                    requiredFields: 'Tutti i campi contrassegnati con * sono obbligatori',
                },
                guest: {
                    guest: 'Ospite',
                    guests: 'I tuoi ospiti (Fino a {maxGuests})',
                    birthday: 'Data di nascita',
                    addManageGuests: 'Aggiungi/gestisci ospiti',
                    manageNintendoAccountFamily:
                        'Gestisci le impostazioni del gruppo famiglia del tuo account Nintendo',
                    guestsRemovedTicket:
                        '{guests} è stato/a rimosso/a come ospite perché si è già registrato/a per un biglietto per questo evento.',
                    guestsRemovedDrawing:
                        '{guests} è stato/a rimosso/a come ospite perché si è già iscritto/a all’estrazione dei biglietti per questo evento.',
                    maxLimit: 'Il numero massimo di ospiti è limitato a {max}.',
                    guestFamilyHelp:
                        'Non hai ancora creato un gruppo famiglia? {guestFamilyHelpLink}',
                    guestFamilyHelpLink: 'Scopri come crearne e gestirne uno.',
                    guestFamilyHelpUrl:
                        'https://www.nintendo.com/it-it/Assistenza/Nintendo-Switch/Come-aggiungere-membri-a-un-gruppo-famiglia-1406409.html',
                    guestFamilyWarning:
                        'Puoi aggiungere ospiti solo dal {nintendoFamilyLink}. Se non vedi i tuoi ospiti nell’elenco, aggiungili al gruppo famiglia del tuo account Nintendo. ',
                    guestFamilyLink: 'Gruppo famiglia del tuo account Nintendo',
                    guestFamilyUrl:
                        'https://www.nintendo.com/it-it/Assistenza/Nintendo-Switch/Come-aggiungere-membri-a-un-gruppo-famiglia-1406409.html',
                    guestRemoveButtonLabel: 'Rimuovi {user} dal gruppo',
                    selectFamilyMember:
                        'Scegli fra i membri del gruppo famiglia del tuo account Nintendo disponibili',
                    counter: '{current} / {max} ospiti selezionati',
                    child: 'Bambino',
                    notSelectableIf: 'I membri del gruppo famiglia non sono selezionabili se',
                    reachedMaxGuests: '1- Hai raggiunto il numero massimo di ospiti',
                    childAndNotParent:
                        '2- Sono bambini e tu non sei il loro genitore/tutore legale',
                    alreadyRegistered: '3- Sono già registrati',
                },
            },
            cancelTicket: {
                cancelTicketModalTitle: 'Cancella biglietto',
                cancelLink: 'Cancella questo biglietto',
                cancelTicketModalWarning: {
                    line1: 'Senza un biglietto non potrai partecipare a questo evento.',
                    line2: 'Il tuo biglietto ed eventuali Warp Pipe Pass verranno cancellati.',
                    line3: 'Cancellando il tuo biglietto cancellerai automaticamente anche i biglietti e gli eventuali Warp Pipe Pass di tutti gli altri membri del tuo gruppo (ove applicabile). Tutti riceveranno una e-mail di conferma della cancellazione.',
                    line4: 'Tutte le cancellazioni sono definitive. I biglietti e gli eventuali Warp Pipe Pass perderanno la loro validità se cancellati.',
                },
                areYouSure: 'Vuoi davvero cancellare questo biglietto?',
                imSure: 'Sì',
                cancelButton: 'Cancella biglietto',
                cancelYourTicket: 'Non potrai partecipare? Cancella i tuoi biglietti',
            },
            cancelPass: {
                cancelTicketModalTitle: 'Cancella pass',
                cancelLink: 'Cancella questo pass',
                areYouSure: 'Vuoi davvero cancellare questo Warp Pipe Pass?',
                imSure: 'Sì',
                cancelButton: 'Cancella pass',
            },
            cancelDrawingEntry: {
                cancelTicketModalTitle: 'Annulla iscrizione',
                cancelLink: '',
                cancelTicketModalWarning: {
                    line1: 'Verrai rimosso/a dall’estrazione per questo biglietto.',
                    line2: 'Annullando la tua iscrizione annullerai automaticamente anche le iscrizioni di tutti gli altri membri del tuo gruppo (ove applicabile). Tutti riceveranno una e-mail di conferma della cancellazione.',
                    line3: '',
                    line4: '',
                },
                areYouSure: 'Vuoi davvero annullare questa iscrizione?',
                imSure: 'Sì',
                cancelButton: 'Annulla iscrizione',
            },
            waitlist: {
                waitlist: '',
                title: '',
                editTitle: '',
                subtitle: '',
                notice: {
                    message: '',
                    notice: '',
                    timeFrame: '',
                },
                individualTicketsHeader: '',
                familyTicketsHeader: '',
                guests: '',
                individual: '',
                family: '',
                adultGuests: '',
                childGuests: '',
                guestCount: '',
                select: '',
                selectAll: '',
                submit: '',
                summary: '',
                summaryRequired: '',
                summaryCheckbox: '',
                summaryFooter: '',
                summaryEmail: '',
                onTheWaitlist: '',
                leaveWaitlists: '',
                leaveWaitlist: '',
                makeChanges: '',
                cancelEntry: '',
                onWaitlistRequestingTickets: '',
                confirmRemoveSelfFromWaitlistsForEvent: '',
                confirmRemoveSelfFromWaitlist: '',
                stayOnWaitlists: '',
                stayOnWaitlist: '',
                noteOtherWaitlistsUnaffected: '',
                congratulationsTicketWaitlistOffered: '',
                congratulationsEventTicketWaitlistOffered: '',
                claimTickets: '',
                passOnTickets: '',
                areYouSureYouDontWantIt: '',
                passOnTicketsNotice: '',
                keepOffer: '',
                passOnOffer: '',
                removeWaitlistTicketsWarning: '',
                updateWaitlist: '',
            },
            appleWalletAddButtonAlt: 'Pulsante Aggiungi a Apple Wallet',
        },
        terms: {
            acceptTerms: 'Accetta le condizioni',
            acceptEventTerms: 'Accetta le condizioni generali dell’evento',
            addedToDrawing:
                'Sei stato iscritto/a a una estrazione che mette in palio un biglietto per il seguente evento Nintendo da {invitor}. Per partecipare all’estrazione devi accettare le condizioni generali dell’evento.',
            addedToEvent:
                'Hai ricevuto un biglietto per il seguente evento Nintendo da {invitor}. Per partecipare devi accettare le condizioni generali dell’evento.',
            completeInformation: 'Inserisci le seguenti informazioni:',
            terms: '',
            viewAndAcceptTerms: 'Leggi e accetta le condizioni generali',
            pendingTermsConsent: 'Condizioni generali non ancora accettate',
        },
        error: {
            error: 'Errore',
            warning: 'Nota',
            modal: {
                close: 'Chiudi',
            },
            notFound: {
                title: 'La pagina o l’evento che cercavi non sono disponibili.',
                viewAvailableEvents: 'Vedi gli eventi disponibili',
            },
            eventsError: 'Si è verificato un errore. Riprova più tardi.',
            eventDetailsError: 'Si è verificato un errore. Riprova più tardi.',
            activityDetailsError: 'Si è verificato un errore. Riprova più tardi.',
            timeSlotError: 'Si è verificato un errore. Riprova più tardi.',
            tempReservationError: 'Si è verificato un errore. Riprova più tardi.',
            myRSVPsError: 'Si è verificato un errore. Riprova più tardi.',
            mapError: 'La mappa non può essere visualizzata in questo momento.',
            dataCollectionError: 'Si è verificato un errore. Riprova più tardi.',
            reservationCouldNotBeConfirmed:
                'Non è stato possibile confermare la prenotazione. Riprova più tardi.',
            reservationCancelFailure: 'Si è verificato un errore. Riprova più tardi.',
            reservationFormError: 'è necessario riempire tutti i campi obbligatori.',
            eventServiceUnavailable: 'Il sistema non è al momento disponibile. Riprova più tardi.',
            rsvpTimeOut: 'La tua registrazione è scaduta. Prova a registrarti di nuovo.',
            waitlistRsvpTimeOut: '',
            ticketConfirmationFailed:
                'Non è stato possibile confermare il biglietto. Verifica le informazioni inserite e prova di nuovo.',
            waitlistEntriesCreationFailed: '',
            serviceError: {
                GP0009: 'Nessun posto disponibile all’orario selezionato.',
                GP0010: 'Ti sei già registrato/a. Non puoi registrarti per più di un’attività nello stesso orario, o per la stessa attività più volte nel corso della stessa giornata.',
                GP0011: '',
                GP0013: 'Le registrazioni per questa attività non sono ancora aperte.',
                GP0014: 'Le registrazioni per questa attività sono chiuse.',
                GP0015: 'Nessuna prenotazione trovata.',
                GP0016: 'Questo orario è nel passato.',
                GP0243: 'Un biglietto per questo evento è già stato richiesto per te.',
                GP0244: 'Sei già stato/a iscritto/a all’estrazione di un biglietto per questo evento.',
                GP0252: 'Hai già un Warp Pipe Pass per questa attività.',
                unknown: 'Si è verificato un errore',
            },
            serviceErrorModal: {
                GP212: {
                    title: 'Registrazioni non ancora aperte',
                    body: 'Le registrazioni per questo biglietto non sono ancora aperte.',
                },
                GP213: {
                    title: 'Le registrazioni sono chiuse',
                    body: 'Le registrazioni per questo biglietto sono chiuse.',
                },
                GP0214: {
                    title: 'Biglietto non disponibile',
                    body: 'I biglietti per l’evento che hai selezionato sono esauriti. Verifica la disponibilità per altri eventi.',
                },
                GP0218: {
                    title: 'Non disponibile',
                    body: 'Sei già iscritto/a all’estrazione per questo biglietto.',
                },
                GP220: {
                    title: 'Registrazioni non ancora aperte',
                    body: 'Le registrazioni per questo biglietto non sono ancora aperte.',
                },
                GP221: {
                    title: 'Le registrazioni sono chiuse',
                    body: 'Le registrazioni per questo biglietto sono chiuse.',
                },
                unknown: {
                    title: 'Errore sconosciuto',
                    body: 'Si è verificato un errore sconosciuto. Prova di nuovo.',
                },
            },
            formError: {
                required: '{attribute} è obbligatorio.',
                default: '{attribute} non è valido.',
            },
            drawingCancelingError: 'La disiscrizione non è andata a buon fine',
            ticketCancelingError: 'La cancellazione del biglietto non è andata a buon fine',
            timeSlotCancelingError: 'La cancellazione del pass non è andata a buon fine',
            minNumberOfGuestsError:
                'aggiungi {atLeastNum} membro/i del gruppo famiglia del tuo account Nintendo per continuare.',
            atLeast: 'almeno {expectedNumberOfGuests}',
            waitlist: {
                maxNumberOfGuestsError: '',
                noTicketsSelectedError: '',
                claimTempTicketsError: '',
            },
        },
        drawing: {
            drawing: 'Estrazione',
            firstComeFirstServed: 'In ordine di arrivo',
            enterForAChance: 'Iscriviti per partecipare all’estrazione',
            youHaveAlreadyEntered:
                'Ti sei già iscritto/a all’estrazione di un biglietto per questo evento.',
            drawingRules: 'Regolamento dell’estrazione',
            ruleX: 'Regolamento: {rule}',
            status: {
                pending: 'In attesa dell’estrazione ({partySize} {type})',
                notSelected: '',
                notWinner: 'Non estratto',
                winner: 'Estratto',
            },
        },
        account: {
            accountRequired: 'È richiesto un account Nintendo',
            pleaseLogin: {
                basic: '{0} al tuo account Nintendo.',
                toView: '{0} al tuo account Nintendo per vedere i tuoi {1}.',
                toAccept: '{0} al tuo account Nintendo per accettare le condizioni generali.',
            },
            requiredToRSVP:
                'Per registrarsi è richiesto un account Nintendo. Accedi o crea un account gratuitamente per proseguire.',
            rsvpWillBeHeld: '',
            time: '10 minuti',
            checkIn: {
                title: '',
                presentCode: '',
                poorInternetConnectivity:
                    'Ti consigliamo di stampare questo pass da questa pagina o dalla e-mail che abbiamo inviato all’indirizzo collegato al tuo account Nintendo, in modo da poterlo mostrare anche in caso di problemi con la connessione Internet presso l’evento. (Se non trovi la nostra e-mail, controlla la cartella della posta indesiderata).',
                checkInCode: '',
                appleWalletTM:
                    'Apple Wallet è un marchio di Apple Inc., registrato negli Stati Uniti e in altri paesi e zone.',
            },
            passes: {
                warpPipePass: 'Warp Pipe Pass',
                warpPipePasses: 'Warp Pipe Pass',
            },
            rsvps: {
                activeRSVPs: '',
                drawingEntries: '',
                rsvps: 'Biglietti e Warp Pipe Pass',
                viewPass: 'Vedi il pass',
                reservationUpdated: '{0} è stato aggiornato.',
                reservationRemoved: '{0} è stato/a rimosso/a',
                ticketAdded: 'Hai ricevuto il seguente biglietto: {ticketName}.',
                drawingTicketAdded: 'Ti sei iscritto/a all’estrazione per: {ticketName}.',
                warpPipePassAdded:
                    'Hai ricevuto il tuo pass per la seguente attività: {activityName}.',
                waitlistAdded: '',
                waitlistsUpdated: '',
                edit: {
                    title: 'Cambia orario',
                },
                cancel: {
                    title: '',
                    entry: 'Annulla iscrizione',
                    pass: '',
                    ticket: 'Cancella biglietto',
                    areYouSure: '',
                },
                details: {
                    pass: {
                        ios: require('../images/pass/ios/it/apple_wallet_badge_it.svg'),
                    },
                    noneFound: '',
                    requiredForCheckIn: 'Per effettuare il check-in è richiesto questo {0}.',
                    addToCalendar: 'Aggiungi al calendario',
                },
            },
        },
        noneFound: {
            events: 'Al momento non è disponibile alcun evento. Riprova più tardi.',
            activities: 'Al momento non è disponibile alcuna attività per questo evento.',
            timeSlots: 'Non sono stati trovati orari disponibili per questa attività.',
            reservations: '',
            warpPipePasses: 'Al momento non hai alcun Warp Pipe Pass attivo.',
            tickets: 'Al momento non hai alcun biglietto attivo.',
        },
        footer: {
            disclaimer:
                'I pass e/o i biglietti sono utilizzabili una sola volta e non possono essere ceduti o venduti a terzi. I pass e/o i biglietti potrebbero dare diritto all’accesso prioritario alla coda per giochi o eventi selezionati e potrebbero non essere utilizzabili al di fuori dell’orario indicato. QR Code is a registered trademark of DENSO WAVE INCORPORATED. Nintendo trademarks are properties of Nintendo. © Nintendo.',
            privacyPolicy: 'Policy sulla privacy',
            privacyPolicyUrl:
                'https://www.nintendo.com/it-it/Informazioni-legali/Policy-sulla-privacy-del-sito/Policy-sulla-privacy-del-sito-637785.html',
            termsOfUse: '',
            termsOfUseUrl: '',
        },
        dates: {
            day: 'DD',
            dayOfWeekShort: 'ddd',
            dayOfWeek: 'dddd',
            fullDate: 'ddd DD MMMM YYYY - HH:mm',
            dateRangeFirst: 'DD MMM',
            dateRangeSecond: 'DD MMM YYYY',
            hourMinute: 'HH:mm',
            hourMinutesTimezone: 'HH:mm zz',
            month: 'MMM',
            monthDateYear: 'DD MMM YYYY',
            monthDateYearLong: 'DD MMMM YYYY',
            range: ' - ',
            reservationDate: 'dddd, D MMM YYYY',
            reservationDateShort: 'ddd, D MMM YYYY',
            reservationTime: 'HH:mm z',
            registrationDate: 'ddd DD MMM YYYY - HH:mm z',
            timeSlot: {
                timeSlotHourMinuteTimezone: 'HH:mm z',
                timeSlotHourMinute: 'HH:mm',
                timeSlotTop: 'ddd',
                timeSlotMiddle: 'DD',
                timeSlotBottom: 'MMM',
            },
            eventTickets: {
                dateRange: 'ddd, DD MMM YYYY',
                time: 'HH',
                same_day_time: 'HH:mm',
                timeZone: 'zz',
                cancel: {
                    time: 'dddd MMM D YYYY - HH:mm',
                },
            },
            birthday: 'D MMMM YYYY',
        },
        countries: {
            title: 'Paese: {0}',
            values: {
                ALL: 'Tutti',
                US: 'Stati Uniti',
                CA: 'Canada',
                // JP: 'Giappone',
                AU: 'Australia',
                GB: 'Regno Unito e Irlanda',
                DE: 'Germania',
                FR: 'Francia',
                ES: 'Spagna',
                IT: 'Italia',
                NL: 'Paesi Bassi',
                // PT: 'Portogallo',
                // BE: 'Belgio',
                // CH: 'Svizzera',
                // AT: 'Austria',
                MX: 'Messico',
            },
        },
        locales: {
            en_US: 'Inglese (Stati Uniti)',
            en_GB: 'Inglese (Regno Unito)',
            en_AU: 'Inglese (Australia)',
            de_DE: 'Tedesco',
            fr_FR: 'Francese (Francia)',
            fr_CA: 'Francese (Canada)',
            it_IT: 'Italiano',
            es_ES: 'Spagnolo (Spagna)',
            es_MX: 'Spagnolo (Messico)',
            nl_NL: 'Olandese',
            // pt_PT: '',
        },
        files: {
            fileCategory: {
                GENERAL_INFO: 'Informazioni generali',
                LEGAL: 'Informazioni legali',
                TOURNAMENT_INFO: 'Informazioni sul torneo',
            },
        },
    },
    en_US,
);

export default it_IT;
