import React, { useContext } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import { useFormContext } from 'react-hook-form';

import LocalizationContext from '../../../context/LocalizationContext';
import { isInternal } from '../../../utils/InternalUtils';
import { AttendeeType } from '../../../utils/types';

import './FieldStyles.css';

/** Property values used to construct a static field displaying a name. */
type StaticNameFieldProps = {
    /** The identification of the user logged in. */
    userId: string;
    /** The name to display */
    name: string;
    /** The attendee type. Used to determine if full name or nickname is shown. */
    attendeeType: AttendeeType;
};

/**
 * Component for rendering a name (usually a user) in a readonly field.
 */
const ReadOnlyNameField = (props: StaticNameFieldProps) => {
    const localizedStrings = useContext(LocalizationContext);
    const { register } = useFormContext();
    const { userId, name, attendeeType } = props;

    return (
        <Form.Group as={Row}>
            <Col md={3}>
                <Form.Label className="mt-2" htmlFor={`${userId}-name`}>
                    {!isInternal(localizedStrings)
                        ? localizedStrings.reservations.nickname
                        : localizedStrings.events.dataAttribute.FULL_NAME}
                </Form.Label>
            </Col>

            <Col className="name-field-user-name">
                <Form.Control
                    className="name-field-spaced-text"
                    id={`${userId}-name`}
                    plaintext
                    readOnly
                    disabled
                    {...register(`participant.${userId}`, {
                        value:
                            attendeeType === AttendeeType.ADULT
                                ? name
                                : `${name} (${localizedStrings.tickets.registration.guest.child})`,
                    })}
                />
            </Col>
        </Form.Group>
    );
};

export default ReadOnlyNameField;
