// Australian English (Language-Country Code: en-AU) Locale File.

import defaultsDeep from 'lodash.defaultsdeep';

import en_US, { PublicLocalizationObject } from './en_US';

const au: PublicLocalizationObject = defaultsDeep(
    {
        general: {
            currentLanguage: 'English (Australia)',
        },
        dates: {
            day: 'DD',
            dayOfWeekShort: 'ddd',
            dayOfWeek: 'dddd',
            fullDate: 'ddd DD MMMM, YYYY - h:mma',
            dateRangeFirst: 'DD MMM',
            dateRangeSecond: 'DD MMM, YYYY',
            hourMinute: 'h:mma',
            hourMinutesTimezone: 'h:mma zz',
            month: 'MMM',
            monthDateYear: 'DD MMM, YYYY',
            monthDateYearLong: 'DD MMMM, YYYY',
            range: ' - ',
            reservationDate: 'dddd, D MMM, YYYY',
            reservationDateShort: 'ddd, D MMM, YYYY',
            reservationTime: 'h:mm A z',
            registrationDate: 'ddd DD MMM, YYYY hh:mma z',
            timeSlot: {
                timeSlotHourMinuteTimezone: 'hh:mm A z',
                timeSlotHourMinute: 'hh:mm A',
                timeSlotTop: 'ddd',
                timeSlotMiddle: 'DD',
                timeSlotBottom: 'MMM',
            },
            eventTickets: {
                dateRange: 'ddd, DD MMM, YYYY',
                time: 'ha',
                same_day_time: 'hh:mm a',
                timeZone: 'zz',
                cancel: {
                    time: 'dddd D MMM, YYYY - hh:mm a',
                },
            },
            birthday: 'D MMM, YYYY',
        },
    },
    en_US,
);

export default au;
