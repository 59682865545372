import axios, { AxiosRequestHeaders, AxiosResponse } from 'axios';
import AppConfig from '../components/config/AppConfig';
import { NintendoAccountFamilyResponse } from '../utils/types';
import { getHeaders } from './ApiUtils';

/**
 * Method for obtaining an AWS service access token
 * @param code - Auth code provided by Nintendo Account
 * @returns {string | null} - Returns service access token when successful. Returns null if not.
 */
export const getAccessToken = async (code: string) => {
    const apiUrl = `/attendee/nintendo-account/authorize?code=${code}&redirect_uri=${AppConfig.redirectUri}`;

    try {
        const response: AxiosResponse = await axios.post(apiUrl);
        return response.data['nintendo-gp-token'];
    } catch (e) {
        return null;
    }
};

/**
 * API function for obtaining Nintendo Account Family information through GPS. If provided a resource type and ID, then
 * an eligibility check is performed for the requested resource.
 * @param token - Access token
 * @param eventGateId - An event gate ID to check eligibility for
 * @param drawingId - A drawing ID to check eligibility for
 * @param timeSlotId - A time slot ID to check eligibility for
 */
export const getFamily = async (
    token: string,
    eventGateId?: number,
    drawingId?: string,
    timeSlotId?: number,
): Promise<NintendoAccountFamilyResponse | null> => {
    const apiUri = `/attendee/me/nintendo-account/family`;

    try {
        const response: AxiosResponse = await axios.get<NintendoAccountFamilyResponse>(apiUri, {
            headers: getHeaders(token) as AxiosRequestHeaders,
            params: { drawingId, eventGateId, timeSlotId },
        });
        return response.data;
    } catch (e) {
        return null;
    }
};
