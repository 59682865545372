import en_US, { PublicLocalizationObject } from './en_US';
import en_GB from './en_GB';
import en_AU from './en_AU';
import de_DE from './de_DE';
import es_ES from './es_ES';
import es_MX from './es_MX';
import fr_CA from './fr_CA';
import fr_FR from './fr_FR';
import it_IT from './it_IT';
import nl_NL from './nl_NL';

export const AVAILABLE_LOCALES: Record<string, PublicLocalizationObject> = {
    'en-US': en_US,
    'en-GB': en_GB,
    'en-AU': en_AU,
    'de-DE': de_DE,
    'es-ES': es_ES,
    'es-MX': es_MX,
    'fr-CA': fr_CA,
    'fr-FR': fr_FR,
    'it-IT': it_IT,
    'nl-NL': nl_NL,
};

export default AVAILABLE_LOCALES;
