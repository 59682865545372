import React from 'react';
import LocalizedStrings, { LocalizedStrings as LocalizedStringsType } from 'react-localization';

import AVAILABLE_LOCALES from '../../resources/locales';
import { PublicLocalizationObject } from '../../resources/locales/en_US';
import { InternalLocalizationObject } from '../../resources/locales/en.internal';

export type InternalLocalizedStrings = LocalizedStringsType<
    PublicLocalizationObject & InternalLocalizationObject
>;

export const localizedStrings = new LocalizedStrings<
    PublicLocalizationObject | (PublicLocalizationObject & InternalLocalizationObject)
>(AVAILABLE_LOCALES);

const LocalizationContext = React.createContext(localizedStrings);

export type TickTockLocalizedStrings = typeof localizedStrings;
export default LocalizationContext;
