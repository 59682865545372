import moment from 'moment-timezone';

/**
 * Returns true if both dates are on the same day in the specified timezone
 * @param date - Date passed as string value
 * @param date2 - Date passed as string value
 * @param timeZone - Timezone used for parsing date values
 */
export const checkIfSameDay = (date: string, date2: string, timeZone: string) => {
    return moment.tz(date, timeZone).isSame(moment.tz(date2, timeZone), 'day');
};
