import React from 'react';
import './Ribbon.css';

type Props = {
    title: string | JSX.Element;
};

export const FrillLeft = () => (
    <div className="frill-container frill-container-left">
        <div className="point-container">
            <div className="frill-point frill-point-left-top" />
            <div className="frill-point frill-point-left-bottom" />
        </div>
        <div className="frill-body frill-body-left" />
    </div>
);

export const FrillRight = () => (
    <div className="frill-container frill-container-right">
        <div className="frill-body frill-body-right" />
        <div className="point-container">
            <div className="frill-point frill-point-right-top" />
            <div className="frill-point frill-point-right-bottom" />
        </div>
    </div>
);
const Ribbon = ({ title }: Props) => (
    <div className="ribbon-container">
        <FrillLeft />
        <h5 className="ribbon-body">{title}</h5>
        <FrillRight />
    </div>
);

export default Ribbon;
