import React, { useContext } from 'react';
import Alert from 'react-bootstrap/Alert';
import { useFormContext } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LocalizationContext from '../../../context/LocalizationContext';
import { Party, PartyMember, Reservation, Ticket } from '../../../utils/types';
import PartyMemberName from '../../common/PartyMemberName';
import AccountContext from '../../../context/AccountContext';
import { isReservation } from '../../../utils/typeGuards';
import { sortAndFilterParty } from '../../account/myTicketsAndPasses/MyTicketUtils';
import { getMemberName } from '../../../utils/AccountUtils';
import BlockHeader from './BlockHeader';
import { BlockType, generateFormControlReferencePrefix } from '../../../utils/BlockUtils';

type Props = {
    party: Party;
    tempReservations: Array<Reservation | Ticket>;
    familyEligibilityMap: { [nintendoAccountId: string]: boolean };
    ineligibleGuests: Array<string>;
};

/**
 * Generates a number of checkboxes equal to the number of PartyMembers passed in. This allows the host to
 * pick which PartyMember(s) they wish to confirm for this ticket.
 *
 * If a PartyMember does not have a temporary reservation, the checkbox is disabled for that user because they are
 * not eligible to get this ticket (e.g. time conflict)
 *
 * @param party - the event gate party (if there is one)
 * @param tempReservations - list of temp reservations. Note ineligible Party members will not
 *  get a tempReservation.
 * @param familyEligibilityMap - Map denoting users with conflicting tickets for guests
 * confirm tickets without providing the host. NOTE: this is only used for drawings, not reservations.
 */
const AttendeesBlock = ({
    party,
    tempReservations,
    familyEligibilityMap,
    ineligibleGuests,
}: Props) => {
    const localizedStrings = useContext(LocalizationContext);
    const { value: accountInfo } = useContext(AccountContext);
    const { formState, watch, register, trigger } = useFormContext();

    // Used for minimum guest check
    const minNumGuestsControlReferenceId = `${BlockType.ATTENDEES_BLOCK}`;
    const blockHeaderFormControlReferencePrefix: string = generateFormControlReferencePrefix(
        party.host_participant_id.toString(),
        BlockType.ATTENDEES_BLOCK,
    );

    const members: Array<PartyMember> = party
        ? sortAndFilterParty(party, Number(accountInfo.participantId))
        : [];
    let hasIneligibleHostAlert = false;
    const isDrawing = !isReservation(tempReservations[0]);

    const participantIdsWithTempsSet = new Set(
        tempReservations.map((tempReservation) => tempReservation.participant_id),
    );

    // Get all members w/o temp reservations. These members checkboxes will be disabled and a warning will
    // be displayed. NOTE: we also filter out the logged-in user since we show a different error message
    // for them.
    const membersWithoutTemps: Array<PartyMember> = members
        .filter((member) => !participantIdsWithTempsSet.has(member.participant_id))
        .filter(
            (member: PartyMember) =>
                Number(member.participant_id) !== Number(accountInfo.participantId),
        );

    // Check if the current logged-in user has a temp reservation. If so, display this warning.
    if (!isDrawing && !participantIdsWithTempsSet.has(Number(accountInfo.participantId))) {
        hasIneligibleHostAlert = true;
    } else if (isDrawing && !familyEligibilityMap[accountInfo.userId]) {
        hasIneligibleHostAlert = true;
    }

    let ineligibleMembersName: Array<string | null | undefined> | null = null;
    if (isDrawing) {
        // Check the family eligibility list instead of the tempReservations
        ineligibleMembersName = members
            // Don't include the logged-in user in the output
            .filter((member) => Number(member.participant_id) !== Number(accountInfo.participantId))
            // Map everyone else to their nickname or username
            .map((member) => {
                if (!familyEligibilityMap[member.participant_identification.value]) {
                    return getMemberName(member);
                }
                return null;
            })
            // Remove any nulls
            .filter((memberName) => memberName);
    } else if (membersWithoutTemps && membersWithoutTemps.length > 0) {
        // Check if there are any other members in the party that already have a temp reservation.
        // If so, display this warning.
        ineligibleMembersName = membersWithoutTemps
            .map((member: PartyMember) => {
                return getMemberName(member);
            })
            // Remove any nulls
            .filter((memberName) => memberName);
    }

    // For each ineligible IDs we got (This variable only covers IDs returned from the API as ineligible) and collect their
    // names to be rendered.
    ineligibleGuests.forEach((guestParticipantId) => {
        const member = members.find(
            (partyMember) => partyMember.participant_identification.value === guestParticipantId,
        );
        if (member) {
            ineligibleMembersName?.push(getMemberName(member));
        }
    });

    // Watch for form changes to update count of selected guests
    const selectedGuests = watch('attendees', []);
    const countSelectedGuests = Object.values(selectedGuests).filter((sel) => {
        return sel as boolean;
    }).length;

    return (
        <>
            <Row>
                <Col>
                    <BlockHeader
                        icon={<i className="me-2 far fa fa-user" />}
                        title={localizedStrings.reservations.event_registration.attendees}
                        formState={formState}
                        formControlReferencePrefixes={[
                            blockHeaderFormControlReferencePrefix,
                            minNumGuestsControlReferenceId,
                        ]}
                        extraErrorMessages={
                            countSelectedGuests === 0
                                ? [
                                      localizedStrings.formatString(
                                          localizedStrings.error.minNumberOfGuestsError,
                                          {
                                              atLeastNum: (
                                                  <strong>
                                                      {localizedStrings.formatString(
                                                          localizedStrings.error.atLeast,
                                                          {
                                                              expectedNumberOfGuests: 1,
                                                          },
                                                      )}
                                                  </strong>
                                              ),
                                          },
                                      ) as string,
                                  ]
                                : []
                        }
                        hideDefaultMessage
                    />
                </Col>
            </Row>
            <div className="mt-2">
                <Alert variant="info">
                    <b>{localizedStrings.reservations.event_registration.note}</b>
                    <span>{` ${localizedStrings.reservations.event_registration.attendeeCannotRegister}`}</span>
                </Alert>

                {hasIneligibleHostAlert ? (
                    <Alert variant="warning">
                        <span>
                            {localizedStrings.reservations.event_registration.currentUserIneligible}
                        </span>
                    </Alert>
                ) : null}

                {ineligibleMembersName && ineligibleMembersName.length > 0 ? (
                    <Alert variant="warning">
                        {localizedStrings.formatString(
                            localizedStrings.reservations.event_registration.removedGuests,
                            { guests: <b>{ineligibleMembersName.join(', ')}</b> },
                        )}
                    </Alert>
                ) : null}

                <p>{localizedStrings.reservations.event_registration.selectAllAttendees}</p>
                {members.map((member) => {
                    // Disable checkbox if the member has a conflicting reservation
                    let disabled = false;

                    if (isDrawing) {
                        disabled = !familyEligibilityMap[member.participant_identification.value];
                    } else {
                        disabled = !tempReservations.find(
                            (ticket) =>
                                Number(ticket.participant_id) === Number(member.participant_id),
                        );
                    }
                    if (ineligibleGuests.includes(member.participant_identification.value)) {
                        disabled = true;
                    }
                    return (
                        <Form.Check
                            className="mb-2"
                            key={member.participant_id}
                            disabled={disabled}
                            id={`checkbox-${member.nickname}`}
                            data-testid={`checkbox-${member.participant_id}`}
                            type="checkbox"
                            {...register(`attendees.${member.participant_identification.value}`, {
                                onChange: () => trigger(),
                            })}
                            label={
                                <span>
                                    <PartyMemberName partyMember={member} hideChildLabel />
                                </span>
                            }
                        />
                    );
                })}
            </div>
            {/* Hidden form controller to check if at least one attendee has been selected */}
            <Form.Control
                id={minNumGuestsControlReferenceId}
                type="hidden"
                defaultValue={countSelectedGuests}
                {...register(minNumGuestsControlReferenceId, {
                    required: true,
                    validate: () => countSelectedGuests > 0,
                })}
            />
        </>
    );
};

export default AttendeesBlock;
