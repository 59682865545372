// TODO Remove when more exports exist in this file
/* eslint import/prefer-default-export: off */
import { SupportedLocale } from './types';

/**
 * Takes a locale string (such as ja, ja-JP, en, en-US, etc) and returns the locale to use.
 * Returns en-US if no supported locales were found.
 * @param locale - The locale to find a SupportedLocale for (ex: ja-JP)
 */

export const getSupportedLocale = (locale: string | null): SupportedLocale => {
    if (!locale) {
        return SupportedLocale.EN_US;
    }

    let matchedLanguage: SupportedLocale | undefined;

    Object.values(SupportedLocale).forEach((value) => {
        // If we ever match the locale exactly, that is the one to use
        if (value.toUpperCase() === locale.toUpperCase()) {
            matchedLanguage = value;
        }

        // Otherwise we should attempt to match the first defined supported locale that matches on the language
        if (!matchedLanguage) {
            const localeLanguage = locale.split('-')[0];
            const supportedLocaleLanguage = value.split('-')[0];
            if (localeLanguage === supportedLocaleLanguage) {
                matchedLanguage = value;
            }
        }
    });

    return matchedLanguage || SupportedLocale.EN_US;
};

/**
 * Given an optional quantity choose between singular and plural text
 * @param quantity {number | null} - The quantity being considered. If empty, the singular form is used.
 * @param singularText {string} - The singular text. Ex: Ticket
 * @param pluralText = The plural text. Ex: Tickets
 */
export const getSingularOrPlural = (
    quantity: number | null,
    singularText: string,
    pluralText: string,
) => {
    return quantity && quantity > 1 ? pluralText : singularText;
};
