import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';

import LanguageMenu from './LanguageMenu';
import LocalizationContext from '../../context/LocalizationContext';
import TicketAltIcon from '../common/icons/TicketAltIcon';

import './NavBar.css';

const NavBar = (props: { userElement: JSX.Element }) => {
    const localizedStrings = useContext(LocalizationContext);

    return (
        <Navbar className="top-nav" expand="lg" variant="dark">
            <Container fluid>
                <Navbar.Toggle className="hide-print" data-testid="navbar-toggler" />
                <Navbar.Brand as={NavLink} className="navbar-brand-nintendo" to="/events">
                    <img
                        src={require('../../../resources/images/nintendo_logo_120x30_red.jpg')}
                        alt="Nintendo"
                    />
                </Navbar.Brand>

                <div className="nav-back nav-login" />

                <Navbar.Collapse id="navbarSupportedContent">
                    <Nav className="me-auto">
                        <Nav.Item>
                            <NavLink
                                data-testid="events-nav-link"
                                className="nav-link"
                                to="/events"
                            >
                                <i className="nav-link-icon fas fa-calendar" />
                                {localizedStrings.tabs.events}
                            </NavLink>
                        </Nav.Item>
                        <Nav.Item>
                            <NavLink
                                data-testid="my-tickets-passes-nav-link"
                                className="nav-link"
                                to="/my-tickets-passes"
                            >
                                <TicketAltIcon className="nav-link-icon" />
                                {localizedStrings.tabs.myTicketsAndPasses}
                            </NavLink>
                        </Nav.Item>
                    </Nav>
                    <LanguageMenu />
                    <Nav className="justify-content-end">
                        <Nav.Item id="mii-login">{props.userElement}</Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default NavBar;
