import React from 'react';
import clsx from 'clsx';
import './DotDivider.css';

interface DottedLineSeparatorProps extends React.ComponentProps<'div'> {}

/**
 * Horizontal dotted line divider
 */
const DottedLineSeparator = ({ className, ...rest }: DottedLineSeparatorProps) => (
    <div className={clsx('divider', className)} {...rest} />
);

export default DottedLineSeparator;
