import React, { ReactNode, useContext } from 'react';
import { ErrorBoundary as BaseErrorBoundary, FallbackProps } from 'react-error-boundary';
import Container from 'react-bootstrap/Container';
import Alert from 'react-bootstrap/Alert';
import LocalizationContext from '../../context/LocalizationContext';
import NotFound from '../errors/NotFound';

type Props = {
    children?: ReactNode;
};

/**
 * Static method to render the content of the ErrorBoundary.
 * @param props
 */
const fallbackRender = (props: FallbackProps) => {
    return <ErrorBoundaryContent {...props} />;
};

/**
 * Component to handle the rendering of the ErrorBoundary content. If the raised error message is "Not found", it will render
 * the NotFound component. Otherwise, it will be a generic Alert component from Bootstrap.
 *
 * @param error
 */
const ErrorBoundaryContent = ({ error }: FallbackProps) => {
    const localizedStrings = useContext(LocalizationContext);

    if (error.message === localizedStrings.error.notFound.title) {
        return <NotFound />;
    }

    return (
        <Container className="p-3">
            <Alert variant="danger">{error.message}</Alert>
        </Container>
    );
};

/**
 * ErrorBoundary component to catch all raised error in a user-friendly way.
 */
const ErrorBoundary = ({ children }: Props) => (
    <BaseErrorBoundary fallbackRender={fallbackRender}>{children}</BaseErrorBoundary>
);

export default ErrorBoundary;
