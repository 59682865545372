import React from 'react';
import { File } from '../../utils/types';
import InformationalToolTip from '../policies/InformationalToolTip';

type Props = {
    file: File;
    enableInstruction?: boolean;
};

/**
 * A presentational component for displaying the filename and the corresponding
 * FontAwesome icon. Optional tooltip is available via the enableInstruction flag.
 *
 * @param file - File object
 * @param enableInstruction - boolean whether to show tooltip or not. This is filled in with the value from
 *  file.instruction. NOTE: even if this is set to true, if the file.instruction property is empty or null, the
 *  tooltip will not show.
 */
const FileTitle = ({ file, enableInstruction }: Props) => {
    const url = file.asset.path;
    const extension = url.substr(url.lastIndexOf('.') + 1, url.length - 1).toLowerCase();

    let icon;
    switch (extension) {
        // PDF
        case 'pdf':
            icon = 'far fa-file-pdf';
            break;
        // Word
        case 'doc':
        case 'docx':
            icon = 'far fa-file-word';
            break;
        // Excel
        case 'xls':
        case 'xlsx':
            icon = 'far fa-file-excel';
            break;
        // Images
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'gif':
            icon = 'far fa-file-image';
            break;
        default:
            icon = 'far fa-file';
    }

    const toolTip =
        enableInstruction && file.instructions ? (
            <>
                <InformationalToolTip tooltip={file.instructions} />
            </>
        ) : null;

    return (
        <>
            <a href={url} target="_blank" rel="noopener noreferrer">
                <i className={`${icon} me-2`} />
                <span>{file.display_name}</span>
            </a>
            {toolTip}
        </>
    );
};

export default FileTitle;
