import React, { useContext } from 'react';
import Alert from 'react-bootstrap/Alert';
import { FieldValues, FormState } from 'react-hook-form';
import LocalizationContext from '../../../context/LocalizationContext';
import InformationalToolTip from '../../policies/InformationalToolTip';
import { chooseFormResultComponentAfterSubmit, hasFormErrors } from '../../../utils/BlockUtils';
import './BlockStyles.css';

type BlockHeaderProps = {
    icon: JSX.Element;
    formState: FormState<FieldValues>;
    formControlReferencePrefixes: Array<string>;
    extraErrorMessages?: Array<string>;
    // title type may look odd, but needs to support what is returned by localizedStrings.formatString()
    title?: string | (string | number)[];
    tooltip?: JSX.Element;
    hideDefaultMessage?: boolean;
};

/**
 * A component for rendering a Blocks header. This component also contains logic for rendering errors that
 * are found in the block as needed.
 *
 * @param icon JSX.Element icon
 * @param title The title of this block
 * @param formState react-hook-form FormState object
 * @param formControlReferencePrefixes form control ids, used to find errors in this block
 * @param extraErrorMessages optional array of string error messages to add to the Alert
 * @param tooltip JSX.Element optional tooltip
 * @param hideDefaultMessage optional control for showing default error message
 */
const BlockHeader = ({
    icon,
    title,
    formState,
    formControlReferencePrefixes,
    extraErrorMessages,
    tooltip,
    hideDefaultMessage,
}: BlockHeaderProps) => {
    const localizedStrings = useContext(LocalizationContext);
    const defaultMessage = <b>{localizedStrings.error.warning}</b>;
    return (
        <>
            <h4 className="mt-4 section-header">
                {chooseFormResultComponentAfterSubmit(
                    formState,
                    formControlReferencePrefixes,
                    <i className="block-header-error fas fa-ban" />,
                    <i className="block-header-success far fa-check-circle" />,
                )}

                {icon}
                {`${title} `}

                {tooltip && <InformationalToolTip tooltip={tooltip} />}
            </h4>
            {formState.isSubmitted && hasFormErrors(formState, formControlReferencePrefixes) ? (
                <Alert data-testid="danger" variant="danger">
                    <>
                        {hideDefaultMessage
                            ? null
                            : [defaultMessage, ': ', localizedStrings.error.reservationFormError]}
                        {extraErrorMessages
                            ? extraErrorMessages.map((errorMessage) => (
                                  <div key={`${errorMessage}`}>
                                      <b>{localizedStrings.error.warning}</b>: {errorMessage}
                                  </div>
                              ))
                            : null}
                    </>
                </Alert>
            ) : null}
        </>
    );
};

export default BlockHeader;
