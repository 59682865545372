import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import LocalizationContext from '../../context/LocalizationContext';

type PrivacyPolicyLinkProps = {
    isInternal: boolean;
};

/**
 * Renders a privacy policy link based on if this is an internal or external site
 * @param isInternal boolean, set to true if this is a NOWPass or Corporate site
 */
const PrivacyPolicyLink = ({ isInternal }: PrivacyPolicyLinkProps) => {
    const localizedStrings = useContext(LocalizationContext);

    if (isInternal) {
        return (
            <>
                <Link to={localizedStrings.footer.privacyPolicyUrl}>
                    {localizedStrings.footer.privacyPolicy}
                </Link>
            </>
        );
    }

    return (
        <a
            href={localizedStrings.footer.privacyPolicyUrl}
            target="_blank"
            rel="noopener noreferrer"
        >
            {localizedStrings.footer.privacyPolicy}
        </a>
    );
};

export default PrivacyPolicyLink;
