import React, { useContext, useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';
import AccountContext from '../../context/AccountContext';
import { EventGateResponse, EventResponse } from '../../utils/types';
import { getEventGateForAccessCode } from '../../api/accessCodeApi';
import TicketCard from '../tickets/TicketCard';
import Ribbon from '../common/Ribbon';
import LocalizationContext from '../../context/LocalizationContext';
import Fade from '../common/animation/Fade';
import './AccessCode.css';
import { isUpperCaseEntry } from '../../utils/FormUtils';

type Props = {
    event: EventResponse;
};

export const ACCESS_CODE_LENGTH = 16;
export const ACCESS_CODE_LENGTH_WITH_SPACES = 19;
export const ACCESS_CODE_SESSION_STORAGE_KEY = 'access_code';

/**
 * Component for displaying the access code specific section
 *
 * @param event - Event object
 */
const AccessCodes = ({ event }: Props) => {
    const initialAccessCode =
        sessionStorage.getItem(`${ACCESS_CODE_SESSION_STORAGE_KEY}_${event.event_id}`) || '';
    const hasInitialAccessCode = !!initialAccessCode;

    const [accessCode, setAccessCode] = useState<string>(initialAccessCode);
    const [loading, setLoading] = useState(hasInitialAccessCode);
    const [isOpen, setIsOpen] = useState(hasInitialAccessCode);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [eventGate, setEventGate] = useState<EventGateResponse | null>(null);

    const { value: accountInfo, setValue: setAccountInfo } = useContext(AccountContext);
    const localizationStrings = useContext(LocalizationContext);
    const getHiddenEventGate = async () => {
        setIsSubmitting(true);
        const result = await getEventGateForAccessCode(
            Number(event.event_id),
            accessCode,
            accountInfo.token,
        );

        if (!result) {
            setHasError(true);
        } else {
            setEventGate(result);
            sessionStorage.setItem(
                `${ACCESS_CODE_SESSION_STORAGE_KEY}_${event.event_id}`,
                accessCode,
            );
        }
        setIsSubmitting(false);
    };

    // Run effect on mount if we already have an access code from sessionStorage
    useEffect(() => {
        if (accessCode) {
            const runEffect = async () => {
                await getHiddenEventGate();
                setLoading(false);
            };
            runEffect();
        }
    }, []);

    const isLoggedIn = !!accountInfo.userId;
    const registrationClosed = moment(eventGate?.registration_end_date).isBefore();

    let content;

    if (loading) {
        return <Spinner />;
    }
    // Closed state
    if (!isOpen) {
        let onClick;
        if (!isLoggedIn) {
            onClick = () => setAccountInfo({ ...accountInfo, showModal: true });
        } else {
            onClick = () => {
                setIsOpen(true);
            };
        }
        content = (
            <Fade key="redeem-button" className="d-flex justify-content-center align-items-center">
                <Button className="access-code-link" variant="link" onClick={onClick}>
                    {localizationStrings.events.details.accessCode.toggleButtonLabel}
                </Button>
            </Fade>
        );
    } else {
        let body;

        // We don't have a Gate from the access code yet
        if (!eventGate) {
            body = (
                <Fade
                    className="row justify-content-center align-items-center"
                    key="access-code-input-group"
                >
                    <div className="access-code-input-container" data-testid="access-code-section">
                        <label htmlFor="access-code" className="sr-only">
                            {localizationStrings.events.details.accessCode.aria.accessCodeInput}
                        </label>
                        <input
                            id="access-code"
                            key="access-code-input"
                            className={`form-control access-code-input ${
                                hasError ? 'is-invalid' : ''
                            }`}
                            placeholder={
                                localizationStrings.events.details.accessCode.inputPlaceHolder
                            }
                            maxLength={ACCESS_CODE_LENGTH_WITH_SPACES}
                            onPaste={(e) => {
                                e.preventDefault();
                                const newPaste = e.clipboardData
                                    .getData('text')
                                    .slice(0, ACCESS_CODE_LENGTH);
                                if (hasError) {
                                    setHasError(false);
                                }
                                isUpperCaseEntry(newPaste, setAccessCode);
                            }}
                            onChange={(e) => {
                                const newValue = e.target.value;

                                if (!newValue && hasError) {
                                    setHasError(false);
                                }
                                isUpperCaseEntry(newValue, setAccessCode);
                            }}
                            value={accessCode ? accessCode.match(/.{1,4}/g)?.join(' ') : accessCode}
                        />
                        {hasError && (
                            <strong className="text-danger">
                                {localizationStrings.events.details.accessCode.error.invalid}
                            </strong>
                        )}
                    </div>
                    <Button
                        className="access-code-redeem"
                        data-testid="access-code-redeem"
                        disabled={accessCode.length < ACCESS_CODE_LENGTH || isSubmitting}
                        onClick={getHiddenEventGate}
                    >
                        {isSubmitting ? (
                            <Spinner />
                        ) : (
                            localizationStrings.events.details.accessCode.redeemButtonLabel
                        )}
                    </Button>
                </Fade>
            );
            // We have a Gate from an Access Code
        } else {
            body = (
                <Fade
                    className="d-flex justify-content-center align-items-center position-relative mt-3"
                    key="access-code-ticket"
                >
                    <TicketCard
                        className={
                            registrationClosed
                                ? 'access-code-card'
                                : 'access-code-card-hide-ticket-text'
                        }
                        event={event}
                        eventGate={eventGate}
                        hasMultipleTickets={false}
                        key="access-ticket"
                        accessCode={accessCode}
                    />
                    {registrationClosed ? null : <Ribbon title={eventGate.description} />}
                </Fade>
            );
        }

        content = (
            <>
                {eventGate && (
                    <Fade
                        key="remove-ticket-button"
                        className="display-none d-flex justify-content-center align-items-center"
                    >
                        <Button
                            className="access-code-link mb-2"
                            variant="link"
                            data-testid="access-code-different-code"
                            onClick={() => {
                                setHasError(false);
                                setAccessCode('');
                                setEventGate(null);
                                sessionStorage.removeItem(
                                    `${ACCESS_CODE_SESSION_STORAGE_KEY}_${event.event_id}`,
                                );
                            }}
                        >
                            {localizationStrings.events.details.accessCode.enterDifferentCode}
                        </Button>
                    </Fade>
                )}
                <motion.div
                    initial={{ y: -15, opacity: 1 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -15, opacity: 0 }}
                    key="access-code-content"
                    className="access-code-container"
                >
                    <div className="d-flex justify-content-center align-items-center">
                        {eventGate ? (
                            <h3 className="access-code-title access-code-title-success mb-2">
                                {registrationClosed ? (
                                    <>
                                        {
                                            localizationStrings.events.details.accessCode.error
                                                .registrationClosed
                                        }
                                    </>
                                ) : (
                                    <>
                                        {
                                            localizationStrings.events.details.accessCode.success
                                                .woohoo
                                        }
                                        <br />
                                        {
                                            localizationStrings.events.details.accessCode.success
                                                .youCanRegister
                                        }
                                    </>
                                )}
                            </h3>
                        ) : (
                            <h3 className="access-code-title">
                                {localizationStrings.events.details.accessCode.title}
                            </h3>
                        )}
                        {!eventGate && (
                            <Button
                                variant="link"
                                onClick={() => {
                                    setIsOpen(false);
                                    setAccessCode('');
                                    setEventGate(null);
                                    setHasError(false);
                                }}
                                aria-label={
                                    localizationStrings.events.details.accessCode.aria.exitButton
                                }
                            >
                                <i className="fas fa-times access-code-exit-icon" />
                            </Button>
                        )}
                    </div>
                    <Container>
                        <AnimatePresence mode="wait">{body}</AnimatePresence>
                    </Container>
                </motion.div>
            </>
        );
    }

    return (
        <div className="mt-3">
            <AnimatePresence mode="wait">{content}</AnimatePresence>
        </div>
    );
};

export default AccessCodes;
