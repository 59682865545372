import React, { useContext } from 'react';
import './NotFound.css';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LocalizationContext from '../../context/LocalizationContext';

/**
 * Component for displaying a graphically pleasing 404 page.
 */
const NotFound = () => {
    const localizedStrings = useContext(LocalizationContext);

    return (
        <Container className="mt-6" data-testid="not-found-content">
            <Row>
                <Col>
                    <div className="d-flex flex-column align-items-center">
                        <i className="fas fa-star-exclamation text-muted star" />
                        <h1 className="my-4 text-center">
                            {localizedStrings.error.notFound.title}
                        </h1>
                        <Link to="/events" className="mt-3 btn btn-primary">
                            {localizedStrings.error.notFound.viewAvailableEvents}
                        </Link>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default NotFound;
