const enInternal = {
    tabs: {
        signIn: 'Sign In',
    },
    account: {
        accountRequired: 'Login Required',
        pleaseLogin: {
            basic: 'Please {0}',
            toView: 'Please {0} to view your {1}',
            toAccept: 'Please {0} to accept our terms',
        },
        requiredToRSVP:
            'Login is required to RSVP for this activity. Please visit the Sign In page.',
        checkIn: {
            poorInternetConnectivity:
                'In case there is poor internet connectivity at your event, we recommend that you print out this pass either from this page or from the email we have sent to your work e-mail.',
        },
    },
    activities: {
        types: {
            RTW: 'Nintendo Onsite Work',
        },
    },
    reservations: {
        nickname: 'Name',
    },
    footer: {
        disclaimer: '',
        privacyPolicy: 'Nintendo Onsite Work Privacy Policy',
        privacyPolicyUrl: '/privacy-policy',
        termsOfUse: '',
        termsOfUseUrl: '',
    },
    internal: {
        workOnsite: {
            pass: 'Nintendo Onsite Work Pass',
            passes: 'Nintendo Onsite Work Passes',
            reservations: {
                selfEvaluation: 'Self-Evaluation Questionnaire',
                sections: {
                    vaccinationAttestation: 'Vaccination Attestation:',
                    sinceLastVisit:
                        'Since your last day of work, or last visit to a Nintendo location, have you:',
                    anySymptoms:
                        'Since your last day of work, or last visit to a Nintendo location, have you had any of these symptoms that you cannot attribute to another health condition?',
                },
                tempAlert: {
                    mainText:
                        'Please take your temperature and if the reading is {0} proceed to answer the following questions. If your temperature is {1} or higher, please stop, notify your supervisor that you are sick, then contact a healthcare provider.',
                    orBelow: 'below {0}',
                    temp: '100°F (37.8°C)',
                },
                questions: {
                    fullyVaccinated:
                        'I am fully vaccinated against COVID-19 and have provided a copy of my vaccination record/proof',
                    pcrTestSevenDays:
                        'I have provided proof of a recent negative COVID-19 test per Nintendo\'s policy, OR have an approved exception given I\'m in my 90-day retest window and have confirmation from NOA HR (or my agency employer) to be onsite, OR have met the established practices as an employee of NOCL. If you\'ve met the conditions described above, select "NO" on question 3 below, "Been lab-confirmed to have COVID-19?"',
                    labConfirmed: 'Been lab-confirmed to have COVID-19?',
                    closeContact:
                        'Had known close contact with a person who is lab-confirmed to have COVID-19?',
                    traveledOutsideRegion:
                        'Traveled outside of your home state/province within the last 10 days?',
                    completedQuarantine:
                        'Travel quarantine rules can be found in the Return from Travel section of the {workingOnsitePolicy}. Have you completed the required quarantine period for your situation and location?',
                    workingOnsitePolicy: 'Working Onsite Policy',
                    workingOnsitePolicyLink:
                        'https://d16rzpve0z7f4l.cloudfront.net/nowpass/Working_Onsite_Policy.pdf',
                },
                symptoms: {
                    cough: 'Cough',
                    shortnessBreath: 'Shortness of breath or difficulty breathing',
                    fever: 'Fever',
                    chills: 'Chills',
                    shaking: 'Repeated shaking with chills',
                    musclePain: 'Muscle Pain',
                    diarrhea: 'Diarrhea',
                    headache: 'Headache',
                    soreThroat: 'Sore Throat',
                    lossOfTaste: 'New Loss of Taste or Smell',
                    congestion: 'Congestion or Runny Nose',
                    nausea: 'Nausea or Vomiting',
                },
                footnote: {
                    confirmButtonNotClickable:
                        'If the {0} button does not become clickable, please call Nintendo’s partner, Aerotek Scientific, at {1} to complete additional screening. You may be at higher risk of currently having COVID-19. For the safety of our workers, visitors and business partners, additional screening is required to determine whether you meet CDC guidelines for safe return to work (see below). If you pass the additional screening, you must also meet the requirement for entrance to the Nintendo facility or each off-site location you will visit. Nintendo reserves the right to deny entry in its discretion, in accordance with applicable law.',
                    phoneNumber: '425-202-8405',
                    block: {
                        intro: 'Workers who were lab-confirmed to have COVID-19 or have had a COVID-19 symptom may report to work if they meet all requirements of the Nintendo facility or off-site location they will visit AND at least one of the following CDC standards for safe return to work:',
                        option1:
                            'Option 1: If, in consultation with a healthcare provider and local public health authorities knowledgeable about locally available testing resources, it is determined a worker will not have a test to determine if they are still contagious, the worker can leave home and return to work after these three conditions have been met:',
                        option1Conditions: [
                            'The worker has had no fever for at least 24 hours (that is, 1 full day of no fever without the use of medicine that reduces fevers) AND',
                            'symptoms have improved (for example, cough or shortness of breath have improved) AND',
                            'at least 10 days have passed since the symptoms first appeared',
                        ],
                        option2: {
                            intro: 'Option 2: If, in consultation with a healthcare provider and local public health authorities knowledgeable about locally available testing resources, it is determined the worker will be tested to determine if the worker is still contagious, the worker can leave home and return to work after these three conditions have been met:',
                            noLongerFever:
                                'The worker no longer has a fever (without the use of medicine that reduces fevers) AND',
                            respiratorySymptomsImprove:
                                'symptoms have improved (for example, cough or shortness of breath have improved) AND',
                            twoNegativeTests:
                                'they received two negative tests in a row, at least 24 hours apart (Their doctor should follow {0})',
                            cdcLink: {
                                name: 'CDC Guidelines',
                                address:
                                    'https://www.cdc.gov/coronavirus/2019-ncov/hcp/disposition-in-home-patients.html',
                            },
                        },
                        quarantineCondition:
                            'Workers who have had known close contact with a person who is lab-confirmed to have COVID-19 may report to work if they have been symptom-free for at least 14 days since the last known exposure.',
                    },
                },
            },
            account: {
                rsvps: {
                    details: {
                        additionalInstructions: {
                            title: 'Additional Instructions',
                            sections: {
                                buildingEntrance: {
                                    title: 'Building Entrance',
                                    body: 'For information on which entrance to use for your building, please {0}',
                                    clickHere: 'click here',
                                    linkAddress: '/building-map',
                                },
                                tempTest: {
                                    title: '',
                                    body: '',
                                },
                                badgeRequired: {
                                    title: 'Badge Required',
                                    body: 'Entry to the Facilities will be limited to certain designated entrances. You will be required to swipe your badge at the entry card reader upon entering the Facilities. If you forget your badge, you will be referred to Security for a replacement. You will also be required to have your badge prominently displayed at all times while you are physically on site at the Facilities.',
                                },
                            },
                        },
                    },
                },
            },
            privacyPolicy: {
                title: 'Nintendo Onsite Work Website Privacy Policy',
                update: 'Last Updated: May 19, 2022',
                blurb: 'At Nintendo of America, we understand that your privacy is very important to you. Please take the time to review this privacy policy that describes how your information is collected and used when you utilize the Nintendo Onsite Work website (the “Site”). By using the Site, you acknowledge that you have read and understood the practices described in this privacy policy.',
                sections: {
                    categories: {
                        heading: 'Categories of information we may collect',
                        info: {
                            heading: 'Information that you give to us',
                            body: 'When you use the Site, we collect information you provide directly to us. This may include information like your company username and email address.',
                        },
                        health: {
                            heading: 'Health information',
                            body: 'The Site allows you to provide health information, including your symptoms and possible exposure to COVID-19. This health information will be retained only for the period needed to grant you access to the relevant facility or office and then it will be subsequently deleted.',
                        },
                        access: {
                            heading: 'Access information',
                            body: 'We will retain a log of your access to our facilities or offices.',
                        },
                        vaccine: {
                            heading: 'Vaccine Information',
                            body: 'This Site may allow you to provide information related to your COVID-19 vaccination status or the medical or religious reasons for which you are unable to receive the COVID-19 vaccination (“Vaccine Status Information”). We will retain this Vaccine Status Information for as long as reasonably necessary to manage the health and safety of our workplaces during the COVID-19 pandemic.',
                        },
                    },
                    infoUse: {
                        heading: 'How we use your information',
                        body: 'This Site is part of our strategy to manage the health and safety of our workplaces, and we may use the information you provide in connection with those efforts. We also may use the information to help manage our relationship with you, such as authenticating you and providing you with information, and to administer the Site.',
                        body2: 'We may use your Vaccine Status Information to determine the COVID-19 requirements that apply to you under our Working Onsite policy.',
                        body3: 'We also may use your information to comply with applicable laws and regulations and to protect or exercise our legal rights or defend against legal claims.',
                    },
                    infoShare: {
                        heading: 'How we share your information',
                        body: 'The information collected by the Site is not shared with external third parties.',
                    },
                    rights: {
                        heading: 'Rights and choices regarding your information',
                        body: 'Depending on your place of residence, you may have the right, in accordance with applicable law, to request a digital copy of the information that we’ve collected about you. You may contact us for information about any rights available to you by emailing us at ',
                    },
                    changes: {
                        heading: 'Changes',
                        body: 'We may update this privacy policy from time to time. When we update it, we will revise the “Last Updated” date above and post the new privacy policy.',
                    },
                    contact: {
                        heading: 'Contact information',
                        body: 'If you have any questions, concerns or comments regarding this privacy policy, or any of our privacy practices, please contact us via email at ',
                        body2: ', via phone at 1-888-977-7627, or via regular mail at Nintendo of America Inc., Attn: Privacy Administrator, 4600 150th Ave NE, Redmond, WA 98052, U.S.A.',
                    },
                    link: 'privacypolicy@noa.nintendo.com',
                },
            },
        },
    },
};

export type InternalLocalizationObject = typeof enInternal;
export default enInternal;
