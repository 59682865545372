import React, { useContext, useState } from 'react';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import isNil from 'lodash.isnil';
import { FieldValues } from 'react-hook-form';
import { toast } from 'react-toastify';
import Container from 'react-bootstrap/Container';
import Alert from 'react-bootstrap/Alert';

import {
    ActivityResponse,
    GrandPrixServiceError,
    DetailFormErrors,
    LocalizedErrorMessages,
    MyTicketsRerouteState,
    MyTicketTab,
    NintendoAccountFamilyResponse,
    NintendoAccountFamilyUser,
    ParticipantInfoMap,
    Party,
    Reservation,
    ReservationRouteParams,
    Ticket,
    TicketResource,
    TimeSlot,
    HydratedWaitlistEntry,
} from '../../utils/types';
import AccountContext, { AccountInfo } from '../../context/AccountContext';
import LocalizationContext from '../../context/LocalizationContext';
import { useFetchState, useWindowUnloadEffect } from '../../hooks/customHooks';
import Spinner from '../common/Spinner';
import { isInternal } from '../../utils/InternalUtils';
import Breadcrumb from '../common/Breadcrumb';
import CountDownTimer from '../registration/CountDownTimer';
import { isErrorResponse, isReservation } from '../../utils/typeGuards';
import { confirmTemporaryReservation, fetchActivityDataCollection } from '../../api/eventApi';
import { useMyTicketsContext } from '../../context/MyTicketsContext';
import LanguageContext from '../../context/LanguageContext';
import { removeTempReservationsFromLocalStorage } from '../../utils/LocalStorageUtils';
import { getFamily } from '../../api/nintendoAccountApi';
import { checkExceptionForGrandPrixError } from '../../utils/ErrorUtils';
import { confirmTickets } from '../../api/ticketApi';
import { getFilesFromAssociation } from '../../utils/files/FilesUtil';
import ScrollToTop from '../common/ScrollToTop';
import RegistrationForm from '../registration/RegistrationForm';
import DetailsBlock from '../registration/blocks/DetailsBlock';
import RegistrantBlock from '../registration/blocks/RegistrantBlock';
import PrivacyPolicyLink from '../policies/PrivacyPolicyLink';
import GuestsBlock from '../registration/blocks/GuestsBlock';
import ConsentBlock from '../registration/blocks/ConsentBlock';
import { transformFormToParticipantData } from '../../utils/FormUtils';
import { isHostOfTheParty } from '../account/myTicketsAndPasses/MyTicketUtils';
import AttendeesBlock from '../registration/blocks/AttendeesBlock';
import { useAuthenticatedFetchState } from '../../hooks/authenticationHooks';
import { checkIfSameDay } from '../../utils/DateUtils';
import { getRequestedTicketsCount } from '../../utils/WaitlistUtils';

const HOST_INELIGIBLE_ERROR_SET = new Set([
    LocalizedErrorMessages.GP0010,
    LocalizedErrorMessages.GP0244,
    LocalizedErrorMessages.GP0252,
]);

type ConfirmReservationLocationState = {
    activity: ActivityResponse;
    timeSlot: TimeSlot;
    temporaryReservations: Array<Reservation | Ticket>;
    eventGateParty?: Party;
    cancelReservationId: string;
};

/**
 * Details about a single timeslot and the ability for a customer to confirm they want that timeslot.
 *
 * Entering this component we have a list of tempReservations (WPP Reservation or Drawing Ticket) the user will
 * eventually need to confirm. A timer is displayed counting down till the temporary reservations expire.
 *
 * If this Activity timeslot is under an Event Gate than eventGateParty should contain everyone in the event gate
 * party that successfully registered for this Event Gate. Only these event gate party members will be eligible to
 * register for this timeslot.
 *
 * On the other hand, an Activity timeslot may NOT be under an Event Gate and the timeslot may have its own
 * max_attendees_per_party. In which case eventGateParty will be undefined, and we will display the
 * "Select family members" button.
 *
 * Event Gate max_attendees_per_party and Timeslot max_attendees_per_party are mutually exclusive. Meaning
 * you will only use one or the other but never both. Event Gate max_attendees_per_party will always override the
 * Timeslot max_attendees_per_party.
 *
 * Example payload to confirm reservations or drawing tickets:
 *  {
 *      "reservations": list of ALL temporary Reservations,
 *      "guests": list of guest Nintendo Account IDs that confirmed they want the reservation/tickets
 *      "participantInfo": optional ParticipantInfoMap
 *              (only sent when we are using timeslot.max_attendees_per_party),
 *      "cancel_reservation_id": optional reservationId to cancel (e.g. change timeslot flow),
 *      "addMeToParty":
 *              true (if host in reservations. Always set to true when using timeslot.max_attendees_per_party)
 *              false (if host not in reservations)
 *  }
 *
 * @param locationState.activity - the ActivityResponse of the activity timeslot being signed up for
 * @param locationState.drawing - the DrawingResponse of the activity being signed up for
 * @param locationState.timeslot - the TimeSlot being signed up for
 * @param locationState.eventGateParty - optional Party from the Event Gate
 * @param locationState.cancelReservationId - optional reservationId to cancel after confirming a change time flow
 */
const ConfirmReservation = () => {
    const params = useParams<ReservationRouteParams>();
    const locationState: ConfirmReservationLocationState | undefined = useLocation().state;
    const eventId = Number(params.eventId);
    const activityId = Number(params.activityId);

    // If state values are missing, take the user back to activity details
    if (!locationState) {
        return <Navigate to={`/events/${eventId}/activities/${activityId}`} />;
    }

    return <ConfirmReservationContent locationState={locationState} />;
};

const ConfirmReservationContent = ({
    locationState,
}: {
    locationState: ConfirmReservationLocationState;
}) => {
    // Setup context
    const localizedStrings = useContext(LocalizationContext);
    const { value: accountInfo } = useContext(AccountContext);
    const { locale } = useContext(LanguageContext);
    const {
        refresh: refreshMyTickets,
        waitlists: {
            meta: { waitlistsById },
        },
    } = useMyTicketsContext();
    const navigate = useNavigate();
    const params = useParams<ReservationRouteParams>();
    const eventId = Number(params.eventId);
    const activityId = Number(params.activityId);
    const { activity, timeSlot, eventGateParty, temporaryReservations, cancelReservationId } =
        locationState;

    // Show hours only if it's a single day
    const startEndSameDay = checkIfSameDay(
        timeSlot.start_date,
        timeSlot.end_date,
        activity.location.time_zone,
    );

    // Grab one of the reservations to see if we have a Ticket or a WPP Reservation
    const temporaryReservation = temporaryReservations[0];
    let expiration: string | null;
    let associatedWaitlist: HydratedWaitlistEntry | undefined;
    if (isReservation(temporaryReservation)) {
        expiration = temporaryReservation.temporary_reservation_expires;
    } else {
        expiration = temporaryReservation.temporary_expires;
        if (!isNil(temporaryReservation.waitlist_entry_id)) {
            associatedWaitlist = waitlistsById[temporaryReservation.waitlist_entry_id];
        }
    }

    // Family eligibility checked is only used for drawings. We only get 1 ticket for the whole
    // party for a drawing so we need to check eligibility for each member of the party using this API
    // instead.
    const { value: familyResponse, loading: loadingFamily } =
        useFetchState<NintendoAccountFamilyResponse | null>(
            () =>
                getFamily(accountInfo.token, undefined, timeSlot.drawing_id, timeSlot.time_slot_id),
            null,
        );

    // Fetch Activity data collection items
    const { value: activityDataCollectionItems, loading: loadingActivityDataCollection } =
        useAuthenticatedFetchState(
            async () => [],
            () => fetchActivityDataCollection(eventId, activityId, accountInfo.token),
            [],
            accountInfo,
        );

    // Setup states
    const [error, setError] = useState(false);
    // Used for drawings to keep track of eligible family members
    const [guests, setGuests] = useState<Array<NintendoAccountFamilyUser>>(
        familyResponse ? familyResponse.family : [],
    );
    const [ineligibleGuests, setIneligibleGuests] = useState<Array<string>>([]);

    // If we are not the host, only show the RegistrantBlock instead of the AttendeesBlock
    const isUserHost = eventGateParty
        ? isHostOfTheParty(Number(accountInfo.participantId), eventGateParty)
        : false;

    // Setup side effects
    useWindowUnloadEffect();

    /**
     * Handle Reservation form submission
     */
    const submitForm = async (values: FieldValues) => {
        // Get participant info & participant list from data collection fields
        const { participantInfo, participantList } = transformFormToParticipantData(values);
        /**
         * Add users from participantList to the selectedNintendoAccountIds. Check userIds
         * to determine if the host is added to the party for event gate WPP RSVPs
         */
        let addMeToParty = false;
        const selectedNintendoAccountIds: Array<string> = participantList.filter((userId) => {
            if (userId === accountInfo.userId) {
                addMeToParty = true;
            }
            return userId !== accountInfo.userId;
        });

        const reservations: Array<Reservation> = temporaryReservations.filter(
            (r): r is Reservation => isReservation(r),
        );
        const tickets: Array<Ticket> = temporaryReservations.filter(
            (t): t is Ticket => !isReservation(t),
        );

        const handleError = () => {
            setError(true);
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        };

        try {
            let response = null;
            if (reservations && reservations.length > 0) {
                // Confirm reservations
                response = await confirmTemporaryReservations(
                    accountInfo,
                    reservations,
                    selectedNintendoAccountIds,
                    participantInfo,
                    addMeToParty,
                    cancelReservationId,
                    locale,
                );
            } else if (tickets && tickets.length > 0) {
                response = await confirmTemporaryTickets(
                    accountInfo,
                    tickets,
                    selectedNintendoAccountIds,
                    participantInfo,
                    timeSlot,
                    addMeToParty,
                    locale,
                    cancelReservationId,
                );
            }

            if (response) {
                refreshMyTickets();

                // Check for GP Error code from RSVP response
                if (isErrorResponse<Array<string>>(response)) {
                    const errorCode = response.code as LocalizedErrorMessages;
                    const { details } = response;

                    if (HOST_INELIGIBLE_ERROR_SET.has(errorCode)) {
                        toast.error(() => localizedStrings.error.serviceError[errorCode]);

                        // Redirect the user to the activity details page
                        navigate(`/events/${eventId}/activities/${activityId}`);
                    } else if (details) {
                        // We have some ineligible guests...find them and remove from guest list
                        const newIneligibleGuests: Array<string> = details;

                        setIneligibleGuests(newIneligibleGuests);
                        const newGuests = guests.filter(
                            (guest) => !newIneligibleGuests.includes(guest.user_id),
                        );
                        setGuests(newGuests);
                    }
                } else {
                    let toastFormatStr;
                    let strValues;

                    // Check for the following conditions in order:
                    // - Is a drawing
                    // - Is a WPP
                    // - Otherwise, default to a ticket.
                    if (activity.drawing) {
                        toastFormatStr = localizedStrings.account.rsvps.drawingTicketAdded;
                        strValues = { ticketName: <b>{activity.name}</b> };
                    } else if (reservations && reservations.length > 0) {
                        toastFormatStr = localizedStrings.account.rsvps.warpPipePassAdded;
                        strValues = { activityName: <b>{activity.name}</b> };
                    } else {
                        toastFormatStr = localizedStrings.account.rsvps.ticketAdded;
                        strValues = { ticketName: <b>{activity.name}</b> };
                    }

                    // Format the variable arguments to the localized string.
                    const toastMsg = localizedStrings.formatString(toastFormatStr, strValues);

                    // Cheers (Show toast).
                    toast.success(() => toastMsg, { delay: 1000 });

                    // Do the 'Redirect dance'.
                    navigate('/my-tickets-passes', {
                        state: {
                            displayTab: MyTicketTab.WARP_PIPE_PASS,
                        } satisfies MyTicketsRerouteState,
                    });

                    // We succeeded in confirming, remove the temp reservations from local storage since we don't need
                    // to cancel them.
                    removeTempReservationsFromLocalStorage();
                }
            } else {
                handleError();
            }
        } catch (e) {
            handleError();
        }
    };

    if (!accountInfo || loadingFamily || loadingActivityDataCollection) {
        return <Spinner />;
    }

    const submitButtonMessage = activity.drawing
        ? localizedStrings.reservations.confirmEntry
        : localizedStrings.reservations.confirmRSVP;

    return (
        <Container data-testid="activity-signup-container">
            <ScrollToTop />
            <Breadcrumb
                to={`/events/${eventId}/activities/${activityId}/timeslots`}
                message={localizedStrings.general.backToActivityTimes}
            />
            {error ? (
                <Alert className="alert alert-danger mb-2">
                    {localizedStrings.error.reservationCouldNotBeConfirmed}
                </Alert>
            ) : null}
            <CountDownTimer
                message={
                    activity.drawing
                        ? localizedStrings.reservations.timeRemainingForDrawing
                        : localizedStrings.reservations.timeRemainingForReservation
                }
                expirationDate={expiration || moment().add(10, 'minutes').toISOString()}
                onExpire={() => {
                    toast.error(localizedStrings.error.rsvpTimeOut, {
                        autoClose: false,
                    });
                    navigate(`/events/${eventId}/activities/${activityId}/timeslots`);
                }}
            />
            <RegistrationForm
                onSubmit={submitForm}
                title={activity.name}
                buttonLabel={submitButtonMessage}
            >
                <DetailsBlock
                    title={localizedStrings.activities.details.title}
                    drawing={activity.drawing}
                    drawingRules={activity.drawing_rules}
                    startTime={timeSlot.start_date}
                    endTime={timeSlot.end_date}
                    showHours={startEndSameDay}
                    showDuration
                    location={activity.location}
                />
                {eventGateParty && eventGateParty.members.length > 1 && isUserHost ? (
                    <AttendeesBlock
                        party={eventGateParty}
                        tempReservations={temporaryReservations}
                        familyEligibilityMap={familyResponse ? familyResponse.eligibility : {}}
                        ineligibleGuests={ineligibleGuests}
                    />
                ) : (
                    <>
                        <RegistrantBlock
                            name={accountInfo.nickname}
                            userId={accountInfo.userId}
                            participantId={accountInfo.participantId}
                            participantInfo={familyResponse?.participant_info[accountInfo.userId]}
                            isHost
                            birthday={accountInfo.birthday}
                            isActivity
                            tooltip={
                                <span>
                                    {localizedStrings.formatString(
                                        localizedStrings.tickets.registration.registrant.dataNotice,
                                        {
                                            link: (
                                                <PrivacyPolicyLink
                                                    isInternal={isInternal(localizedStrings)}
                                                />
                                            ),
                                        },
                                    )}
                                </span>
                            }
                            dataCollection={activityDataCollectionItems}
                        />
                        <GuestsBlock
                            maxGuests={
                                associatedWaitlist
                                    ? getRequestedTicketsCount(associatedWaitlist) - 1
                                    : timeSlot.max_attendees_per_party - 1
                            }
                            minGuests={0}
                            dataCollection={activityDataCollectionItems}
                            participantInfoMap={familyResponse?.participant_info}
                            guests={guests}
                            setGuests={setGuests}
                            tooltip={
                                <span>
                                    {localizedStrings.formatString(
                                        localizedStrings.tickets.registration.registrant.dataNotice,
                                        {
                                            link: (
                                                <PrivacyPolicyLink
                                                    isInternal={isInternal(localizedStrings)}
                                                />
                                            ),
                                        },
                                    )}
                                </span>
                            }
                            eligibilityCheck={{
                                timeslotId: timeSlot.time_slot_id,
                                drawingId: timeSlot.drawing_id,
                            }}
                            ineligibleGuests={ineligibleGuests}
                            isDrawing={!!activity.drawing}
                            isActivity
                        />
                    </>
                )}
                <ConsentBlock
                    userId={accountInfo.userId}
                    files={[...getFilesFromAssociation(activity.files)]}
                    guests={guests}
                    header={localizedStrings.reservations.legalConsent}
                />
            </RegistrationForm>
        </Container>
    );
};

export default ConfirmReservation;

/**
 * Confirm temporary reservations
 *
 * @param accountInfo logged in users AccountInfo object
 * @param tempReservations list of temporary reservations
 * @param guestNintendoAccountIds list of guests
 * @param participantInfo list of ParticipantInfoMap objects
 * @param addMeToParty if we should add logged-in user to the party or not
 * @param cancelReservationId optional cancel reservation for cancel process
 * @param locale locale for this user
 */
const confirmTemporaryReservations = async (
    accountInfo: AccountInfo,
    tempReservations: Array<Reservation>,
    guestNintendoAccountIds: Array<string>,
    participantInfo: ParticipantInfoMap,
    addMeToParty: boolean,
    cancelReservationId: string,
    locale: string,
): Promise<Array<Reservation> | GrandPrixServiceError<Array<string>> | null> => {
    try {
        return await confirmTemporaryReservation(
            accountInfo.token,
            locale,
            tempReservations,
            guestNintendoAccountIds,
            participantInfo,
            cancelReservationId,
            addMeToParty,
        );
    } catch (e) {
        return checkExceptionForGrandPrixError(e);
    }
};

/**
 * Confirms this ticket. If the host is part of the selectedGuestAccountIds, remove them. The
 * confirmTickets call assumes who ever is calling the end point is the host.
 *
 * NOTE: Only Drawing tickets are supported at this point. Once WPP is migrated to the new Ticketing
 * system, we will be able to safely remove processReservations method below.
 *
 * @param accountInfo logged in users AccountInfo object
 * @param tempTickets list of Tickets
 * @param guestNintendoAccountIds list of guests
 * @param participantInfo list of ParticipantInfoMap objects
 * @param timeSlot TimeSlot object for this drawing
 * @param addMeToParty if we should add logged-in user to the party or not
 * @param cancelTicketId optional cancel ticketId for cancel process
 * @param locale locale for this user
 */
const confirmTemporaryTickets = async (
    accountInfo: AccountInfo,
    tempTickets: Array<Ticket>,
    guestNintendoAccountIds: Array<string>,
    participantInfo: ParticipantInfoMap,
    timeSlot: TimeSlot,
    addMeToParty: boolean,
    locale: string,
    cancelTicketId?: string,
): Promise<Array<Ticket> | GrandPrixServiceError<Array<string> | DetailFormErrors> | null> => {
    try {
        if (!timeSlot.drawing_id || timeSlot.drawing_id.length <= 0) {
            return null;
        }

        return await confirmTickets(
            TicketResource.DRAWINGS,
            timeSlot.drawing_id,
            accountInfo.token,
            locale,
            {
                tickets: tempTickets,
                guests: guestNintendoAccountIds,
                participantInfo,
                addMeToParty,
                cancelTicketId,
            },
        );
    } catch (e) {
        return checkExceptionForGrandPrixError(e);
    }
};
