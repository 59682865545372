import { ErrorMessage } from '@hookform/error-message';
import get from 'lodash.get';
import React, { useContext } from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { useFormContext } from 'react-hook-form';
import LocalizationContext from '../../../context/LocalizationContext';
import './FieldStyles.css';

type SelectFieldProps = {
    label: string;
    formControlReference: string;
    isRequired: boolean;
    disabled?: boolean;
    options?: Array<string>;
    defaultValue?: string;
};

/**
 * Generic select field with options
 *
 * @param label string label to display
 * @param isRequired boolean if this section is required or not
 * @param formControlReference unique reference used for react-hook-form
 * @param options List of the select options as strings
 * @param disabled boolean is this select disabled or not
 * @param defaultValue optional string, which option is default
 */
const SelectField = ({
    label,
    isRequired,
    formControlReference,
    options,
    disabled = false,
    defaultValue = '',
}: SelectFieldProps) => {
    const localizedStrings = useContext(LocalizationContext);
    const { register, formState } = useFormContext();

    const fieldOptions = {
        value: defaultValue,
        required: isRequired
            ? (localizedStrings.formatString(localizedStrings.error.formError.required, {
                  attribute: label,
              }) as string)
            : false,
    };

    let errorClassName = null;
    if (get(formState.errors, formControlReference)) {
        errorClassName = 'border-danger';
    }

    return (
        <Form.Group as={Col} className="generic-field-form-group">
            <Form.Label className="generic-field-form-label">
                {label} {isRequired ? <span className="field-error-color">*</span> : null}
            </Form.Label>
            <Form.Select
                id={formControlReference}
                disabled={disabled}
                className={`generic-field-form-control ${
                    (errorClassName || false) && 'border-danger'
                }`}
                {...register(formControlReference, fieldOptions)}
                aria-label={label}
            >
                <option value="">Choose a {label}</option>
                {options?.map((option: string) => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </Form.Select>

            <ErrorMessage
                errors={formState.errors}
                name={formControlReference}
                render={({ message }) => <div className="generic-field-form-error">{message}</div>}
            />
        </Form.Group>
    );
};

export default SelectField;
