import React, { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Loading from 'react-loading';

import LocalizationContext from '../../../../../context/LocalizationContext';

export interface PassOnTicketsModalProps extends React.ComponentProps<typeof Modal> {
    onConfirm?: () => void | Promise<void>;
    submitting?: boolean;
}

export const PassOnTicketsModal = ({
    onHide,
    onConfirm,
    submitting,
    ...rest
}: PassOnTicketsModalProps) => {
    const localizedStrings = useContext(LocalizationContext);

    return (
        <Modal centered onHide={onHide} {...rest}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {localizedStrings.tickets.waitlist.areYouSureYouDontWantIt}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{localizedStrings.tickets.waitlist.passOnTicketsNotice}</p>
                <p>{localizedStrings.tickets.waitlist.noteOtherWaitlistsUnaffected}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-primary" onClick={onHide} disabled={submitting}>
                    {localizedStrings.tickets.waitlist.keepOffer}
                </Button>
                <Button
                    variant="primary"
                    disabled={submitting}
                    onClick={onConfirm}
                    data-testid="confirm-pass-on-ticket"
                >
                    {submitting ? (
                        <Loading type="spin" height={25} width={25} />
                    ) : (
                        localizedStrings.tickets.waitlist.passOnOffer
                    )}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default PassOnTicketsModal;
