import React from 'react';
import { ErrorModalMessage } from '../utils/types';

export type ErrorContextProps = {
    message: ErrorModalMessage;
    updateMessage: (updatedMessage: ErrorModalMessage) => void;
};

export default React.createContext<ErrorContextProps>({
    message: { title: '', body: '' },
    updateMessage: () => {},
});
