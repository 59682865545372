import React, { useContext } from 'react';
import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useFormContext } from 'react-hook-form';
import parse from 'html-react-parser';

import BlockHeader from './BlockHeader';

import DataCollectionFields from '../fields/DataCollectionFields';
import ReadOnlyBirthdayField from '../fields/ReadOnlyBirthdayField';
import ReadOnlyNameField from '../fields/ReadOnlyNameField';

import DotDivider from '../../common/DotDivider';
import GuestModal from '../../tickets/signup/guests/modal/GuestModal';

import LocalizationContext from '../../../context/LocalizationContext';
import { getAttendeeType } from '../../../utils/AccountUtils';
import { BlockType, generateFormControlReferencePrefix } from '../../../utils/BlockUtils';
import { sanitizeForDisplay } from '../../../utils/FormattingUtils';
import { isInternal } from '../../../utils/InternalUtils';
import {
    DataCollectionAssociation,
    NintendoAccountFamilyUser,
    ParticipantInfoMap,
} from '../../../utils/types';

import './BlockStyles.css';

/** Property values used to construct a `<GuestBlock />` */
export type GuestBlockProps = {
    /** Max number of guests allowed */
    maxGuests: number;
    /** Min number of guests allowed */
    minGuests: number;
    /** Determines if this current registration page is for a regular gate */
    isDrawing: boolean;
    /** Determines if the current registration is for an activity or an event gate */
    isActivity: boolean;
    /** Data collection items provided by GPS. */
    dataCollection: Array<DataCollectionAssociation> | null;
    /** Text containing rules about guest sign-ups. */
    guestRules?: string;
    /** List of currently selected guests */
    guests: Array<NintendoAccountFamilyUser>;
    /** React.useState() setter function */
    setGuests: (newGuests: Array<NintendoAccountFamilyUser>) => void;
    /** List of guest Nintendo Account IDs deemed as ineligible */
    ineligibleGuests: Array<string>;
    /** A map of eligibility status for the guests */
    eligibilityCheck?: {
        eventGateId?: number;
        drawingId?: string;
        timeslotId?: number;
    };
    /** Optional ParticipantInfoMap from the family API */
    participantInfoMap?: ParticipantInfoMap;
    /** Optional JSX component that contains a tooltip */
    tooltip?: JSX.Element;
};

/**
 * Block-style component for the Guest Sign-up section in Event Ticket sign-up
 */
const GuestBlock = (props: GuestBlockProps) => {
    const {
        maxGuests,
        minGuests,
        isDrawing,
        isActivity,
        dataCollection,
        guestRules,
        guests,
        setGuests,
        ineligibleGuests,
        eligibilityCheck,
        participantInfoMap,
        tooltip,
    } = props;

    const localizedStrings = useContext(LocalizationContext);
    const { register, formState, trigger } = useFormContext();
    // Guests removed using the RemoveGuest button are sent back to the Guest modal to update the checkboxes.

    if (isInternal(localizedStrings) || maxGuests <= 0) {
        return null;
    }

    const onGuestModalSubmit = async (addedUsers: Array<NintendoAccountFamilyUser>) => {
        setGuests(addedUsers);
        // Kick off another react-hook-form validation since this might have triggered adding new checkboxes
        // to the ConsentBlock. Only trigger if the form has already been submitted to avoid confusion.
        if (formState.isSubmitted) {
            await trigger();
        }
    };
    const removeGuest = (removedGuest: string) => {
        // setGuests call removes the user's GuestBlock
        setGuests(
            guests.filter((user) => {
                return user.user_id !== removedGuest;
            }),
        );
    };

    // Used for minimum guest check
    const minNumGuestsControlReferenceId = `${BlockType.GUESTS_BLOCK}.guests`;
    // Add data collection form fields for each guest
    const formControlReferencePrefixList: Array<string> = [minNumGuestsControlReferenceId];
    const formFields = guests.map((guest, index) => {
        const formControlReferencePrefix: string = generateFormControlReferencePrefix(
            guest.user_id,
            BlockType.GUESTS_BLOCK,
        );
        const removeGuestButtonLabel = localizedStrings.formatString(
            localizedStrings.tickets.registration.guest.guestRemoveButtonLabel,
            { user: guest.nickname },
        );
        formControlReferencePrefixList.push(formControlReferencePrefix);
        return (
            <div key={guest.user_id}>
                <DotDivider showIcon={false} />
                <div>
                    <span className="guest-block-guest-num">
                        {' '}
                        {localizedStrings.tickets.registration.guest.guest} {index + 1}
                    </span>
                </div>
                <Container>
                    <Row>
                        <Col xs={11}>
                            <Row>
                                <ReadOnlyNameField
                                    userId={guest.user_id}
                                    name={guest.nickname}
                                    attendeeType={getAttendeeType(guest.birthday)}
                                />
                                <ReadOnlyBirthdayField
                                    userId={guest.user_id}
                                    birthday={guest.birthday}
                                />
                                <DataCollectionFields
                                    userId={guest.user_id}
                                    nickname={guest.nickname}
                                    birthday={guest.birthday}
                                    participantInfo={
                                        participantInfoMap
                                            ? participantInfoMap[guest.user_id]
                                            : undefined
                                    }
                                    formControlReferencePrefix={formControlReferencePrefix}
                                    dataCollection={dataCollection}
                                />
                            </Row>
                        </Col>
                        <Col xs={1} className="guest-block-remove-button-holder">
                            {/* Remove guest button */}
                            <Button
                                aria-label={removeGuestButtonLabel.toString()}
                                className="guest-block-remove-guest-button"
                                onClick={() => removeGuest(guest.user_id)}
                            >
                                <i className="far fa-times" />
                            </Button>
                        </Col>
                    </Row>
                </Container>

                {index === guests.length - 1 ? <DotDivider showIcon={false} /> : null}
            </div>
        );
    });

    return (
        <>
            {maxGuests > 0 || !isActivity ? (
                <>
                    <BlockHeader
                        icon={<i className="me-2 fas fa-user-friends" />}
                        title={localizedStrings.formatString(
                            localizedStrings.tickets.registration.guest.guests,
                            { maxGuests },
                        )}
                        formState={formState}
                        formControlReferencePrefixes={formControlReferencePrefixList}
                        extraErrorMessages={
                            guests.length < minGuests
                                ? [
                                      localizedStrings.formatString(
                                          localizedStrings.error.minNumberOfGuestsError,
                                          {
                                              atLeastNum: (
                                                  <strong>
                                                      {localizedStrings.formatString(
                                                          localizedStrings.error.atLeast,
                                                          {
                                                              expectedNumberOfGuests: minGuests,
                                                          },
                                                      )}
                                                  </strong>
                                              ),
                                          },
                                      ) as string,
                                  ]
                                : []
                        }
                        tooltip={tooltip}
                    />

                    {guestRules && (
                        <Alert variant="warning" className="guest-rules">
                            <p>{parse(sanitizeForDisplay(guestRules))}</p>
                        </Alert>
                    )}
                </>
            ) : null}

            <Container>
                <p>
                    {localizedStrings.formatString(
                        localizedStrings.tickets.registration.guest.guestFamilyHelp,
                        {
                            guestFamilyHelpLink: (
                                <a
                                    href={
                                        localizedStrings.tickets.registration.guest
                                            .guestFamilyHelpUrl
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {
                                        localizedStrings.tickets.registration.guest
                                            .guestFamilyHelpLink
                                    }
                                </a>
                            ),
                        },
                    )}
                </p>
                <GuestModal
                    onConfirm={onGuestModalSubmit}
                    maxGuests={maxGuests}
                    eligibilityCheck={eligibilityCheck}
                    ineligibleGuests={ineligibleGuests}
                    isDrawing={isDrawing}
                    selectedGuests={guests}
                />
                {/* Hidden form controller to handle minimum number of guests check */}
                <Form.Control
                    id={minNumGuestsControlReferenceId}
                    type="hidden"
                    defaultValue={guests.length}
                    {...register(minNumGuestsControlReferenceId, {
                        required: true,
                        validate: () => guests.length >= minGuests,
                    })}
                />
                {formFields}
            </Container>
        </>
    );
};

export default GuestBlock;
