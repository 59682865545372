import { AxiosError } from 'axios';
import AppConfig from '../components/config/AppConfig';

/**
 * A helper method to get common headers provided an access token
 * @param token The user's GP JWT access token
 * @returns Returns an object representing the common headers
 */
export const getHeaders = (token: string): HeadersInit => {
    return {
        Authorization: `Bearer ${token}`,
    };
};

/**
 * Helper to determine if an error is an Axios Error.
 * Reference: https://typescript.tv/best-practices/error-ts1196-catch-clause-variable-type-annotation/
 * @param candidate {any} - The object that will be checked to see if it's an AxiosError or not
 */
export const isAxiosError = (candidate: any): candidate is AxiosError => {
    return candidate.isAxiosError;
};

export const audienceQueryParam = AppConfig.audience ? { audience: AppConfig.audience } : {};
