import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Placement } from 'react-bootstrap/types';
import Button from 'react-bootstrap/Button';
import AppConfig from '../config/AppConfig';
import { Audience } from '../../utils/types';

type InformationalToolTipIconProps = {
    tooltip: JSX.Element | string;
    placement?: Placement;
};

/**
 * Renders the informational "I" icon with tooltip on overlay
 *
 * @param tooltip - Content of the tooltip that will be displayed on hover
 * @param placement - Optional parameter to determine the placement of the tooltip window. Defaults
 * to 'top'.
 */
const InformationalToolTip = ({ tooltip, placement = 'top' }: InformationalToolTipIconProps) => {
    if (AppConfig.audience === Audience.CORPORATE_EVENT) {
        return null;
    }
    return (
        <>
            <OverlayTrigger
                placement={placement}
                delay={{ show: 250, hide: 800 }}
                overlay={<Tooltip id="tooltip-top">{tooltip}</Tooltip>}
            >
                <Button variant="link" className="p-0">
                    <i className="text-muted far fa-info-circle ms-2" />
                </Button>
            </OverlayTrigger>
        </>
    );
};

export default InformationalToolTip;
