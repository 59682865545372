import React, { useContext } from 'react';
import Container from 'react-bootstrap/Container';
import { LocalizedStrings } from 'react-localization';
import LocalizationContext from '../../context/LocalizationContext';
import { InternalLocalizationObject } from '../../../resources/locales/en.internal';
import './PrivacyPolicy.css';

// Page that lists the Nintendo Onsite Work Employee Privacy Policy
// Makes user experience on NOWpass site more efficient, no need to navigate to sharepoint

const PrivacyPolicy = () => {
    const localizedStrings = useContext(
        LocalizationContext,
    ) as LocalizedStrings<InternalLocalizationObject>;

    return (
        <Container>
            <div className="header">
                <h1>{localizedStrings.internal.workOnsite.privacyPolicy.title}</h1>
                <br />
                <div className="subsection">
                    <p>{localizedStrings.internal.workOnsite.privacyPolicy.update}</p>

                    <p>{localizedStrings.internal.workOnsite.privacyPolicy.blurb}</p>
                </div>
                <ol>
                    <div className="subsection">
                        <li>
                            <p className="subheader">
                                {
                                    localizedStrings.internal.workOnsite.privacyPolicy.sections
                                        .categories.heading
                                }
                            </p>
                            <ul>
                                <li>
                                    <div className="subheader">
                                        {
                                            localizedStrings.internal.workOnsite.privacyPolicy
                                                .sections.categories.info.heading
                                        }
                                    </div>
                                    <p>
                                        {
                                            localizedStrings.internal.workOnsite.privacyPolicy
                                                .sections.categories.info.body
                                        }
                                    </p>
                                </li>
                                <li>
                                    <div className="subheader">
                                        {
                                            localizedStrings.internal.workOnsite.privacyPolicy
                                                .sections.categories.health.heading
                                        }
                                    </div>
                                    <p>
                                        {
                                            localizedStrings.internal.workOnsite.privacyPolicy
                                                .sections.categories.health.body
                                        }
                                    </p>
                                </li>
                                <li>
                                    <div className="subheader">
                                        {
                                            localizedStrings.internal.workOnsite.privacyPolicy
                                                .sections.categories.access.heading
                                        }
                                    </div>
                                    <p>
                                        {
                                            localizedStrings.internal.workOnsite.privacyPolicy
                                                .sections.categories.access.body
                                        }
                                    </p>
                                </li>
                                <li>
                                    <div className="subheader">
                                        {
                                            localizedStrings.internal.workOnsite.privacyPolicy
                                                .sections.categories.vaccine.heading
                                        }
                                    </div>
                                    <p>
                                        {
                                            localizedStrings.internal.workOnsite.privacyPolicy
                                                .sections.categories.vaccine.body
                                        }
                                    </p>
                                </li>
                            </ul>
                        </li>
                    </div>

                    <div className="subsection">
                        <li>
                            <p className="subheader">
                                {
                                    localizedStrings.internal.workOnsite.privacyPolicy.sections
                                        .infoUse.heading
                                }
                            </p>

                            <p>
                                {
                                    localizedStrings.internal.workOnsite.privacyPolicy.sections
                                        .infoUse.body
                                }
                            </p>

                            <p>
                                {
                                    localizedStrings.internal.workOnsite.privacyPolicy.sections
                                        .infoUse.body2
                                }
                            </p>

                            <p>
                                {
                                    localizedStrings.internal.workOnsite.privacyPolicy.sections
                                        .infoUse.body3
                                }
                            </p>
                        </li>
                    </div>

                    <div className="subsection">
                        <li>
                            <p className="subheader">
                                {
                                    localizedStrings.internal.workOnsite.privacyPolicy.sections
                                        .infoShare.heading
                                }
                            </p>

                            <p>
                                {
                                    localizedStrings.internal.workOnsite.privacyPolicy.sections
                                        .infoShare.body
                                }
                            </p>
                        </li>
                    </div>

                    <div className="subsection">
                        <li>
                            <p className="subheader">
                                {
                                    localizedStrings.internal.workOnsite.privacyPolicy.sections
                                        .rights.heading
                                }
                            </p>

                            <p>
                                {
                                    localizedStrings.internal.workOnsite.privacyPolicy.sections
                                        .rights.body
                                }
                                {}
                                <a
                                    href={
                                        localizedStrings.internal.workOnsite.privacyPolicy.sections
                                            .link
                                    }
                                >
                                    {
                                        localizedStrings.internal.workOnsite.privacyPolicy.sections
                                            .link
                                    }
                                </a>
                                .
                            </p>
                        </li>
                    </div>

                    <div className="subsection">
                        <li>
                            <p className="subheader">
                                {
                                    localizedStrings.internal.workOnsite.privacyPolicy.sections
                                        .changes.heading
                                }
                            </p>

                            <p>
                                {
                                    localizedStrings.internal.workOnsite.privacyPolicy.sections
                                        .changes.body
                                }
                            </p>
                        </li>
                    </div>

                    <div className="subsection">
                        <li>
                            <p className="subheader">
                                {
                                    localizedStrings.internal.workOnsite.privacyPolicy.sections
                                        .contact.heading
                                }
                            </p>

                            <p>
                                {
                                    localizedStrings.internal.workOnsite.privacyPolicy.sections
                                        .contact.body
                                }
                                {}
                                <a
                                    href={
                                        localizedStrings.internal.workOnsite.privacyPolicy.sections
                                            .link
                                    }
                                >
                                    {
                                        localizedStrings.internal.workOnsite.privacyPolicy.sections
                                            .link
                                    }
                                </a>
                                {
                                    localizedStrings.internal.workOnsite.privacyPolicy.sections
                                        .contact.body2
                                }
                            </p>
                        </li>
                    </div>
                </ol>
            </div>
        </Container>
    );
};

export default PrivacyPolicy;
