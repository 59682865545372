import React from 'react';
import { UseMyWaitlists } from '../hooks/use-my-waitlists';
import { UseMyTickets } from '../hooks/use-my-tickets';
import { UseHydrateWaitlists } from '../hooks/use-hydrate-waitlists';

export interface IMyTicketsContext {
    tickets: UseMyTickets;
    waitlists: Omit<UseMyWaitlists, 'waitlists'> & {
        waitlists: UseHydrateWaitlists['hydratedWaitlists'];
        meta: UseHydrateWaitlists['meta'];
    };
    /** Refresh all tickets/waitlist data related to the context */
    refresh: () => Promise<void>;
    /** Indicates whether any requests are loading */
    loading: boolean;
}

export const getInitialContext = (): IMyTicketsContext => ({
    tickets: {
        value: new Map(),
        loading: false,
        refresh: async () => {},
    },
    waitlists: {
        hasWaitlists: false,
        loading: false,
        error: false,
        refresh: async () => [],
        waitlists: [],
        meta: {
            resourcesById: {},
            eventsById: {},
            waitlistsByEvent: {},
            waitlistsById: {},
        },
    },
    loading: false,
    refresh: async () => {},
});

const MyTicketsContext = React.createContext<IMyTicketsContext>(getInitialContext());

export const useMyTicketsContext = () => React.useContext(MyTicketsContext);

export default MyTicketsContext;
