import React from 'react';
import clsx from 'clsx';
import './TicketAltIcon.css';

type Props = React.ComponentProps<'i'>;

const TicketAltIcon = React.forwardRef<any, Props>(({ className, ...iconProps }, forwardedRef) => (
    <i
        data-testid="ticket-alt-icon"
        className={clsx('fas fa-ticket-alt', className)}
        {...iconProps}
        ref={forwardedRef}
    />
));

export default TicketAltIcon;
