import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { fetchWaitlistEntries } from '../api/waitlistApi';
import AccountContext from '../context/AccountContext';
import { WaitlistEntry, WaitlistEntryStatus } from '../utils/types';

export const useMyWaitlists = () => {
    const {
        value: { token, userId },
    } = useContext(AccountContext);
    const [waitlists, setWaitlists] = useState<Array<WaitlistEntry> | null>(null);
    const [fetchError, setFetchError] = useState(false);
    const [loadingWaitlists, setLoadingWaitlists] = useState(false);
    const hasWaitlists = (waitlists?.length ?? 0) > 0;

    const refreshWaitlists = useCallback(async () => {
        setFetchError(false);
        setLoadingWaitlists(true);
        setWaitlists(null);

        try {
            const wlResponse = await fetchWaitlistEntries(token);

            if (wlResponse) {
                const filteredWaitlists = wlResponse.filter(
                    ({ status }) => status !== WaitlistEntryStatus.REMOVED,
                );
                setWaitlists(filteredWaitlists);
                return filteredWaitlists;
            }
            setFetchError(true);
        } catch (e) {
            setFetchError(true);
        } finally {
            setLoadingWaitlists(false);
        }
        return undefined;
    }, [token]);

    useEffect(() => {
        if (token && userId) {
            refreshWaitlists();
        }
    }, [refreshWaitlists, token, userId]);

    return useMemo(
        () => ({
            hasWaitlists,
            waitlists,
            loading: loadingWaitlists,
            refresh: refreshWaitlists,
            error: fetchError,
        }),
        [fetchError, hasWaitlists, loadingWaitlists, refreshWaitlists, waitlists],
    );
};

export type UseMyWaitlists = ReturnType<typeof useMyWaitlists>;
