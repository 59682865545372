import { useEffect, useState } from 'react';
import { AccountInfo } from '../context/AccountContext';
import { AsyncData } from './customHooks';

/**
 * A custom hook that will handle state, fetch, and error handling for loading data from an authenticated API if
 * possible. If not (such as the user is not yet logged in), it will use the provided public API.
 * @param publicAsyncFunction - The function that returns data from a public API
 * @param authenticatedAsyncFunction - The function that returns data from an authenticated API
 * @param defaultState - The default state for the async data
 * @param accountInfo - The AccountInfo representing the user
 */
export const useAuthenticatedFetchState = <T>(
    publicAsyncFunction: () => Promise<T>,
    authenticatedAsyncFunction: (token: string) => Promise<T>,
    defaultState: T,
    accountInfo: AccountInfo,
): AsyncData<T> => {
    const [asyncState, setAsyncState] = useState<T>(defaultState);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);
    const [refresh, setRefresh] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            if (!accountInfo.token && !accountInfo.loginCheck) {
                // If we don't have a token and we're not logging in to get one, then obtain data from the public API
                const publicData = await publicAsyncFunction();
                if (publicData) {
                    setAsyncState(publicData);
                } else {
                    setError(true);
                }
            } else if (accountInfo.token) {
                // If we do have a token, then obtain data from the authenticated API
                const authenticatedData = await authenticatedAsyncFunction(accountInfo.token);
                if (authenticatedData) {
                    setAsyncState(authenticatedData);
                } else {
                    setError(true);
                }
            } else if (accountInfo.loginCheck) {
                // If we're still logging in set the loading state to true and return
                setIsLoading(true);
                return;
            }
            setIsLoading(false);
        };
        fetchData();
    }, [accountInfo.token, accountInfo.userId, accountInfo.loginCheck, refresh]);

    return {
        value: asyncState,
        loading: isLoading,
        error,
        refresh: () => setRefresh(refresh + 1),
    };
};

/**
 * A hook that will refresh a session when the access token expires
 * @param accessTokenExpiration {number} - The time in milliseconds until the access token expires
 * @param refreshSession {() => void} - A function that refreshes the session
 */
export const useAuthenticationSessionTimeout = (
    accessTokenExpiration: number,
    refreshSession: () => void,
) => {
    useEffect(() => {
        // Set up the session timeout
        let timeout: NodeJS.Timeout | null = null;
        if (accessTokenExpiration > 0) {
            // Only set the timeout if we have an access token expiration time
            timeout = setTimeout(() => {
                refreshSession();
            }, accessTokenExpiration);
        }
        return () => {
            // Clean up the old timeout if one was created
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [accessTokenExpiration]);
};
