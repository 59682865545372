import React from 'react';
import { AlpsSessionData, GlobalState } from '../utils/types';

export interface AccountInfo extends AlpsSessionData {
    token: string;
    loginCheck: boolean;
    birthday: string | null;
    participantId: string;
    userImage: string | null;
    showModal: boolean;
}

export const EMPTY_ACCOUNT_INFO: AccountInfo = {
    token: '',
    code: '',
    idToken: '',
    userId: '',
    nickname: '',
    birthday: '',
    loginCheck: false,
    participantId: '',
    userImage: '',
    showModal: false,
};

export const LOADING_ACCOUNT_INFO: AccountInfo = {
    ...EMPTY_ACCOUNT_INFO,
    loginCheck: true,
};

export default React.createContext<GlobalState<AccountInfo>>({
    value: EMPTY_ACCOUNT_INFO,
    setValue: () => {},
});
